<template>
  <div>
     <div v-if="logo" :class="small ? 'image-bg-container-small mx-2':'image-bg-container'" :style="'background:url('+logo+')'"></div>
     <div v-else 
     :class="small ? 'image-bg-container-small mx-2':'image-bg-container'" 
     :style="'background:url('+defaultLogo+')'"></div>
  </div>
</template>

<script>
export default {  
  name: "avatar",
  props: {
    logo: String,
    small: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {
      defaultLogo: "/img/placeholder.jpg",
    };
  },
};
</script>

<style>

.image-bg-container {
    width: 100px;
    height: 100px;
    background-size: cover !important;
    border-radius: 50%;
    margin: 0 auto;
}

.image-bg-container-small {
    width: 40px;
    height: 40px;
    background-size: cover !important;
    border-radius: 50%;
    margin: 0 auto;
}

</style>