<template>


  <div class="md-layout">  
    <!-- Formulario para añadir nuevo menú -->
   

    <div class="md-layout-item md-size-100 mt-4" v-if="editingMenuId">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-rose">
          <div class="card-icon">
            <md-icon>edit</md-icon>
          </div>
          <h4 class="title">{{$t('edit')}}</h4>
        </md-card-header>
        <md-card-content>
          <div v-for="menu in jsonData" :key="menu._id" >
            <div v-if="editingMenuId == menu._id" class="menu">
              <MenuForm 
              :menuDefault="menu" 
              :allStablishments="allStablishments" 
              :allProducts="allProducts"
              @save="saveAllChanges($event)"
              @cancel="cancelEdit">
              </MenuForm>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
    <div class="md-layout-item md-size-100 mt-4" v-if="showNewMenuForm">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-rose">
          <div class="card-icon">
            <md-icon>add</md-icon>
          </div>
          <h4 class="title">{{$t('addNewMenu')}}</h4>
        </md-card-header>
        <md-card-content>
              <MenuForm 
              :menuDefault="newMenu" 
              :allStablishments="allStablishments" 
              :allProducts="allProducts"
              @save="addNewMenu($event)"
              @cancel="cancelEdit">
              </MenuForm>
        </md-card-content>
      </md-card>
    </div>
    <div class="mb-4 md-layout-item md-size-100 md-small-size-100 w-100" v-if="!showNewMenuForm">
      <md-button class="md-primary md-sm" @click="showNewMenuForm = !showNewMenuForm">+ {{$t('addNewMenu')}}</md-button>
    </div>
    <div class="md-layout-item md-size-100" v-if="!editingMenuId">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-rose">
          <div class="card-icon">
            <md-icon>restaurant</md-icon>
          </div>
          <h4 class="title">{{$t('menus')}}</h4>
           <p class="p-2 md-text text-secondary"><md-icon>chat</md-icon> "{{$t('menuTypesDescription')}}: 
           <br> 1- {{$t('takeaway')}} 
           <br> 2- {{$t('onsite')}} 
           <br> 3- {{$t('onlymenu')}}
           <br> 4- {{$t('imagemenu')}}
           <br> 5- {{$t('shipping')}}</p>



        </md-card-header>
        <md-card-content>
          <md-table v-model="jsonData" table-header-color="green" class=" d-none d-sm-block">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Name">{{ item.name }} 
                <span v-if="item.payment" >
                  <md-icon  class="md-primary">payment</md-icon>
                </span></md-table-cell>
              <md-table-cell md-label="type"> <small> <strong>{{ $t('menu_type') }} </strong>:{{ $t(item.menuType) }}</small></md-table-cell>
              <md-table-cell md-label="languages">
                <span v-for="lang in item.menuLanguages" class="m-1 badge badge-primary" :key="lang">{{getLanguageName(lang)}}</span>
              </md-table-cell>
              <md-table-cell md-label="qr">
                <span  v-if="item.stablishment"><md-icon>business</md-icon> {{item.stablishment.name}}</span>
              </md-table-cell>
              <md-table-cell>
                <div class="menu-actions">
                  <md-button class="md-just-icon md-primary mr-2" @click="editMenu(item._id)">
                    <md-icon>edit</md-icon>
                    <md-tooltip md-direction="top">{{$t('edit')}}</md-tooltip>
                  </md-button>
                  <md-button class="md-just-icon md-simple md-danger" @click="confirmDeleteMenu(item._id)">
                    <md-icon>delete</md-icon>
                    <md-tooltip md-direction="top">Delete</md-tooltip>
                  </md-button>
                </div>   
              </md-table-cell>
            </md-table-row>
          </md-table>


          <div v-for="item in jsonData" :key="item._id" class="d-block d-sm-none card p-2 my-2">   

                <div class="menu-actions d-inline float-right">
                  <md-button class="md-just-icon md-primary mr-2" @click="editMenu(item._id)">
                    <md-icon>edit</md-icon>
                    <md-tooltip md-direction="top">{{$t('edit')}}</md-tooltip>
                  </md-button>
                  <md-button class="md-just-icon md-simple md-danger" @click="confirmDeleteMenu(item._id)">
                    <md-icon>delete</md-icon>
                    <md-tooltip md-direction="top">Delete</md-tooltip>
                  </md-button>
                </div>  
              <div class="my-2">
                <span  v-if="item.stablishment"><md-icon>business</md-icon> {{item.stablishment.name}}</span>
              </div>
              <div class="d-inline" md-label="Name"><h4>{{ item.name }}</h4></div>
             
              <div md-label="type">{{ $t(item.menuType) }}</div>
              <div md-label="languages">
                <span v-for="lang in item.menuLanguages" class="m-1 badge badge-primary" :key="lang">{{getLanguageName(lang)}}</span> 
                <span v-if="item.payment" >
                  <md-icon  class="md-primary">payment</md-icon>
                </span>
              </div>
          </div>

        </md-card-content>
      </md-card>
    </div>
          <div v-if="isRestaurant() && !showNewMenuForm">
             <div class="md-layout-item  md-size-100" >
                <stats-card header-color="rose">
                  <template slot="header">
                    <div class="card-icon">
                      <md-icon>menu_book</md-icon>
                    </div>
                    <h4 class="t mt-3 title align-left">{{$t('differentWaysToUse')}}</h4>
                  </template>
                  <template slot="footer">
                  <div class="md-layout" v-if="">
                    <div class="md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33 p-2" >
                     <Carousel id="1" :images="images1"/>  
                    </div>
                    <div class="md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33 p-2" >
                     <Carousel id="2" :images="images2"/>  
                    </div>
                    <div class="md-layout-item md-medium-size-33 md-xsmall-size-100 md-size-33 p-2" >
                     <Carousel id="3" :images="images3"/>  
                    </div>
                    </div>
                  </template>
                </stats-card>
              </div>
          </div>

  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { getAllAction } from "@/pages/Stablishments/actions";
import { getAllMenuAction, saveAllAction } from "@/pages/Menu/actions";
import { getAllProductAction } from "@/pages/Products/actions";
import { Collapse } from "@/components";
import { MenuForm } from "@/pages";
import permissionsMixin from '@/mixins/permissionsMixin';



import {
  Carousel,
  StatsCard,
} from "@/components";
export default {
  mixins: [permissionsMixin],

  created() {
    this.stripeAccountId = localStorage.getItem('stripeAccountId');
    this.getAll();
  },
  computed: {
    languages() {
      return this.$languages;
    },
  },
  components: {
    Collapse,
    Carousel,
    StatsCard,
    MenuForm
  },
  data() {
    return {  
      allStablishments:false,
      stripeAccountId: false,
      jsonData: [],
      allProducts: [], // Productos disponibles
      editingMenuId: null, // Estado para controlar qué menú está en edición
      showNewMenuForm: false,
      showSaveButton: false, // Estado para controlar la visibilidad del botón "Guardar todo"
      newMenu: {
        name: 'Menu',
        menuType: 'onsite',
        products: [],
        requestAdditionalInfo: true,
        primaryColor: '#11b5c9',
        secondaryColor: '#6d6d6d'
      },
      images1: [
        {
          src: "/img/qr-beatch.webp",
          alt: "QR beach",
          title: this.$t('imageSet1_step1_title'),
          description: this.$t('imageSet1_step1_description'),
        },
        {
          src: "/img/receiving-order-beach2.webp",
          alt: "Taking order",
          title: this.$t('imageSet1_step2_title'),
          description: this.$t('imageSet1_step2_description'),
        },
        {
          src: "/img/taking-order-delivery.webp",
          alt: "Delivery",
          title: this.$t('imageSet1_step3_title'),
          description: this.$t('imageSet1_step3_description'),
        }
      ],
      images2: [
        {
          src: "/img/scanning-qr.webp",
          alt: "QR beach",
          title: this.$t('imageSet2_step1_title'),
          description: this.$t('imageSet2_step1_description'),
        },
        {
          src: "/img/order-in-kitchen.webp",
          alt: "Delivery",
          title: this.$t('imageSet2_step2_title'),
          description: this.$t('imageSet2_step2_description'),
        },
        {
          src: "/img/delivery-order-in-table.webp",
          alt: "Taking order",
          title: this.$t('imageSet2_step3_title'),
          description: this.$t('imageSet2_step3_description'),
        },
      ],
      images3: [
        {
          src: "/img/choseorder.webp",
          alt: "Delivery",
          title: this.$t('imageSet3_step1_title'),
          description: this.$t('imageSet3_step1_description'),
        },
        {
          src: "/img/takeorder.webp",
          alt: "Delivery",
          title: this.$t('imageSet3_step2_title'),
          description: this.$t('imageSet3_step2_description'),
        },
        {
          src: "/img/order-in-kitchen.webp",
          alt: "Delivery",
          title: this.$t('imageSet3_step3_title'),
          description: this.$t('imageSet3_step3_description'),
        },
        {
          src: "/img/showorder.webp",
          alt: "Taking order",
          title: this.$t('imageSet3_step4_title'),
          description: this.$t('imageSet3_step4_description'),
        },
        {
          src: "/img/paying.webp",
          alt: "Taking order",
          title: this.$t('imageSet3_step5_title'),
          description: this.$t('imageSet3_step5_description'),
        },
      ],
      translations: {
          language: 'es',
          values: {
            total: '',
            myOrder: '',
            orderNotification: '',
            name: '',
            enterName: '',
            email: '',
            enterEmail: '',
            comments: '',
            enterComments: '',
            code: '',
            phone: '',
            enterPhone: '',
            confirmOrder: '',
            yourOrder: '',
            orderDetails: '',
            sendOrder: '',
            cancel: '',
            orderNumber: '',
            preparingOrder: '',
            dontClose: '',
            orderReadyTitle: '',
            orderReadyText: '',
            welcome: '',
            instructions: ''
          }
        },
    };
  },
  watch: {
    jsonData: {
      handler() {
        this.showSaveButton = true;
      },
      deep: true
    }
  },
  methods: {

    removeMenuImage() {
      this.menuImage = "";
      this.selectedMenuImageFile = null;
    },
    onMenuImageChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.selectedMenuImageFile = files[0];
      this.createImagePreview(this.selectedMenuImageFile, 'menuImage');
    },
    createImagePreview(file, type) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (type === 'menuImage') {
            this.menuImage = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    chooseLanguage(event, menu) {
      let language = this.translations;
      
      // Recorre cada idioma en el array de event
      event.forEach(lang => {
        // Verifica si el idioma ya está presente en el array
        let isLanguagePresent = menu.terms.some(term => term.language === lang);
        
        // Solo agrega el idioma si no está presente
        if (!isLanguagePresent) {
          let newLanguage = { ...language, language: lang };

          Object.keys(newLanguage.values).forEach((key) => {
            // Asignamos la traducción usando this.$t(key)
            newLanguage.values[key] = this.$t(key,newLanguage.language);
          });

          menu.terms.push(newLanguage);
        }
      });

    },
    languageSelected(code,menuLanguages){
      const language = menuLanguages.find(lang => lang === code || lang.codelowercase === code.toLowerCase());
      return language ? true : false;
    },
    getLanguageName(code) {
      return this.$getLanguageName(code);
    },
    async getAll() {
      try {
        const menuResponse = await getAllMenuAction();
        this.jsonData = menuResponse.menus; // Suponiendo que el jsonData se devuelve como `response.data`
    

        const productResponse = await getAllProductAction();
        this.allProducts = productResponse.products;


        const stablishmentsResponse = await getAllAction();
        this.allStablishments = stablishmentsResponse.stablishments

      } catch (error) {
        this.errorMessage = error.message;
      }
    },
    updateJson() {
      // Actualizar jsonData basado en la estructura de menús y productos
      this.showSaveButton = true;
    },
    isSelected(menu, product) {
      return menu.products.some(p => p._id === product._id);
    },
    toggleProduct(menu, product, event) {
      if (event) {
        menu.products.push(product);
      } else {
        menu.products = menu.products.filter(p => p._id !== product._id);
      }
    },
    editMenu(menuId) {
      this.editingMenuId = menuId;
    },
    saveMenu(menu) {
      if(this.isDemo()){
        return;
      }
      if(menu._id){
        this.menus = this.jsonData.map(item => 
          item._id === menu._id ? { ...item, ...menu } : item
        );
      }else{
        this.jsonData.push(menu)
      }
      // Aquí puedes agregar lógica para guardar el menú editado
      this.updateJson();
      this.editingMenuId = null;
    },
    cancelEdit() {
      this.editingMenuId = null;
      this.showNewMenuForm = null;
    },
    confirmDeleteMenu(menuId) {
      if(this.isDemo()){
        return;
      }
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteMenu(menuId);
          this.saveAllChanges()
          Swal.fire(
            'Deleted!',
            'Your menu has been deleted.',
            'success'
          )
        }
      })
    },
    deleteMenu(menuId) {
      // Lógica para eliminar el menú
      this.jsonData = this.jsonData.filter(menu => menu._id !== menuId);
      this.updateJson();
    },
    addNewMenu(menu) {
      if(this.isDemo()){
        return;
      }
      if (!menu.name) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Please fill out all fields!',
        });
        return;
      }
      const newMenu = {
        _id: Date.now().toString(), // Generar un ID único temporalmente
        name: menu.name,
        businessName: "6689769a22c3538df38ba1bb",
        products: this.allProducts,
        requestAdditionalInfo: true,
        primaryColor: menu.secondaryColor,
        secondaryColor: menu.secondaryColor,
      };
      this.jsonData.push(menu);
      this.updateJson();
      this.resetNewMenuForm();
      this.saveAllChanges()
    },
    cancelNewMenu() {
      this.resetNewMenuForm();
    },
    resetNewMenuForm() {
      this.newMenu = {
        name: '',
        menuType: 'onsite',
        products: [],
        requestAdditionalInfo: true,
        primaryColor: '#333',
        secondaryColor: '#2d2d2d',
      },
      this.showNewMenuForm = false;
    },
    confirmSaveAll() {

      if(this.isDemo()){
        return;
      }
      Swal.fire({
        title: this.$t('areYouSure'),
        text: this.$t('doYouWantToSaveAllChanges'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('yesSaveIt')
      }).then((result) => {
        if (result.isConfirmed) {
          this.saveAllChanges();
          Swal.fire(
            this.$t('saved'),
            this.$t('yourChangesHaveBeenSaved'),
            'success'
          )
          this.cancelEdit()
        }
      })
    },
    async saveAllChanges() {
      // Lógica para guardar todos los cambios
      // Esto puede ser una llamada a la API para guardar los datos en el servidor

      await saveAllAction(this.jsonData)
      this.showSaveButton = false;
      this.editingMenuId = null;
      this.getAll();
    }
  }
};
</script>

<style scoped>
.menu {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.menu-actions {
  display: flex;
  justify-content: flex-end;
}
.product {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 5px;
}
.product span {
  flex-grow: 1;
}
</style>
