<template>
  <div class="md-layout text-center mt-4">
    <div
      class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100"
    >
    <form @submit.prevent="handleSubmit">

      <login-card  class="mb-0">
        <div slot="title"  class="image-container logo logoregister mt-4 pt-4">

         <div v-if="subdomain != 'app' 
          && stablishmentReady() 
          && stablishment.coverImage" class="w-100 mb-1 imagebanner">
              <div class="logoresto" :style="'background:url('+stablishment.logo+')'">
                 
              </div>  
        </div>
          <img v-else :src="logo" title="" />
        </div>
        <div slot="buttons">
          <small>{{$t('welcome')}}</small>
          <h3 v-if="subdomain !== 'app' && stablishmentReady()">{{stablishment.name}}</h3>          
         
          <h3 v-if="subdomain !== 'app' && !stablishmentReady()">{{subdomain}}</h3>          
         
          <small v-if="subdomain !== 'app' && stablishmentReady()">{{stablishment.address}}, {{stablishment.country}}</small> 
        </div>
        <md-field class="md-form-group" slot="inputs" v-if="!isDemo(false)">
          <md-icon>email</md-icon>
          <label>Email... </label>
          <md-input v-model="email" type="email"></md-input>
        </md-field>
        <md-field class="md-form-group" slot="inputs" v-if="!showforgot && subdomain != 'app' && !isDemo(false)" >
          <md-icon>lock_outline</md-icon>
          <label>Password...</label>
          <md-input v-model="password" type="password"></md-input>
        </md-field>

        <div slot="footer">
          <div v-if="errorMessage" class="error-message">
            {{ errorMessage }} 
          </div>
          <md-button 
            :style="'background-color:'+stablishment.primaryColor+' !important'"
            v-if="!showforgot &&  subdomain != 'app'" @click="login" class="md-primary md-lg">
            <span v-if="isDemo(false)"> {{ $t('seeDemo') }} </span> 
            <span v-else> {{ $t('login') }} </span>
           
          </md-button>
          
          <md-button v-if="!showforgot &&  subdomain == 'app'" @click="login" class="md-primary md-lg">
             {{ $t('goToMyApp') }} 
          </md-button>
          <p v-if="!showforgot &&  subdomain == 'app'" >{{$t('pleaseinsertemail')}}</p> 
          <md-button v-if="showforgot" @click="sendPassword()" class="md-primary md-lg" :disabled="!email">
            {{$t('sendEmail')}}
          </md-button>
          <div class="my-3">
           
          <div v-if="subdomain == 'app'"> 
          <strong>  {{ $t('register') }}: </strong>
            <br>
            <router-link  class="gotakeit" :to="{ path: '/register' }">  
            GO take it FOOD
           </router-link> 
              |
            <router-link  class="gotakeit" :to="{ path: '/register/gym' }">  
            GO take it GYM
           </router-link> 
           </div>
        <div class="w-100">

            <router-link class="md-primary" :to="{ path: 'loginclient'}" v-if="subdomain !== 'app'">  
              <md-button 
               class="md-button md-sm md-primary">

                 <md-icon class="md-primary">sensor_door</md-icon>  <span class="md-primary" > {{ $t('clientAccess') }}</span>
       
              </md-button>         
          </router-link> 



            </div>
          </div>
            <br>
            <br>
            <span  v-if="!showforgot && !isDemo(false)" class="mt-3 cursor-pointer text-info md-simple md-info bg-white border-0" @click="forgot()">  
           {{ $t('forgotPassword') }}
           </span> 

            <button v-if="showforgot" class="text-info md-simple md-info bg-white border-0" @click="showforgot=false;errorMessage=''">  
           {{ $t('login') }}
           </button> 


        </div>

      </login-card>

    </form>
    </div>
  </div>
</template>

<script>
import { LoginCard } from "@/components";
import { loginAction,getMyProfileAction,confirmAction,sendPassword,changePassword } from "@/pages/Auth/actions";
import Swal from "sweetalert2";
import permissionsMixin from '@/mixins/permissionsMixin';
import { getProfileStablishmentsAction } from "@/pages/Stablishments/actions";

export default {
  mixins: [permissionsMixin],
  components: {
    LoginCard,
  },
  data() {
    return {
      email: null,
      password: null,
      logo: '/img/gotakeit.png',
      errorMessage: null, // Añadimos una propiedad para el mensaje de error
      hastoken: false,
      confirm: false,
      subdomain: false,
      showforgot: false,
      stablishment:false,
      allStablishments: [],
    };
  },
  mounted() {

  },
  created(){

    if(localStorage.getItem('token')){
      this.$router.push('/dashboard');
      return;
    }

    this.subdomain = window.location.hostname;
    // Usamos split para dividir la URL en partes separadas por "."
    const parts = this.subdomain.split(".");
    // Tomamos la primera parte, que es lo que necesitamos
    this.subdomain = parts[0];

    if(this.$route.query.state){
      const state = this.$route.query.state;
      const code = this.$route.query.code;
      const newurl = "https://"+state+".app.gotake.it/#/stripe?code="+code;
  
      window.location.href = newurl
      return;
    }
    if(this.$router.currentRoute.params.token){
      this.hastoken = true
      const response = confirmAction(this.$router.currentRoute.params.token);
     
      if(!response.ok){
        this.confirm = false
      }else{
        this.confirm = true
        Swal.fire({
          title: this.$t('success'),
          text: this.$t('accountconfirm'),
          type: "success",
          position: 'top',
        })
      }
    }
    if(this.$router.currentRoute.params.login){
      localStorage.setItem('token', this.$router.currentRoute.params.login);
      localStorage.setItem('name', this.$router.currentRoute.params.name);
      this.$router.push('/dashboard');
      return;
    }


    this.getStablishments();
    


  },
  methods: {
    async getStablishments(){

      const stablishmentsResponse = await getProfileStablishmentsAction();
      this.allStablishments = stablishmentsResponse.stablishments
      if(this.allStablishments){
      
      this.stablishment = this.allStablishments[0];

              if(this.stablishment && this.stablishment.primaryColor == '#FFFFFF' ||
        this.stablishment.primaryColor == '#ffffff' || 
        !this.stablishment.primaryColor){
          this.stablishment.primaryColor = '#333';
        }
        if(this.stablishment && this.stablishment.secondaryColor == '#FFFFFF' ||
        this.stablishment.secondaryColor == '#ffffff' || 
        !this.stablishment.secondaryColor){
          this.stablishment.secondaryColor = '#333';
        }
      }

      if(this.stablishmentReady()){
        // Cambiar el título de la página
        document.title = this.stablishment.name;
        // Cambiar el favicon de la página
        const link = document.createElement('link');
        link.rel = 'icon';
        link.href = this.stablishment.logo;
        document.head.appendChild(link);
      }

    },
    stablishmentReady(){
        return (
          this.stablishment.name !== 'My Fitness' &&
          this.stablishment.name !== 'Costa Azul' && 
          this.stablishment !== undefined )
    },
    async forgot(){
        Swal.fire({
        title: this.$t('forgotPassword'),
        html: this.$t('passwordResetEmail'),
        type: "warning",
        showCancelButton: true,
        position: 'top',
      }).then((result) => {
       if (result.isConfirmed) {
        this.showforgot = true
                this.errorMessage = this.$t('passwordResetEmail'); // Mensaje de error genérico
        }else{

            this.errorMessage='';
        }
      });
    },
    handleSubmit(event) {
      event.preventDefault();
    },
    async sendPassword() {
        Swal.fire({
          title: this.$t('emailSent'),
          html: this.$t('correctEmail'),
          type: "warning",
          showCancelButton: true,
          position: 'top',
        }).then((result) => {
          if (result.isConfirmed) {
                try {
               sendPassword(this.email);
              } catch (error) {

              }
            this.showforgot = false
          }else{
            this.showforgot = false
            this.errorMessage='';
          }
        });

    },
    async login() {
      try {
        const response = await loginAction(this.email, this.password);

         if(this.subdomain == 'app'){
          this.errorMessage = 'Redirecting...'; // Mensaje de error específico
         }

        if (response.token) {
          this.errorMessage = null;
          if (this.$router.currentRoute.path !== '/dashboard') {
            this.$router.push('/dashboard');
          }
        } else {
         if(this.subdomain !== 'app'){
          this.errorMessage = this.$t('incorrectUserOrPassword'); // Mensaje de error específico
         }
        } 
      } catch (error) { 
            this.errorMessage = this.$t('incorrectUserOrPassword'); // Mensaje de error genérico
      }
    }
  }
};
</script>

<style scoped>

.image-container {
    margin-top: 17px;
    box-shadow: inherit !important;
}

.cursor-pointer {
  cursor:pointer;
}
.image-container {
  max-width: 400px;
}

.error-message {
  color: red;
  margin-top: 10px;
}
.gotakeit{
  font-weigth:bold;
  cursor:pointer !important;
    color: #11b5c9 !important;
}
.login-page .md-card-login .md-card-header {
    margin-bottom: 0px !important;
} 
</style>
