<template>
  <div>
    <md-card>
        <md-card-header>
            <div class="card-icon p-1 bg-danger">
              <md-icon class="">workspaces</md-icon>
            </div>
            <h4 class="title mt-2">Grupos de ejercicios</h4>
             <div class="mt-4 align-left" >
                 <md-icon class="text-danger" >chat</md-icon> "<span>Los grupos de ejercicios permiten no tener que repetir la creacion de los mismos ejercicios cada vez que se crea una rutina. Puede crear grupos aquí y luego reutilizarlos dentro de las rutinas asignandolos</span>"
                </div>

        </md-card-header>
        <md-card-content>
           <TrainingList/>
        </md-card-content>
    </md-card>
  </div>
</template>

<script>
import permissionsMixin from '@/mixins/permissionsMixin';
import {TrainingList } from "@/pages/Training";



export default {
  mixins: [permissionsMixin],
  data() {
    return {
    };
  },
  components: {
    TrainingList
  },
  async created() {
  
  },
  methods:{
 
  
  },
};
</script>

