<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
     


      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-rose">
          <div class="card-icon">
            <md-icon>settings</md-icon>
          </div>
            <h4 class="title">{{ $t('myProfile') }}</h4>
        </md-card-header>
        <md-card-content>
          <NewUserForm 
          v-if="useredit && isRestaurant()"
          :required="['name', 'email']" 
          :hide="['paymentDate','role','paymentType','password']"
          :roles="[]"
          :anonymous="false"
          type="restaurant"
          :user="useredit"
          @userCreated="userCreated($event)" 
          ></NewUserForm>

          <NewUserForm 
          v-if="useredit && isGym()"
          :required="['name', 'email']" 
          :hide="['paymentDate','role','paymentType','password','identifier','imc']"
          :roles="[]"
          :anonymous="false"
          type="gym"
          :user="useredit"
          @userCreated="userCreated($event)" 
          ></NewUserForm>

          <NewUserForm 
          v-if="useredit && isClientGym()"
          :required="['name', 'email']" 
          :hide="['paymentDate','role','paymentType','password','identifier']"
          :roles="[]"
          :anonymous="false"
          type="gym"
          :user="useredit"
          @userCreated="userCreated($event)" 
          ></NewUserForm>

        </md-card-content>
      </md-card>

    </div>
  </div>
</template>

<script>
import { saveAction, getSettingsAction } from "@/pages/Auth/actions";
import Swal from 'sweetalert2';
import { Stripe } from "@/pages";
import permissionsMixin from '@/mixins/permissionsMixin';
import { getPriceAction,oauthAction } from "@/pages/MercadoPago/actions";
import NewUserForm from "@/pages/Dashboard/Pages/UserProfile/NewUserForm.vue";
import { getMyProfileAction } from "@/pages/Users/actions";


export default {
  name: "Settings",
  components: {
    Stripe,
    NewUserForm
  },
  mixins: [permissionsMixin],

  props: {
    dashboard: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      role: false,
      nameBusiness: "",
      emailBusiness: "",
      currency: "",
      waitingDays:10,
      useredit:false,
      register:true,
      currencyPosition: "",
      subdomain: "",
      currencyOptions:[]
    };
  },

  computed: {
    currencySymbol() {
      const selectedCurrency = this.currencyOptions.find(option => option.value === this.currency);
      return selectedCurrency ? selectedCurrency.symbol : '';
    }
  },

  created() {
    this.getMyProfile();
    this.currencyOptions = this.$currencies;
    this.subdomain = window.location.hostname;
    this.role = localStorage.getItem('role');
    const mercadoapagooauth = localStorage.getItem('mercadoapagooauth');
    if(this.$route.path == '/mercadopago/confirm' && this.$route.query.code && this.$route.query.state){
      const state = this.$route.query.state;
      if(mercadoapagooauth == state){
        this.registerToken()
      }
    }
  },

  methods: {
    async getMyProfile(){
      this.useredit = await getMyProfileAction();
    },
    async registerToken(){
       await oauthAction(this.$route.query.code);
    },

    userCreated(event){

        Swal.fire({
          title: this.$t('success'),
          icon: 'success',
          confirmButtonText: 'OK'
        });
    },
    async fetchBusinessData() {
      try {
        const response = await getSettingsAction();
        const data = response.result;
        this.emailBusiness = data.email === "contact@rodisio.com" ? "" : data.email || "";
        this.nameBusiness = data.name || "";
        this.currency = data.currency || "usd"; // valor predeterminado
        this.currencyPosition = data.currencyPosition || "after";
        if(data.waitingDays){
          this.waitingDays = data.waitingDays
        }
        if(data.register){
          this.register = data.register
        }
      } catch (error) {
        console.error("Error fetching business data:", error);
      }
    },
    async saveSettings() {

      if(this.isDemo()){
        return;
      }

      const data = {
        name: this.nameBusiness,
        email: this.emailBusiness,
        currency: this.currency,
        currencyPosition: this.currencyPosition,
        waitingDays: this.waitingDays,
        register: this.register
      };

      try {
        await saveAction(data);
        Swal.fire({
          title: this.$t('success'),
          text: this.$t('business_info_updated'),
          icon: 'success',
          confirmButtonText: 'OK'
        });
      } catch (error) {
        console.error("Error saving business data:", error);
        Swal.fire({
          title: this.$t('error'),
          text: this.$t('error_updating_business_info'),
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    },
  },

  async mounted() {
    this.fetchBusinessData();
  }
};
</script>


<style lang="scss" scoped>
.md-card .md-card-actions {
  border: none;
}

.md-checkbox,
.md-radio {
  margin-top: 15px;
  margin-bottom: 0.5rem;
}

.md-checkbox,
.md-radio {
  display: flex;
}

.form-control-static {
  margin-top: 6px;
}
</style>
