<template>
  <div>
    <div class="header text-center">
      <h3 class="title">Evolución del sistema</h3>
      <p class="category">
          Aqui encontrarás los desarrollos y mejoras que hacemos cada semana.
      </p>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-size-50 md-small-size-100">
        <md-card>
          <md-card-header>
              <div class="card-icon p-1 bg-danger">
                <md-icon class="">today</md-icon>
              </div>
              <h4 class="title mt-2">Hecho</h4>
          </md-card-header>

          <md-card-content>
            <div class="alert alert-danger alert-with-icon"
              data-notify="container">
              <i data-notify="icon" class="material-icons">computer</i>
              <span data-notify="message">15/10/2024: Sistema de clases, correcion de errores.</span>
            </div>
            <div class="alert alert-danger alert-with-icon"
              data-notify="container">
              <i data-notify="icon" class="material-icons">computer</i>
              <span data-notify="message">18/10/2024: los usuarios pueden insertar su DNI y teléfono como identificador al momento de crear su cuenta.</span>
            </div>
              <div  class="alert alert-danger alert-with-icon"
                data-notify="container">
                <i data-notify="icon" class="material-icons">computer</i>
                <span data-notify="message">19/10/2024:   Ahora el cliente puede modificar su foto de perfil y otros datos dentro de us propia cuenta.<br>
              Ahora puedes editar tu propia foto  en "mi perfil".
              <br>
              Ahora puedes filtrar por clientes en el listado de clientes.</span>
              </div>
              <div  class="alert alert-danger alert-with-icon"
                data-notify="container">
                <i data-notify="icon" class="material-icons">computer</i>
                <span data-notify="message">20/10/2024: Ahora puedes dejar registrado el precio que paga cada uno de tus clientes </span>
              </div>
              <div  class="alert alert-danger alert-with-icon"
                data-notify="container">
                <i data-notify="icon" class="material-icons">computer</i>
                <span data-notify="message">29/10/2024: Manejo de rutinas de gimnasio</span>
              </div>
              <div  class="alert alert-danger alert-with-icon"
                data-notify="container">
                <i data-notify="icon" class="material-icons">computer</i>
                <span data-notify="message">02/11/2024: Ahora se generará una orden de pago cuando marques un cliente como pagado. Eso nos permitirá generar gráficos con estadísticas financieras para vos en el futuro</span>
              </div>

          </md-card-content>
        </md-card>
      </div>
      <div class="md-layout-item md-size-50 md-small-size-100">
        <md-card>
          <md-card-header>
              <div class="card-icon p-1 bg-primary">
                <md-icon class="">today</md-icon>
              </div>
            <h4 class="title mt-2">Lo que viene</h4>
          </md-card-header>
            <md-card-content>
              <div  class="alert alert-primary alert-with-icon"
                data-notify="container">
                <i data-notify="icon" class="material-icons">computer</i>
                <span data-notify="message">Sistema de cobros con mercado pago para que el cliente pague directamente al gimnasio y que quede registrado el pago en Go Take it.</span>
              </div>
              <div  class="alert alert-primary alert-with-icon"
                data-notify="container">
                <i data-notify="icon" class="material-icons">computer</i>
                <span data-notify="message">Permitir que las actividades recurrentes sin una fecha especifica que se repiten cada semana, tengan también la posibilidad de inscripción</span>
              </div>
              <div  class="alert alert-primary alert-with-icon"
                data-notify="container">
                <i data-notify="icon" class="material-icons">computer</i>
                <span data-notify="message">Crear perfil profesor y Poder asignar profesores a los alumnos</span>
              </div>
              <div  class="alert alert-primary alert-with-icon"
                data-notify="container">
                <i data-notify="icon" class="material-icons">computer</i>
                <span data-notify="message">Estadísticas financieras</span>
              </div>
              <div  class="alert alert-primary alert-with-icon"
                data-notify="container">
                <i data-notify="icon" class="material-icons">computer</i>
                <span data-notify="message">Ventas de productos online a través de Go Take it</span>
              </div>
            </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "@/components";
export default {
  components: {
    Modal,
  },
  props: {
    registerImg: {
      type: String,
      default: "/img/card-1.jpg",
    },
    applyImg: {
      type: String,
      default: "/img/card-2.jpg",
    },
  },
  data() {
    return {
      type: ["", "info", "success", "warning", "danger"],
      notifications: {
        topCenter: false,
      },
      classicModal: false,
      noticeModal: false,
      smallAlertModal: false,
    };
  },
  methods: {
    notifyVue(verticalAlign, horizontalAlign) {
      var color = Math.floor(Math.random() * 4 + 1);
      this.$notify({
        timeout: 2500,
        message:
          "Welcome to <b>Vue Material Dashboard Pro</b> - a beautiful admin panel for every web developer.",
        icon: "add_alert",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: this.type[color],
      });
    },
    classicModalHide: function () {
      this.classicModal = false;
    },
    noticeModalHide: function () {
      this.noticeModal = false;
    },
    smallAlertModalHide: function () {
      this.smallAlertModal = false;
    },
  },
};
</script>
<style lang="scss">
.text-center {
  text-align: center;
}

.places-buttons + div .md-button:not(:last-child) {
  margin-right: 5px;
}
</style>
