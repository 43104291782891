<template>
  <div>
   
  </div>
</template>

<script>

export default {
  props: {
    userId:String,
    typeEvent:String,
  },
  data() {
    return { 
      socket:false,
      reconnectInterval: 5000, // Intervalo de tiempo para intentar reconectar (5 segundos)
    };
  },
  created() {
    // Establecer la conexión WebSocket cuando el componente se monte
    this.connectWebSocket();
    //this.startAutoRefresh();
  },
  beforeDestroy() { // O 'unmounted' si usas Vue 3
    this.disconnectWebSocket(); // Asegurar que cerramos la conexión cuando el componente se destruye
  },
  methods: {
    startAutoRefresh() {
      this.autoRefreshInterval = setInterval(() => {
        
        
      }, 10000);
    },
    disconnectWebSocket() {
      if (this.socket) {
        this.socket.close(); // Cerrar la conexión WebSocket
      }
    },
    connectWebSocket() {
      const ws = new WebSocket(process.env.VUE_APP_WEBHOOK_API_URL+'/ws/orders');
      this.socket = ws;
      // Cuando se recibe un mensaje desde el servidor WebSocket
      ws.onmessage = (event) => {
        const message = JSON.parse(event.data);

        // Verificar si el evento es 'new_order'
        if (message.event === this.typeEvent) {
            this.$emit("message",message.data)
        }
      };

      // Conexión establecida
      ws.onopen = () => {
        let userIdMessage = JSON.stringify({ type: 'identify', userId: this.userId+'-'+Date.now() });
        ws.send(userIdMessage);
      };

      // Manejar la desconexión
      ws.onclose = () => {
        setTimeout(() => {  
                window.location.reload(); 

        }, 1000);
      };

      // Manejar errores en la conexión
      ws.onerror = (error) => {
        console.error('Error en WebSocket:', error);
      };
    },
  },
};
</script>

<style scoped>
h1 {
  color: #333;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
}

strong {
  font-weight: bold;
}

.rotating-border {
  width: 100px;
  height: 100px;
  margin-left:calc(50% - 50px);
  position: relative;
  text-align: center;
  line-height: 200px; /* Alinea el texto verticalmente */
}

.rotating-border::before {
  content: '';
  position: absolute;
  border-radius:50%;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border: 3px dashed #3498db;
  box-sizing: border-box;
  animation: rotate-border 10s linear infinite;
}

@keyframes rotate-border {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.boxwiting{
      border: 2px solid #c8c8c8;
    min-height: 300px;
    border-style: dashed;
    padding-top: 95px;
}
.menu {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.menu-actions {
  display: flex;
  justify-content: flex-end;
}
.product {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 5px;
}
.product span {
  flex-grow: 1;
}
</style>
