<template>
  <div class="md-layout">
    <div class="md-layout-item w-100 bg-white text-primary">
            <div class="image-container logo">
          <img :src="'/img/gotakeit.png'" title="" />
        </div>

      <div class="terms-and-conditions">
           <!-- English Version -->

           <collapse
              id="collapsetotal23"
              :collapse="['Terms and Conditions of Use']"
              iconlabel="gavel"
              icon="keyboard_arrow_up"
              :open="false"
              class="bg-white p-3">
               <template slot="md-collapse-pane-1">
        <div id="terms-en">
          <h1>Terms and Conditions of Use</h1>
          
          <h2>1. Introduction</h2>
          <p>
            These Terms and Conditions ("Terms") govern the use of the QR menu application ("the Application") provided by GoTakeIT ("we" or "the Company"). By using the Application, you ("the User") agree to be bound by these Terms. If you do not agree with these Terms, you must stop using the Application immediately.
          </p>
          
          <h2>2. Description of Service</h2>
          <p>
            The Application offers restaurants a platform to create and manage digital menus accessible via QR codes. Diners can scan these QR codes to view the restaurant's menu on their mobile devices.
          </p>
          
          <h2>3. Use of the Application</h2>
          <ul>
            <li>The Application is intended for personal, non-commercial use only, except for restaurants using the Application to display their menus.</li>
            <li>The User is responsible for ensuring that their device is compatible with the Application and has a suitable internet connection.</li>
            <li>It is prohibited to use the Application for illegal, immoral, or unauthorized activities.</li>
          </ul>
          
          <h2>4. Limitation of Liability</h2>
          <ul>
            <li><strong>Relationship with Restaurants:</strong> The Application acts solely as a menu display platform and is not responsible for the quality, availability, prices, dish descriptions, or any other aspects related to the products and services offered by the restaurants.</li>
            <li><strong>Issues with the Restaurant:</strong> GoTakeIT is not responsible for any issues that may arise between the User and the restaurant, including but not limited to order errors, food quality, service times, hygiene conditions, or any other matters related to the restaurant experience.</li>
            <li><strong>Menu Content:</strong> GoTakeIT is not responsible for the accuracy or truthfulness of the content posted in the menus, as this information is provided and managed directly by the restaurants.</li>
            <li><strong>Application Availability:</strong> GoTakeIT does not guarantee that the Application will be available at all times or that it will be free of errors or interruptions. We assume no responsibility for any losses or damages that may arise from the unavailability or malfunction of the Application.</li>
          </ul>
          
          <h2>5. Intellectual Property</h2>
          <p>
            All intellectual property rights related to the Application and its content, including but not limited to text, graphics, logos, icons, images, and software, are the exclusive property of GoTakeIT or its licensors.
          </p>
          <p>
            Unauthorized reproduction, distribution, modification, or any other use of the Application's content is prohibited.
          </p>
          
          <h2>6. Changes to Terms</h2>
          <p>
            GoTakeIT reserves the right to modify these Terms at any time. Any changes will be effective immediately upon posting in the Application. Users are encouraged to review these Terms periodically to stay informed of any changes.
          </p>
          
          <h2>7. Privacy</h2>
          <p>
            The use of the Application is subject to our Privacy Policy, which describes how we collect, use, and protect User information.
          </p>
          
          <h2>8. Governing Law and Jurisdiction</h2>
          <p>
            These Terms are governed by the laws of European union.
          </p>
          
          <h2>9. Contact</h2>
          <p>
            If you have any questions about these Terms, you can contact us via email at: <a href="mailto:contact@gotake.it">contact@gotake.it</a>.
          </p>
        </div>
        </template>
        </collapse>


           <collapse
              id="collapsetotal23"
              :collapse="['Términos y Condiciones de Uso']"
              iconlabel="gavel"
              icon="keyboard_arrow_up"
              :open="false"
              class="bg-white p-3">
               <template slot="md-collapse-pane-1">
        <!-- Spanish Version -->
        <div id="terms-es">
          <h1>Términos y Condiciones de Uso</h1>
          
          <h2>1. Introducción</h2>
          <p>
            Estos Términos y Condiciones ("Términos") regulan el uso de la aplicación de menú QR ("la Aplicación") proporcionada por GoTakeIT ("nosotros" o "la Empresa"). Al utilizar la Aplicación, usted ("el Usuario") acepta estar vinculado por estos Términos. Si no está de acuerdo con estos Términos, debe dejar de utilizar la Aplicación de inmediato.
          </p>
          
          <h2>2. Descripción del Servicio</h2>
          <p>
            La Aplicación ofrece a los restaurantes una plataforma para crear y gestionar menús digitales accesibles mediante códigos QR. Los comensales pueden escanear estos códigos QR para ver el menú del restaurante en sus dispositivos móviles.
          </p>
          
          <h2>3. Uso de la Aplicación</h2>
          <ul>
            <li>La Aplicación está destinada únicamente para uso personal y no comercial, excepto para los restaurantes que utilizan la Aplicación para mostrar sus menús.</li>
            <li>El Usuario es responsable de asegurarse de que su dispositivo es compatible con la Aplicación y de que dispone de una conexión a internet adecuada.</li>
            <li>Queda prohibido el uso de la Aplicación para actividades ilegales, inmorales o no autorizadas.</li>
          </ul>
          
          <h2>4. Limitaciones de Responsabilidad</h2>
          <ul>
            <li><strong>Relación con los Restaurantes:</strong> La Aplicación actúa únicamente como una plataforma de visualización de menús y no es responsable de la calidad, disponibilidad, precios, descripción de los platos, o cualquier otro aspecto relacionado con los productos y servicios ofrecidos por los restaurantes.</li>
            <li><strong>Problemas con el Restaurante:</strong> GoTakeIT no es responsable de problemas que puedan surgir entre el Usuario y el restaurante, incluyendo pero no limitándose a errores en los pedidos, calidad de los alimentos, tiempos de servicio, condiciones higiénicas, o cualquier otra cuestión relacionada con la experiencia en el restaurante.</li>
            <li><strong>Contenido de los Menús:</strong> GoTakeIT no se responsabiliza por la exactitud o veracidad del contenido publicado en los menús, ya que esta información es proporcionada y gestionada directamente por los restaurantes.</li>
            <li><strong>Disponibilidad de la Aplicación:</strong> GoTakeIT no garantiza que la Aplicación estará disponible en todo momento ni que estará libre de errores o interrupciones. No asumimos responsabilidad alguna por pérdidas o daños que puedan derivarse de la falta de disponibilidad o fallos de la Aplicación.</li>
          </ul>
          
          <h2>5. Propiedad Intelectual</h2>
          <p>
            Todos los derechos de propiedad intelectual relacionados con la Aplicación y su contenido, incluyendo pero no limitándose a textos, gráficos, logotipos, íconos, imágenes y software, son propiedad exclusiva de GoTakeIT o de sus licenciantes.
          </p>
          <p>
            Queda prohibida la reproducción, distribución, modificación o cualquier otro uso no autorizado del contenido de la Aplicación.
          </p>
          
          <h2>6. Modificaciones a los Términos</h2>
          <p>
            GoTakeIT se reserva el derecho de modificar estos Términos en cualquier momento. Cualquier cambio será efectivo inmediatamente después de su publicación en la Aplicación. Se recomienda a los Usuarios revisar periódicamente estos Términos para estar al tanto de cualquier cambio.
          </p>
          
          <h2>7. Privacidad</h2>
          <p>
            El uso de la Aplicación está sujeto a nuestra Política de Privacidad, la cual describe cómo recopilamos, utilizamos y protegemos la información personal del Usuario.
          </p>
          
          <h2>8. Ley Aplicable y Jurisdicción</h2>
          <p>
            Estos Términos se rigen por las leyes de la unión europea.
          </p>
          
          <h2>9. Contacto</h2>
          <p>
            Si tiene alguna pregunta sobre estos Términos, puede contactarnos a través de nuestro correo electrónico: <a href="mailto:contact@gotake.it">contact@gotake.it</a>.
          </p>
        </div>
        </template>
        </collapse>
    <collapse
              id="collapsetotal23"
              :collapse="['Conditions Générales d\'Utilisation']"
              iconlabel="gavel"
              icon="keyboard_arrow_up"
              :open="false"
              class="bg-white p-3">
               <template slot="md-collapse-pane-1">
        <!-- French Version -->
        <div id="terms-fr">
          <h1>Conditions Générales d'Utilisation</h1>
          
          <h2>1. Introduction</h2>
          <p>
            Ces Conditions Générales d'Utilisation ("Conditions") régissent l'utilisation de l'application de menu QR ("l'Application") fournie par GoTakeIT ("nous" ou "la Société"). En utilisant l'Application, vous ("l'Utilisateur") acceptez d'être lié par ces Conditions. Si vous n'êtes pas d'accord avec ces Conditions, vous devez cesser d'utiliser l'Application immédiatement.
          </p>
          
          <h2>2. Description du Service</h2>
          <p>
            L'Application offre aux restaurants une plateforme pour créer et gérer des menus numériques accessibles via des codes QR. Les clients peuvent scanner ces codes QR pour consulter le menu du restaurant sur leurs appareils mobiles.
          </p>
          
          <h2>3. Utilisation de l'Application</h2>
          <ul>
            <li>L'Application est destinée à un usage personnel et non commercial uniquement, sauf pour les restaurants utilisant l'Application pour afficher leurs menus.</li>
            <li>L'Utilisateur est responsable de s'assurer que son appareil est compatible avec l'Application et qu'il dispose d'une connexion Internet adéquate.</li>
            <li>L'utilisation de l'Application à des fins illégales, immorales ou non autorisées est interdite.</li>
          </ul>
          
          <h2>4. Limitation de Responsabilité</h2>
          <ul>
            <li><strong>Relations avec les Restaurants:</strong> L'Application agit uniquement en tant que plateforme d'affichage de menus et n'est pas responsable de la qualité, de la disponibilité, des prix, de la description des plats ou de tout autre aspect lié aux produits et services offerts par les restaurants.</li>
            <li><strong>Problèmes avec le Restaurant:</strong> GoTakeIT n'est pas responsable des problèmes qui peuvent survenir entre l'Utilisateur et le restaurant, y compris mais sans s'y limiter les erreurs de commande, la qualité des aliments, les temps de service, les conditions d'hygiène ou toute autre question liée à l'expérience au restaurant.</li>
            <li><strong>Contenu des Menus:</strong> GoTakeIT n'est pas responsable de l'exactitude ou de la véracité du contenu publié dans les menus, car ces informations sont fournies et gérées directement par les restaurants.</li>
            <li><strong>Disponibilité de l'Application:</strong> GoTakeIT ne garantit pas que l'Application sera disponible à tout moment ni qu'elle sera exempte d'erreurs ou d'interruptions. Nous n'assumons aucune responsabilité pour toute perte ou dommage pouvant résulter de l'indisponibilité ou du dysfonctionnement de l'Application.</li>
          </ul>
          
          <h2>5. Propriété Intellectuelle</h2>
          <p>
            Tous les droits de propriété intellectuelle liés à l'Application et à son contenu, y compris mais sans s'y limiter les textes, graphiques, logos, icônes, images et logiciels, sont la propriété exclusive de GoTakeIT ou de ses concédants.
          </p>
          <p>
            Toute reproduction, distribution, modification ou tout autre usage non autorisé du contenu de l'Application est interdit.
          </p>
          
          <h2>6. Modifications des Conditions</h2>
          <p>
            GoTakeIT se réserve le droit de modifier ces Conditions à tout moment. Toute modification sera effective immédiatement après sa publication dans l'Application. Il est recommandé aux Utilisateurs de consulter périodiquement ces Conditions pour se tenir informés de tout changement.
          </p>
          
          <h2>7. Confidentialité</h2>
          <p>
            L'utilisation de l'Application est soumise à notre Politique de Confidentialité, qui décrit comment nous collectons, utilisons et protégeons les informations des Utilisateurs.
          </p>
          
          <h2>8. Droit Applicable et Juridiction</h2>
          <p>
            Ces Conditions sont régies par les lois de Union européenne.
          </p>
          
          <h2>9. Contact</h2>
          <p>
            Si vous avez des questions concernant ces Conditions, vous pouvez nous contacter par email à l'adresse suivante : <a href="mailto:contact@gotake.it">contact@gotake.it</a>.
          </p>
        </div>
        </template>
        </collapse>

    <collapse
              id="collapsetotal23"
              :collapse="['Termos e Condições de Uso']"
              iconlabel="gavel"
              icon="keyboard_arrow_up"
              :open="false"
              class="bg-white p-3">
               <template slot="md-collapse-pane-1">
        <!-- Portuguese Version -->
        <div id="terms-pt">
          <h1>Termos e Condições de Uso</h1>
          
          <h2>1. Introdução</h2>
          <p>
            Estes Termos e Condições ("Termos") regem o uso do aplicativo de menu QR ("o Aplicativo") fornecido pela GoTakeIT ("nós" ou "a Empresa"). Ao usar o Aplicativo, você ("o Usuário") concorda em estar vinculado por estes Termos. Se você não concorda com estes Termos, deve parar de usar o Aplicativo imediatamente.
          </p>
          
          <h2>2. Descrição do Serviço</h2>
          <p>
            O Aplicativo oferece aos restaurantes uma plataforma para criar e gerenciar menus digitais acessíveis por meio de códigos QR. Os clientes podem escanear esses códigos QR para visualizar o menu do restaurante em seus dispositivos móveis.
          </p>
          
          <h2>3. Uso do Aplicativo</h2>
          <ul>
            <li>O Aplicativo é destinado apenas para uso pessoal e não comercial, exceto para restaurantes que utilizam o Aplicativo para exibir seus menus.</li>
            <li>O Usuário é responsável por garantir que seu dispositivo seja compatível com o Aplicativo e que possua uma conexão de internet adequada.</li>
            <li>É proibido usar o Aplicativo para atividades ilegais, imorais ou não autorizadas.</li>
          </ul>
          
          <h2>4. Limitação de Responsabilidade</h2>
          <ul>
            <li><strong>Relação com os Restaurantes:</strong> O Aplicativo atua apenas como uma plataforma de exibição de menus e não é responsável pela qualidade, disponibilidade, preços, descrições dos pratos ou quaisquer outros aspectos relacionados aos produtos e serviços oferecidos pelos restaurantes.</li>
            <li><strong>Problemas com o Restaurante:</strong> A GoTakeIT não é responsável por quaisquer problemas que possam surgir entre o Usuário e o restaurante, incluindo, mas não se limitando a erros nos pedidos, qualidade dos alimentos, tempos de serviço, condições de higiene ou quaisquer outras questões relacionadas à experiência no restaurante.</li>
            <li><strong>Conteúdo dos Menus:</strong> A GoTakeIT não é responsável pela precisão ou veracidade do conteúdo publicado nos menus, pois essas informações são fornecidas e gerenciadas diretamente pelos restaurantes.</li>
            <li><strong>Disponibilidade do Aplicativo:</strong> A GoTakeIT não garante que o Aplicativo estará disponível a qualquer momento ou que estará livre de erros ou interrupções. Não assumimos qualquer responsabilidade por perdas ou danos que possam surgir da indisponibilidade ou mau funcionamento do Aplicativo.</li>
          </ul>
          
          <h2>5. Propriedade Intelectual</h2>
          <p>
            Todos os direitos de propriedade intelectual relacionados ao Aplicativo e seu conteúdo, incluindo, mas não se limitando a textos, gráficos, logotipos, ícones, imagens e software, são de propriedade exclusiva da GoTakeIT ou de seus licenciadores.
          </p>
          <p>
            É proibida a reprodução, distribuição, modificação ou qualquer outro uso não autorizado do conteúdo do Aplicativo.
          </p>
          
          <h2>6. Alterações nos Termos</h2>
          <p>
            A GoTakeIT reserva-se o direito de modificar estes Termos a qualquer momento. Quaisquer alterações serão efetivas imediatamente após a publicação no Aplicativo. Os Usuários são incentivados a revisar periodicamente estes Termos para se manterem informados sobre quaisquer alterações.
          </p>
          
          <h2>7. Privacidade</h2>
          <p>
            O uso do Aplicativo está sujeito à nossa Política de Privacidade, que descreve como coletamos, usamos e protegemos as informações dos Usuários.
          </p>
          
          <h2>8. Lei Aplicável e Jurisdição</h2>
          <p>
            Estes Termos são regidos pelas leis de European Union.
          </p>
          
          <h2>9. Contato</h2>
          <p>
            Se você tiver alguma dúvida sobre estes Termos, pode entrar em contato conosco por e-mail em: <a href="mailto:contact@gotake.it">contact@gotake.it</a>.
          </p>
        </div>

        </template>
        </collapse>

    <collapse
              id="collapsetotal23"
              :collapse="['Умови та положення використання']"
              iconlabel="gavel"
              icon="keyboard_arrow_up"
              :open="false"
              class="bg-white p-3">
               <template slot="md-collapse-pane-1">
<!-- Ukrainian Version -->
<div id="terms-ukr">
  <h1>Умови та положення використання</h1>
  
  <h2>1. Вступ</h2>
  <p>
    Ці Умови та положення ("Умови") регулюють використання додатка меню QR ("Додаток"), що надається компанією GoTakeIT ("ми" або "Компанія"). Використовуючи Додаток, ви ("Користувач") погоджуєтесь дотримуватися цих Умов. Якщо ви не погоджуєтесь із цими Умовами, ви повинні негайно припинити використання Додатка.
  </p>
  
  <h2>2. Опис Послуги</h2>
  <p>
    Додаток пропонує ресторанам платформу для створення та управління цифровими меню, доступними через QR-коди. Відвідувачі можуть сканувати ці QR-коди, щоб переглянути меню ресторану на своїх мобільних пристроях.
  </p>
  
  <h2>3. Використання Додатка</h2>
  <ul>
    <li>Додаток призначений виключно для особистого некомерційного використання, за винятком випадків, коли ресторани використовують Додаток для показу своїх меню.</li>
    <li>Користувач несе відповідальність за забезпечення сумісності свого пристрою з Додатком та наявності відповідного інтернет-з'єднання.</li>
    <li>Забороняється використовувати Додаток для незаконних, аморальних або несанкціонованих дій.</li>
  </ul>
  
  <h2>4. Обмеження відповідальності</h2>
  <ul>
    <li><strong>Відносини з ресторанами:</strong> Додаток виконує виключно роль платформи для відображення меню і не несе відповідальності за якість, доступність, ціни, опис страв або будь-які інші аспекти, пов'язані з продуктами та послугами, що пропонуються ресторанами.</li>
    <li><strong>Проблеми з рестораном:</strong> GoTakeIT не несе відповідальності за будь-які проблеми, які можуть виникнути між Користувачем і рестораном, включаючи, але не обмежуючись, помилками в замовленнях, якістю їжі, часом обслуговування, санітарними умовами або будь-якими іншими питаннями, пов'язаними з досвідом у ресторані.</li>
    <li><strong>Зміст меню:</strong> GoTakeIT не несе відповідальності за точність або правдивість змісту, розміщеного в меню, оскільки ця інформація надається і управляється безпосередньо ресторанами.</li>
    <li><strong>Доступність Додатка:</strong> GoTakeIT не гарантує, що Додаток буде доступний у будь-який час або що він буде без помилок чи перерв. Ми не несемо відповідальності за будь-які втрати або збитки, які можуть виникнути через недоступність або несправність Додатка.</li>
  </ul>
  
  <h2>5. Інтелектуальна власність</h2>
  <p>
    Усі права інтелектуальної власності, пов'язані з Додатком і його вмістом, включаючи, але не обмежуючись текстами, графікою, логотипами, іконками, зображеннями та програмним забезпеченням, є виключною власністю GoTakeIT або її ліцензіарів.
  </p>
  <p>
    Забороняється несанкціоноване відтворення, розповсюдження, модифікація або будь-яке інше використання вмісту Додатка.
  </p>
  
  <h2>6. Зміни до Умов</h2>
  <p>
    GoTakeIT залишає за собою право змінювати ці Умови в будь-який час. Будь-які зміни набирають чинності негайно після їх публікації в Додатку. Користувачам рекомендується періодично переглядати ці Умови, щоб бути в курсі будь-яких змін.
  </p>
  
  <h2>7. Конфіденційність</h2>
  <p>
    Використання Додатка підпорядковується нашій Політиці конфіденційності, яка описує, як ми збираємо, використовуємо та захищаємо інформацію Користувача.
  </p>
  
  <h2>8. Законодавство і юрисдикція</h2>
  <p>
    Ці Умови регулюються законами European Union.
  </p>
  
  <h2>9. Контакти</h2>
  <p>
    Якщо у вас є будь-які запитання щодо цих Умов, ви можете зв'язатися з нами електронною поштою за адресою: <a href="mailto:contact@gotake.it">contact@gotake.it</a>.
  </p>
</div>

        </template>
        </collapse>

      </div>
    </div>
  </div>
</template>

<script>
import { Collapse } from "@/components";


export default {
  components: {
    Collapse
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="css">
.terms-and-conditions {
  padding: 20px;
  line-height: 1.6;
}

.terms-and-conditions h1,
.terms-and-conditions h2 {
  color: #333;
}

.terms-and-conditions p,
.terms-and-conditions ul {
  margin-bottom: 20px;
  color: #555;
}

.terms-and-conditions a {
  color: #007bff;
  text-decoration: none;
}

.terms-and-conditions a:hover {
  text-decoration: underline;
}
</style>

