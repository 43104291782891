<template>
  <div>


    <md-card>
        <md-card-header>
            <div class="card-icon p-1 bg-danger">
              <md-icon class="">workspaces</md-icon>
            </div>
            <h4 class="title mt-2">Grupos de ejercicios</h4>
           

        </md-card-header>
        <md-card-content>
          <md-table 

      v-if="!open"
      v-model="trainings"
      :md-sort.sync="currentSort"
      :md-sort-order.sync="currentSortOrder"
      class="paginated-table table-striped table-hover paginated-table-exercises"
    >
      <md-table-row 
        slot="md-table-row"  
        slot-scope="{ item }"
      >
        <!-- Columna Nombre del Entrenamiento -->
        <md-table-cell :md-label="$t('name')" md-sort-by="name">
          <div class="mt-2 float-left">
            <md-button class="md-button md-just-icon md-success" @click="add(item)">
              <md-icon>add</md-icon>
            </md-button>
          </div>
          <div class="pt-3">
            <span class="ml-2"> {{ item.name }}</span>
          </div>
        </md-table-cell>

        <!-- Columna Elemento Usado -->
        <md-table-cell :md-label="'Ejercicios'">
        <ul>
         <li v-for="element in item.myexercises" > <span  >{{ element.name }}</span> </li>
          </ul>
        </md-table-cell>


        <!-- Columna de Acciones -->
       
      </md-table-row>
    </md-table>



            <div class="text-center md-layout">
              <div class="text-center  md-layout-item md-layout-100">
                <md-button 
                  @click="$emit('cancel')"
                class="w-100 md-button md-danger" >
                {{$t('cancel')}}
                </md-button>
              </div>
            </div>

        </md-card-content>
    </md-card>

      
  
  

  </div>
</template>

<script>
import { getAllTrainingsAction, deleteTrainingAction, createTrainingAction,   getAllTrainingsOfPlanningAction } from '@/pages/Training/actions';
import Swal from 'sweetalert2';
import TrainingListClient from "@/pages/Training/TrainingListClient.vue";

export default {
  data() {
    return {  
    open:false,
      trainings: [],
      currentSort: 'name',
      currentSortOrder: 'asc',
      selectedTraining: null // Entrenamiento seleccionado para editar
    };
  },
  props: {
    planningId: String
  },
  components: {
    TrainingListClient
  },
  async created() {
    this.loadTrainings();
  },
  methods: {
    async add(item){
      let formData = {};
      formData.name = item.name
      formData.myexercises = item.myexercises
      
      try {
          let response;
          
           if(this.planningId){
            formData.planning = this.planningId
           }

          // Si no hay preloadedData, estamos creando un nuevo entrenamiento
          response = await createTrainingAction(formData);
          

          if (response.ok) {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: this.preloadedData ? 'Training updated successfully' : 'Training created successfully',
              timer: 2000,
              showConfirmButton: false
            });
            this.$emit('formSubmitted',response);
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Failed to save training: ' + response.message,
            });
          }
          this.$emit('formSubmitted',response)
        } catch (error) {
          console.error('Error saving training:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'An unexpected error occurred',
          });
        }
      
    },
    cancel(){
      this.open=false;
    },
    async loadTrainings() {
      try {
       let response;
           response = await getAllTrainingsAction();
        
        if (response.ok) {
          this.trainings = response.trainings;
        } else {
          console.error('Failed to fetch trainings: ', response.message);
        }
      } catch (error) {
        console.error('Error fetching trainings: ', error);
      }
    },
    onTrainingUpdated() {
      // Actualiza la lista de entrenamientos después de la edición
      this.selectedTraining = null;
      this.loadTrainings(); // Recargar la lista
      this.open=false
    }
  }
};

</script>
