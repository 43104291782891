import { wencesApi } from "@/api/wencesApi";
import { isAxiosError } from "axios";

// Acción para iniciar la conexión o creación de cuenta de Stripe
export const connectStripeAccount = async () => {
  try {
    const { data } = await wencesApi.get(`/api/stripe/connect`);
    return {
      ok: true,
      url: data.link, // La URL a la que redirigirás al usuario
    };
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "No autorizado",
      };
    }
    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

// Acción para iniciar la conexión o creación de cuenta de Stripe
export const removeSync = async () => {
  try {
    const { data } = await wencesApi.get(`/api/stripe/remove`);
 
    localStorage.removeItem('stripeAccountId');
    return {
      ok: true
    };

  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "No autorizado",
      };
    }
    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};



export const syncStripeAccount = async (code) => {
  try {
    const { data } = await wencesApi.post(`/api/stripe/sync`, 
     {code: code}
    );

    return {
      ok: true,
      stripeAccountId: data.stripeAccountId
    };

  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "Stalishment no existe",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};



// Acción para crear una suscripción
export const createSubscription = async (plan) => {
  try {
    const { data } = await wencesApi.post(`/api/stripe/create-subscription`, {plan: plan});

    return {
      ok: true,
      data: data
    };

  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "No autorizado",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

// Acción para confirmar el pago de la suscripción
export const confirmSubscriptionPayment = async (sessionId) => {
  try {
    const { data } = await wencesApi.post(`/api/stripe/confirm-subscription-payment`, {sessionId});

    return {
      ok: true,
      message: "Pago confirmado"
    };

  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "No autorizado",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

// Acción para confirmar el pago de la suscripción
export const confirmPaymentIntent = async (paymentIntent) => {
  try {
    const { data } = await wencesApi.post(`/api/stripe/confirm-payment-intent-front`, paymentIntent);

    return {
      ok: true,
      message: "Pago confirmado"
    };

  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "No autorizado",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};

// Acción para confirmar el pago de la suscripción
export const cancelSubscription = async () => {
  try {
    const { data } = await wencesApi.post(`/api/stripe/cancel-subscription`);

    return {
      ok: true,
      message: "Pago cancelado"
    };

  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 401) {
      return {
        ok: false,
        message: "No autorizado",
      };
    }

    console.log(error);
    throw new Error("No se pudo realizar la petición");
  }
};


