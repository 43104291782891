<template>
  <div>
    <div class="card w-100 p-4 mb-2">
      <h6>Buscar</h6>
      <md-field>
        <label>{{ $t('bodyZone') }}</label>
        <md-select v-model="selectedBodyZone" @change="filterExercises">
          <md-option v-for="zone in bodyZones" :key="zone" :value="zone">{{  zone }}</md-option>
        </md-select>
      </md-field>
      <md-field>
        <label>{{ $t('elements') }}</label>
        <md-select v-model="selectedElements" @change="filterExercises">
          <md-option :value="''">Todas</md-option>
          <md-option v-for="element in elements" :key="element" :value="element">{{  $t(element) }}</md-option>
        </md-select>
      </md-field>
      <md-field>
        <label>{{ $t('name') }}</label>
        <md-input type="text" v-model="searchQuery" @change="filterExercises"></md-input>
      </md-field>
    </div>

    <md-button class="w-100 md-button md-primary" @click="open=true" v-if="!open">
      + Agregar Ejercicio
    </md-button>
    <h5>{{filteredExercises.length}} items</h5>
    <md-table 
      v-if="!open"
      v-model="filteredExercises"
      :md-sort.sync="currentSort"
      :md-sort-order.sync="currentSortOrder"
      class="paginated-table scrollcustom exerciselistclient mt-4"
    >
      <md-table-row 
        slot="md-table-row"  
        slot-scope="{ item }"
        :class="item.source == 'custom' ? '':'bg-light'"
      >
        <md-table-cell :md-label="$t('name')" md-sort-by="name">
          <img :src="item.image" loading="lazy"  width="50px"/> <strong>{{ item.name }}</strong>
        </md-table-cell>
        <md-table-cell class="text-left" :md-label="$t('bodyZone')">
          <div><span v-for="body in item.bodyZone" class="p-2">{{  $t(body) }}</span></div>
        </md-table-cell>
        <md-table-cell class="text-left" :md-label="$t('elements')">
          <div>
            <span v-for="element in item.elements" class="p-2">{{  $t(element) }}</span>
          </div>
        </md-table-cell>
        <md-table-cell>
          <md-button class="md-primary md-icon" @click="editExercise(item); open=true">
            <md-icon>edit</md-icon>
          </md-button>
          <md-button class="md-danger md-icon" @click="confirmDelete(item)" v-if="item.source == 'custom'">
            <md-icon>delete</md-icon>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
   <div class="loading-container mr-2 w-100 text-center p-4" v-if="loading">
            <md-progress-spinner 
              :md-diameter="80" 
              md-mode="indeterminate" 
              class="text-red bg-red md-red">
            </md-progress-spinner>
          </div>
    <CreateExercise
      v-if="selectedExercise && open"
      :key="selectedExercise._id" 
      :preloadedData="selectedExercise"
      @formSubmitted="onExerciseUpdated"
      @cancel="cancel()"
    />
    <CreateExercise
      v-if="!selectedExercise && open"
      @formSubmitted="onExerciseUpdated"
      @cancel="cancel()"/>
  </div> 
</template>

<script>
import { getAllExercisesAction, deleteExerciseAction, getAllExercisesOfTrainingAction,getExerciseElementsAction,getBodyZonesElementsAction } from '@/pages/Training/actions';
import CreateExercise from './CreateExercise.vue'; // El formulario de edición
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      searchQuery: '',
      exercises: [],
      currentSort: 'name',
      loading:false,
      currentSortOrder: 'asc',
      bodyZones: [],
      elements: [],
      mobile: false, // Se puede configurar según sea necesario
      open: false,
      selectedBodyZone: 'Cardio', // Valor por defecto del primer bodyZone
      selectedElements: '', // Valor por defecto del primer element
      selectedExercise: null // Ejercicio seleccionado para editar
    };
  },
  props: {
    trainingId: String
  },
  components: {
    CreateExercise
  },
  async created() {

    const optionElements =  await getExerciseElementsAction();
    this.elements = optionElements.elements;

    const bodyzones = await getBodyZonesElementsAction();
    this.bodyZones = bodyzones.result;

    this.start();
  },  
  computed: {
    filteredExercises() {
      return this.exercises.filter(exercise => {
        const matchesZone = !this.selectedBodyZone || exercise.bodyZone.includes(this.selectedBodyZone);
        const matchesElements = !this.selectedElements || exercise.elements.includes(this.selectedElements);
        const matchesQuery = !this.searchQuery || exercise.name.toLowerCase().includes(this.searchQuery.toLowerCase());

              if(this.selectedElements){
          this.selectedBodyZone = '';
        }

        if(this.searchQuery.length > 1){
          return matchesQuery;
        }else{
          return matchesZone && matchesQuery && matchesElements
        }

      });
    }
  },
  methods: {
    filterExercises() {
      // Filtrado se realiza automáticamente en computed property
    },
    cancel() {
      this.open = false;
      this.selectedExercise = null;
    },
    async start() {
      try {
        let response;
        this.loading = true
        if (this.trainingId) {
          response = await getAllExercisesOfTrainingAction(this.trainingId);

        this.loading = false
          if (response.ok) {
            this.exercises = response.exercises.exercises;
          } else {
            console.error('Failed to fetch exercises: ', response.message);
          }
        } else {
          response = await getAllExercisesAction();
        this.loading = false
          if (response.ok) {
            this.exercises = response.exercises;
          } else {
            console.error('Failed to fetch exercises: ', response.message);
          }
        }
      } catch (error) {
        console.error('Error fetching exercises: ', error);
      }
    },
    editExercise(exercise) {
      this.selectedExercise = exercise;
    },
    async confirmDelete(exercise) {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: `You are about to delete the exercise "${exercise.name}". This action cannot be undone.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'Cancel'
      });

      if (result.isConfirmed) {
        this.deleteExercise(exercise._id);
      }
    },
    async deleteExercise(id) {
      try {
        const response = await deleteExerciseAction(id);
        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Deleted',
            text: 'Exercise deleted successfully',
            timer: 2000,
            showConfirmButton: false
          });
          // Recargar la lista de ejercicios
          this.start();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to delete exercise: ' + response.message,
          });
        }
      } catch (error) {
        console.error('Error deleting exercise:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An unexpected error occurred',
        });
      }
    },
    onExerciseUpdated() {
      // Actualiza la lista de ejercicios después de la edición
      this.selectedExercise = null;
      this.open = false;
      this.start(); // Recargar la lista
    }
  }
};
</script>

<style scoped>
.scrollcustom {
  overflow-y: scroll;
  max-height: 750px;
}
/* Estilos para la tabla */
</style>
