<template>
 <div  class="w-100">
  <div v-if="token && order && !loading" class="w-100">
      <AnonymousResult  :isWebsocket="false" :defaultOrderId="order.access._id" />
  </div>
    <div class="w-100 text-center mt-4"  v-if="loading">
          <div class="w-100">
              <md-progress-spinner 
              :md-diameter="60" 
              md-mode="indeterminate" 
              class="text-red bg-red md-red">
            </md-progress-spinner>
              </div>
                <div class="w-100">
           <strong> Cargando...</strong>
               </div>
                <div class="w-100">
                <small>puede tardar unos segundos dependiendo la señal de internet en el lugar.</small>
               </div>
                <div class="w-100" v-if="obteniendoperfil">
                  <small>Obteniendo información del perfil...<md-icon v-if="obteniendoperfilOK" class="text-success">task_alt</md-icon></small>
               </div>
                <div class="w-100" v-if="obteniendoQR">
                  <small>Leyendo QR...<md-icon v-if="obteniendoQROK" class="text-success">task_alt</md-icon></small>
               </div>
                <div class="w-100" v-if="obteniendoestablecimiento">
                 <small>Obteniendo establecimiento...<md-icon v-if="obteniendoestablecimientoOK" class="text-success">task_alt</md-icon></small>
               </div>
                <div class="w-100" v-if="creandoacceso">
                 <small>Registrando acceso...<md-icon v-if="creandoaccesoOK" class="text-success">task_alt</md-icon></small>
               </div>

        </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { createAccessAction } from "@/pages/Orders/actions";
import { getUserAction,getMyProfileAction } from "@/pages/Users/actions";
import { getQrAction } from "@/pages/Qrs/actions";
import { i18n } from '@/i18n';
import { format } from 'date-fns';
import NewUserForm from "@/pages/Dashboard/Pages/UserProfile/NewUserForm.vue";
import permissionsMixin from '@/mixins/permissionsMixin';
import { logoutAction } from "@/pages/Auth/actions";
import AnonymousResult from "@/pages/Orders/AnonymousResult.vue";


import {
  Avatar,
  MyClasses,
  ScheduleWeekGym,
} from "@/components";


export default {
  mixins: [permissionsMixin],
  props: {
    argqrId: {
      type: String
    }
  },
  data() {
    return {
      subdomain:'',
      image: "/img/placeholder.jpg",
      qrName:false,
      profile:false,
      identifier:'',
      token: false,
      role: false,
      order:false,
      totalPriceOrders:0,
      pendingOrders:false,
      clientSecret:false,
      activeCategory: 0,
      business: false,
      opencollapse: false,
      opencollapsefirst: true,
      stablishment: false,
      reloadActivities:122323,
      reloadclasses: 3232,
      menu: false,
      selected: [],
      cart: [],
      categorieslabels: [],
      responsive: false,
      categories: [],
      customerName: '',
      customerEmail: '',
      customerPhone: '',
      customerAvatar: '',
      customerComments: '',
      selectedCountryCode: '+34',
      terms: [],
      loading: false,
      obteniendoperfil: false,
      obteniendoperfilOK: false,
      obteniendoQR: false,
      obteniendoQROK: false,
      creandoacceso: false,
      creandoaccesoOK: false,
      obteniendoestablecimiento: false,
      obteniendoestablecimientoOK: false,
    };
  },
  watch: {
    isCollapseOpen(newVal) {
      this.opencollapse = newVal;
    }
  },
  components: {
    ScheduleWeekGym,
    NewUserForm,
    Avatar,
    MyClasses,
    AnonymousResult
  },
  computed: {
    isFormValid() {
      if(this.menu.requestAdditionalInfo){
            return this.customerName && this.customerEmail && this.customerPhone && this.isValidEmail(this.customerEmail);
      }else{
            return this.customerName;
      }
    }
  },
  mounted(){
  },
  created() {
    this.loading = true;

    this.subdomain = window.location.hostname.split('.')[0];

    this.token = localStorage.getItem('token');
    this.role = localStorage.getItem('role');

    if(!this.token){

          if(this.argqrId){
            this.$route.params.id = this.argqrId
          }

          this.loading = false;
  
          if(this.$route.params.id){
            this.$router.push('/loginclient?qr='+this.$route.params.id);
            return;
          }else{
              this.$router.push('/loginclient');
             return;
          }


    }else{
           if(this.argqrId){
            this.$route.params.id = this.argqrId
          }



          if(this.token && this.role !== 'client' && !this.argqrId){
            this.logout();
            this.loading = false;
            return;
          }else{

          
            if(localStorage.getItem('customerName')){
             this.customerName = localStorage.getItem('customerName')
            }
            if(localStorage.getItem('identifier')){
             this.identifier = localStorage.getItem('identifier')
            }
            if(localStorage.getItem('customerAvatar')){
             this.customerAvatar = localStorage.getItem('customerAvatar')
            }

            if(this.token){
             this.getMyProfile();
            }
            
            this.getQr();
           
            this.submitForm()
            

          }

    }

   


    
  },
  methods: {
    async getMyProfile(){
      this.obteniendoperfil = true;
      const response = await getMyProfileAction();
      this.obteniendoperfilOK = true;

      this.profile = response;
    },
    deletedClass(){
      this.reloadActivities++;
      this.reloadclasses++;
      this.getMyProfile();
    },
    labelDate(startDate){
      return format(new Date(startDate), 'dd')+ ' ' +this.$t(format(new Date(startDate), 'MMMM'))+ ' ' +format(new Date(startDate), 'yyyy');
    },
    validateForm() {
      return this.identifier && this.identifier.length > 0
    },
    async logout() {

      let urllogin = '/loginclient';
     
      await logoutAction();
      if(this.$route.path !== urllogin){
        this.$router.push(urllogin);
      }
    },
    submitForm() {
       
          this.createOrder()

    },
    stablishmentReady(){
        return (
          this.stablishment.name !== 'My Fitness' &&
          this.stablishment.name !== 'Costa Azul' && 
          this.stablishment !== undefined )
    },
    async getQr() {
      try {

        if(!this.$route.params.id){
          this.obteniendoQROK = true;
          return;
        }


        this.obteniendoQR = true;

        const response = await getQrAction(this.$route.params.id);


        this.obteniendoQROK = true;

        this.obteniendoestablecimiento = true;
        if(response.data.stablishment){
          this.stablishment = response.data.stablishment;
        }


        if(this.stablishment){
           if(this.stablishment 
          && this.stablishment.primaryColor == '#FFFFFF' ||
          this.stablishment.primaryColor == '#ffffff' || 
          !this.stablishment.primaryColor){
            this.stablishment.primaryColor = '#333';
          }
          if(this.stablishment && this.stablishment.secondaryColor == '#FFFFFF' ||
          this.stablishment.secondaryColor == '#ffffff' || 
          !this.stablishment.secondaryColor){
            this.stablishment.secondaryColor = '#333';
          }

          if(this.stablishmentReady()){
            // Cambiar el título de la página
            document.title = this.stablishment.name;
            // Cambiar el favicon de la página
            const link = document.createElement('link');
            link.rel = 'icon';
            link.href = this.stablishment.logo;
            document.head.appendChild(link);
          }
          this.obteniendoestablecimientoOK = true;
        }else{

          this.obteniendoestablecimientoOK = true;
        }
       

        this.qrName = response.data.name;

       
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
    localDate(utcDate) {
      const date = new Date(utcDate);
      return format(date, 'PPpp');  // Formato de fecha y hora según la hora local del navegador
    },
    setActiveCategory(key) {
      this.activeCategory = key;
    },
    buttonStyle(color) {
      return {
        backgroundColor: `${color} !important`,
      };
    },
    colorStyle(color) {
      return {
        color: `${color} !important`,
      };
    },
   
    async createOrder() {
      try {
    

        this.creandoacceso = true;

        const response = await createAccessAction(
          this.identifier,
          this.$route.params.id,
          this.stablishment
        );

        
          this.order = response.data;
        
        this.creandoaccesoOK = true;
        
            this.loading = false;
      } catch (error) {
          Swal.fire({
              icon: 'error',
              title: this.$t('identifierNotFound'),
            });
      }
    },
    preventZoom() {
      this.$el.addEventListener('dblclick', this.handleDblClick, { passive: false });
    },
    handleDblClick(event) {
      event.preventDefault();
    },
    onSelect(items) {
      this.selected = items;
    },
    onResponsiveInverted() {
      if (window.innerWidth < 768) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    },
  
  },
  mounted() {
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);

    this.preventZoom();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
    this.$el.removeEventListener('dblclick', this.handleDblClick);
  },
};
</script>

<style lang="css">
.identifieranonymous  .md-field,
.identifieranonymous  .md-input, 
.identifieranonymous  .md-field{
    font-size:20px !important;
}
.identifieranonymous  .md-field::placeholder,
.identifieranonymous  .md-input::placeholder, 
.identifieranonymous  .md-field::placeholder{
    font-size:20px !important;
}

</style>

