<template>
  <div>


    <md-button class="w-100 md-button md-primary" @click="open=true" v-if="!open">
    + Crear nuevo grupo de ejercicios
    </md-button>




      <!-- Cargar formulario de edición solo si hay un entrenamiento seleccionado -->
        <CreateTraining
          v-if="selectedTraining && open"
          :key="selectedTraining._id"
          :preloadedData="selectedTraining"
          @formSubmitted="onTrainingUpdated"
            @cancel="cancel()"
        />
          <template>
            <CreateTraining class="mt-4" 
             @formSubmitted="onTrainingUpdated" 
             @cancel="open=false" 
             v-if="open && !selectedTraining"/>
          </template>

   <div class="loading-container mr-2 w-100 text-center p-4" v-if="loading">
            <md-progress-spinner 
              :md-diameter="80" 
              md-mode="indeterminate" 
              class="text-red bg-red md-red">
            </md-progress-spinner>
          </div>
    <md-table 

      v-if="!open"
      v-model="trainings"
      :md-sort.sync="currentSort"
      :md-sort-order.sync="currentSortOrder"
      class="paginated-table table-striped table-hover"
    >
      <md-table-row 
        slot="md-table-row"  
        slot-scope="{ item }"
      >
        <!-- Columna Nombre del Entrenamiento -->
        <md-table-cell :md-label="$t('name')" md-sort-by="name">
          <span>{{ item.name }}</span>
        </md-table-cell>

        <!-- Columna Elemento Usado -->
        <md-table-cell :md-label="'Ejercicios'">
        <ul>
          <li v-for="element in item.myexercises" class="p-2">{{ element.name }}</li>
          </ul>
        </md-table-cell>


        <!-- Columna de Acciones -->
        <md-table-cell>
          <button class="btn btn-primary " @click="editTraining(item);open=true">
            {{$t('edit')}}
          </button>
          <button class="btn btn-danger" @click="confirmDelete(item)">
               {{$t('delete')}}
          </button>
        </md-table-cell>
      </md-table-row>
    </md-table>

  

  </div>
</template>

<script>
import { getAllTrainingsAction, deleteTrainingAction, getAllTrainingsOfPlanningAction } from '@/pages/Training/actions';
import {CreateTraining} from '@/pages/Training'; // El formulario de edición
import Swal from 'sweetalert2';
import TrainingListClient from "@/pages/Training/TrainingListClient.vue";

export default {
  data() {
    return {  
    open:false,
      trainings: [],
      loading:false,
      currentSort: 'name',
      currentSortOrder: 'asc',
      selectedTraining: null // Entrenamiento seleccionado para editar
    };
  },
  props: {
    planningId:String
  },
  components: {
    CreateTraining,
    TrainingListClient
  },
  async created() {
    this.loadTrainings();
  },
  methods: {
    cancel(){
      this.open=false;
    },
    async loadTrainings() {
      try {
       let response;
       this.loading = true
        if(!this.planningId){
           response = await getAllTrainingsAction();

       this.loading = false
        }else{
           response = await getAllTrainingsOfPlanningAction(this.planningId); 
       this.loading = false
        }
        if (response.ok) {
          this.trainings = response.trainings;
        } else {
          console.error('Failed to fetch trainings: ', response.message);
        }
      } catch (error) {
        console.error('Error fetching trainings: ', error);
      }
    },
    editTraining(training) {
      this.selectedTraining = training;
    },
    async confirmDelete(training) {
      const result = await Swal.fire({
        title: this.$t('areYouSure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: this.$t('delete'),
        cancelButtonText: this.$t('cancel')
      });

      if (result.isConfirmed) {
        this.deleteTraining(training._id);
      }
    },
    async deleteTraining(id) {
      try {
        const response = await deleteTrainingAction(id);
        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Deleted',
            text: 'Training deleted successfully',
            timer: 2000,
            showConfirmButton: false
          });
          // Recargar la lista de entrenamientos
          this.loadTrainings();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to delete training: ' + response.message,
          });
        }
      } catch (error) {
        console.error('Error deleting training:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An unexpected error occurred',
        });
      }
    },
    onTrainingUpdated() {
      // Actualiza la lista de entrenamientos después de la edición
      this.selectedTraining = null;
      this.loadTrainings(); // Recargar la lista
      this.open=false
    }
  }
};

</script>
