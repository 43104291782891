export default {
  addNewMenu: 'Додати нове меню',
menuName: 'Назва меню',
forgotPassword: 'Я забув свій пароль',
sendEmail: 'Надіслати електронний лист',
tokenExpired: 'Токен минув',
activateQR: 'Активувати QR',
restaurant_role: 'Адміністратор',
pay: 'Оплатити',
'height' : 'Висота',
'weight' : 'Вага',
"gender" : "Стать",
  "emailExistsMessage": "Ця електронна пошта вже існує.",
accesses: 'Доступи',
  "enrolledPeople": "Зареєстровані люди",
contactForHelp: 'Якщо у вас є запитання щодо роботи системи або як підписатися, напишіть нам у чат, і ми допоможемо вам. Не хвилюйтеся, ви будете спілкуватися з людиною.',
alreadySubscribedUnlimited: 'Ви вже підписані на необмежений план',
  "waitingForEntries": "Очікування входів",
rejected: 'Відхилено',
"thankyoushipping" : "Ми надішлемо вам електронний лист із деталями відправлення найближчим часом.",
  "notEnrolledInAnyClass": "Ви не записані на жоден урок",
  "myProfile": "Мій профіль",
  "pleaseinsertemail": "Введіть електронну пошту, з якою ви зареєстрували додаток, щоб бути перенаправленим до вашого додатку.",
  "noQuotaLeft": "У вас більше немає доступних місць",
  "quota": "Ліміти",
    "bodyZone": "Зона тіла",
      "inprogress": "В процесі",
        "clientAccess": "Доступ для клієнтів",
  'shoulders' : 'Плечі',
    "pending": "В очікуванні",
      "done": "Завершено",
'biceps' : 'Біцепси',
'perderpeso' : 'Схуднення',
'ganarmusculo' : 'Нарощування м’язів',
'tonificacion' : 'Тонізація',
'movilidad' : 'Мобільність',
'triceps' : 'Трицепси',
'chest' : 'Грудна клітка',
'back' : 'Спина',
'abdomen' : 'Живіт',
'glutes' : 'Сідниці',
'quadriceps' : 'Квадрицепс',
'hamstrings' : 'Задні м’язи стегна',
'calves' : 'Ікри',
    "download": "Завантажити",
    "select": "Вибрати",
    "stickQRMessage": "Приклейте QR-код на двері вашого закладу, щоб ваші клієнти могли його сканувати при вході для контролю доступу",
    "scanQrFirst": "Спочатку ви повинні відсканувати QR-код, щоб мати змогу натиснути на вхід.",
    "classLimitPerMonth": "Кількість занять, на які цей клієнт може записатися",
  "perMonth": "на місяць",
    "noSlotsAvailable": "Немає місць на це заняття",
  "mustLoginToSignUp": "Ви повинні увійти в систему, щоб зареєструватися",
    "defineSpecificDate": "Визначити конкретну дату",
    "remainingQuota": "Залишкові ліміти",
      "myCalendar": "Мій календар",
        "paymentPerAccessOrQuota": "Оплата за кількість доступів або квот",
        "myClasses": "Мої заняття",
          "noQuotaLeftThisMonth": "У вас немає доступних місць на цей місяць",
          "allowedAccessCount": "Кількість дозволених доступів (Сканування QR при вході до закладу)",
    "allready": "Ви вже записані на це заняття",
      "signUpForClass": "Записатися на заняття",
        "signUpForThisClass": "Записатися на це заняття",
  "seeDemo": "Переглянути демо",
    "availableSlots": "Доступні місця",
      "recurringActivitiesMessage": "Рекурентні заняття поки не дозволяють реєстрацію, але ми покращимо це найближчим часом. Виберіть конкретну дату, щоб увімкнути реєстрацію.",
  "allowOverQuotaMessage": "Вибираючи цю опцію, будь-який клієнт, у якого немає доступних місць, але який сплатив свій щомісячний внесок, також зможе зареєструватися.",
  "anyClientCanSignUp": "Будь-який клієнт може зареєструватися",
    "temporaryIssue": "У нас тимчасова проблема, спробуйте ще раз пізніше.",
  "seeDemoBeforeSignUp": "Я хочу побачити демонстрацію перед реєстрацією.",
  "uploadFile": "Завантажити файл",
    "availableEntries": "Доступні входи",
  "enableRegistrations": "Увімкнути реєстрації на цю діяльність",
  "imagemenu": "Показувати лише фото меню, без можливості замовлення для споживача.",
differentWaysToUse: 'Різні способи використання Go Take It',
subscribe: 'Підписатися',
    "Monday": "Понеділок",
    "Tuesday": "Вівторок",
    "Wednesday": "Середа",
    "Thursday": "Четвер",
    "Friday": "П'ятниця",
    "Saturday": "Субота",
    "Sunday": "Неділя",
    "January": "Січень",
    "February": "Лютий",
    "March": "Березень",
    "April": "Квітень",
    "May": "Травень",
    "June": "Червень",
    "July": "Липень",
    "August": "Серпень",
    "September": "Вересень",
    "October": "Жовтень",
    "November": "Листопад",
    "December": "Грудень",
  "unlimitedQuota": "Необмежені ліміти",
  "lastEntry": "Останній вхід",
    "quotaMessage": "Встановивши квоти, система не дозволить людям продовжувати реєструватися, коли досягнуто ліміту. Щоб зареєструватися, вони повинні відсканувати QR-код, який ви розмістите у своєму спортзалі, або скористатися посиланням:",
    "demoMessage": "Це демо, вам потрібно створити власний обліковий запис, щоб змінювати параметри. Ви можете зробити це на:",
    "uploadMenuMessage": "Ви можете завантажити зображення для кожної сторінки меню або PDF із повним меню.",
  "shareScheduleInfo": "Ви зможете поділитися розкладом зі своїми клієнтами, розмістивши посилання у своїх соціальних мережах. Вони також зможуть побачити його на своєму телефоні при вході в спортзал, скануючи QR-код.",
  myClients: "Мої клієнти",
    "leaveEmptyForEmail": "Залиште це поле порожнім, якщо хочете використовувати електронну пошту як ідентифікатор.",
kitchenStatusOrders: 'Замовлення в статусі "кухня"',
completePayment: 'Завершіть оплату',
  "leaveEmptyIfNoQuota": "Залиште порожнім, якщо для ваших занять не встановлено лімітів.",
  "gotakeitUsageMessage": "Це дозволяє почати використовувати Gotakeit без необхідності завантажувати клієнтів вручну. Вони створять свої профілі, а ви повинні будете ввести дату оплати пізніше.",
  "selfRegistrationMessage": "Дозволити людям самостійно зареєструватися при скануванні QR-коду, якщо у них ще немає створеного профілю.",
  "activityLabel": "Діяльність",
    "identifierExistsMessage": "Цей ідентифікатор вже використаний іншим користувачем.",
  "activityPlaceholder": "Назва діяльності",
  "selectDateLabel": "Вибрати дату",
  "startTimeLabel": "Час початку",
    "uploadImageMessage": "Завантажте зображення, на якому видно ваше обличчя, щоб ідентифікувати вас при вході в спортзал.",
    "identifierMessage": "Введіть ідентифікатор, який ви легко запам’ятаєте. Ви будете використовувати його тут для доступу до спортзалу щоразу, коли скануєте QR-код. Приклад: ваш номер телефону, ідентифікаційний номер або електронна пошта.",
  "endTimeLabel": "Час закінчення",
  "timePlaceholder": "ГГ:ХХ",
  "removeButton": "Видалити",
    "leaveScreenOpenMessage": "Залиште цей екран відкритим у браузері вашого закладу, і ви зможете перевіряти щоразу, коли хтось входить, скануючи QR-код.",
  "addButton": "Додати діяльність",
  "saveGridButton": "Зберегти сітку",
    "addDailyActivityTitle": "Додати Щоденну Діяльність",
  "activityLabel": "Діяльність",
  "activityPlaceholder": "Наприклад: Йога",
  "dayOfWeekLabel": "День тижня",
  "startTimeLabel": "Час початку",
  "endTimeLabel": "Час закінчення",
  "addButton": "Додати Діяльність",
  "Lunes": "Понеділок",
  "Martes": "Вівторок",
  "Miércoles": "Середа",
  "Jueves": "Четвер",
  "Viernes": "П'ятниця",
  "Sábado": "Субота",
  "Domingo": "Неділя",
remainingAccesses: 'Залишилося доступів',
imageSet1_step1_title: '1- Скануйте QR-код',
imageSet1_step1_description: 'Вони сканують QR-код у басейні, на пляжі або на ринку.',
imageSet1_step2_title: '2- Отримати замовлення',
imageSet1_step2_description: 'Замовлення отримується на планшет або смартфон.',
imageSet1_step3_title: '3- Сповістити клієнта',
imageSet1_step3_description: 'Клієнт отримує сповіщення, коли страва готова, і йде її забрати.',
generateReceipt: 'Створити квитанцію',
paymentsAndReceipts: 'Платежі та квитанції',
  waitingPeriod: "Період очікування", // Ucraniano
  waitingPeriodExplanation: "Виберіть кількість днів, які ви хочете почекати, перш ніж показати червоний банер клієнту, який не сплатив.",
imageSet2_step1_title: '1- Скануйте QR-код',
imageSet2_step1_description: 'Скануйте QR-код і замовляйте бажані продукти.',
imageSet2_step2_title: '2- Отримати замовлення',
imageSet2_step2_description: 'Ресторан отримує замовлення на кухні.',
imageSet2_step3_title: '3- Офіціант приносить страву',
imageSet2_step3_description: 'Офіціант приносить замовлення до столу.',
    "importUsers": "Імпортувати користувачів з файлу Excel (.xls або .xlsx)",
    "matchColumns": "Зіставити колонки",
    "selectDateFormat": "Виберіть формат дати",
    "previewImport": "Попередній перегляд імпорту",
    "previewUsers": "Попередній перегляд користувачів для імпорту",
    "noDataAvailable": "Немає доступних даних для попереднього перегляду.",
    "confirmAndImport": "Підтвердити та імпортувати",
    "resume": "Продовжити",
    "pause": "Пауза",
    "error": "Помилка",
    "emptyFile": "Файл порожній або не містить даних.",
    "missingHeaders": "Файл має містити заголовки в першому рядку.",
    "limitExceeded": "Перевищено ліміт",
    "importLimit": "Не можна імпортувати більше 3000 контактів за одну операцію.",
    "missingHeadersCSV": "Файл CSV має містити заголовки в першому рядку.",
    "importCancelled": "Імпорт скасовано",
    "errorCreatingUser": "Помилка створення користувача {name}:",
    "importedUsers": "{createdUsers} користувачів було успішно імпортовано або відредаговано.",
imageSet3_step1_title: '1- Клієнти переглядають меню',
imageSet3_step1_description: 'Вони переглядають меню, але не можуть створювати замовлення.',
imageSet3_step2_title: '2- Офіціант приймає замовлення',
imageSet3_step2_description: 'Офіціант отримує доступ до QR-коду столу та генерує замовлення.',
imageSet3_step3_title: '3- Отримати замовлення',
imageSet3_step3_description: 'Ресторан отримує замовлення на кухні.',
imageSet3_step4_title: '4- Офіціант приносить страву',
imageSet3_step4_description: 'Офіціант приносить замовлення до столу.',
imageSet3_step5_title: '5- Клієнти оплачують замовлення',
  "paymentMethod": "Спосіб оплати",
   "unlimitedMonthlyPayment": "Щомісячний платіж з необмеженим доступом до спортзалу та необмеженою кількістю занять",
  "unlimitedMonthlyPaymentWithQuota": "Щомісячний платіж з необмеженим доступом до спортзалу та {cupos} місцями для занять на місяць",
  "limitedMonthlyPayment": "Щомісячний платіж з {access} входами до спортзалу на місяць та {cupos} місцями для занять на місяць",
  "limitedAccessUnlimitedQuota": "Щомісячний платіж з {access} входами до спортзалу на місяць та необмеженою кількістю місць для занять",
  "oneTimePayment": "Одноразовий платіж за {access} входів до спортзалу та {cupos} місць для занять",
    "monthlyPayment": "Щомісячний платіж",
imageSet3_step5_description: 'Клієнти оплачують замовлення класичним способом або сканують QR-код для оплати.',
  "unlimitedAccess": "Необмежений доступ",
sendMessageChat: 'Надішліть нам повідомлення в чаті',
maxOrders: 'Максимальна кількість замовлень',
maxOrdersLimit: 'Максимальна кількість замовлень, яку цей заклад може прийняти одночасно. Якщо кількість перевищено, QR-код покаже меню, але не дозволить створювати нові замовлення.',
importantNotifications: 'Тут ви отримуватимете важливі сповіщення про діяльність людей у цьому закладі.',
unsubscribe: 'Відписатися',
selectAll: 'Вибрати все',
filter: 'Фільтрувати',
perMonth: 'на місяць',
remainingOrders: 'Залишилося замовлень',
noCreditsQRInactive: 'Без кредитів ваші QR-коди перестануть працювати',
unlimitedPlan: 'Необмежений план',
cancelableSubscription: 'Підписку можна скасувати в будь-який момент, без зобов’язань.',
subscriptionActivated: 'Вашу підписку активовано, насолоджуйтеся GoTakeIt без обмежень!',
monthlySubscription: 'Місячна підписка на 10 000 аргентинських песо на місяць',
monthlySubscriptionEur: 'Місячна підписка на 12,00 € на місяць',
cancelSubscription: 'Ви можете скасувати підписку в будь-який момент через MercadoPago',
unlimitedCredits: 'Необмежені кредити, необмежений доступ до всіх функцій',
business: 'Бізнес',
delete: 'Видалити',
inviteToSubscribe: 'Ми запрошуємо вас підписатися на необмежений план',
paymentDate: 'Дата оплати',
accessBlocked: 'Доступ заблоковано',
serviceStart: 'Початок обслуговування',
  "access_reminder": "У вас залишилося {credits} доступів",
  "subscription_invite": "Запрошуємо вас підписатися, вибравши опцію 'підписка' у меню",
accessGranted: 'Доступ дозволено',
logout: 'Вихід',
markAsPaid: 'Позначити як оплачено',
accepted: 'Прийнято',
paymentDateInfo: 'Введіть своїх клієнтів із датою оплати, щоб система знала, коли відхилити вхід. За замовчуванням система дозволяє доступ, якщо дату не було введено.',
gym_role: 'Адміністратор',
client_role: 'Клієнт',
deactivateQR: 'Деактивувати QR',
loadClients: 'Завантажити клієнтів',
  "payment_due": "Залишилося {days} днів до терміну вашої щомісячної оплати",
clientsStartUsing: 'Ваші клієнти починають ним користуватися',
 "access_denied": "Доступ заборонено",
  "payment_overdue": "Ваш платіж прострочений на {days} днів.",
  identifierNotFound: 'Ідентифікатор не існує',
qrScanNotification: 'Ваші клієнти сканують QR-код кожного разу, коли заходять у заклад, і ви отримуєте сповіщення про те, чи дозволено їм вхід чи ні',
accessControl: 'Контроль доступу для тренажерних залів, клубів та приватних закладів',
digitalQrMenu: 'Цифрове QR-меню для ресторанів, пляжних парасольок, басейнів або готелів',
nameAlreadyTaken: 'Це ім\'я вже вибрано іншим користувачем',
employee_role: 'Офіціант',
qrSettings: 'Налаштування для QR',
qrConfigurationInfo: 'Визначте налаштування для ваших різних QR-кодів і зв’яжіть це налаштування з QR-кодом, який ви хочете. Ви можете встановлювати кольори, мови та фрази для використання.',
toPay: 'До оплати',
insertClients: 'Введіть своїх клієнтів із іменем та ідентифікатором',
businessName: 'Назва вашого бізнесу',
users: 'Користувачі',
consentNotice: 'Використовуючи додаток Gotakeit, ви погоджуєтесь з нашими умовами використання та політикою конфіденційності. Ви розумієте, що ваша інформація обробляється від імені Заклади і буде передана їм.',
goToMyApp: 'Перейти до мого додатку',
menuTypesDescription: 'Існує 4 типи меню, які ви можете вибрати залежно від потреб вашого бізнесу',
addUser: 'Додати користувача',
userRolesDescription: 'Ви можете створити іншого адміністратора користувача або користувача з роллю співробітника. Роль співробітника дозволяє користувачу використовувати GO Take It на своєму телефоні для прийому замовлень і самостійного формування замовлень у випадках, коли споживач може лише переглянути меню, але не може самостійно формувати замовлення.',
popupNotification: 'Ми повідомимо вас спливаючим вікном на цьому екрані, коли ваше замовлення буде готове',
tryAgain: 'Спробуйте ще раз з новою електронною адресою',
orderSummaryEmail: 'Ми надіслали вам електронний лист із підсумком вашого замовлення, не забудьте перевірити СПАМ і видалити електронний лист із цієї папки, щоб правильно отримати сповіщення, коли ваше замовлення буде готове.',
   "new_password": "Новий пароль",
    "repeat_password": "Повторити пароль",
    "change_password": "Змінити пароль",
    paymentPending: 'Очікується оплата',
    "password_too_short": "Пароль має бути не менше 6 символів.",
    "password_needs_symbol": "Пароль повинен містити принаймні один символ.",
    "passwords_do_not_match": "Паролі не співпадають.",
    "password_changed": "Пароль успішно змінено.",
    "error_changing_password": "Помилка зміни пароля.",
emailSent: 'Електронний лист надіслано',
correctEmail: 'Якщо електронна адреса правильна, ви отримаєте посилання для скидання пароля',
deliveryConfirmation: 'Ваше замовлення вже доставлено',
passwordResetEmail: 'Ми надішлемо вам електронний лист із посиланням для скидання пароля',
orderDelivered: 'Ваше замовлення вже доставлено, ви можете зробити інше замовлення, повернувшись до меню',
onlymenu: 'Показати лише меню, без можливості робити замовлення',
sendNotifications: 'Ми надсилатимемо сповіщення на цю електронну пошту, коли ви отримаєте замовлення',
activatePaymentSystem: 'Активувати платіжну систему у замовленнях',
customersMustPay: 'Клієнти повинні платити під час оформлення замовлення.',
syncRequired: 'Вам потрібно синхронізуватися з нашою платіжною системою, щоб активувати цю опцію',
  syncpayments:'Синхронізувати з платіжною платформою',
  ifyouunsync:'Якщо ви десинхронізуєте, ви більше не зможете отримувати платежі.',
  stripeAccountSync:'Ваш обліковий запис було успішно синхронізовано.',
  nowYouCanAccess:'Тепер ви будете отримувати платежі безпосередньо на свій рахунок, ми не є посередниками. Ви вже можете активувати опцію в своєму меню, щоб клієнти могли оплачувати свої замовлення.',
   connectStripeAccount: 'Підключити обліковий запис Stripe',
  syncStripeInfo: 'Синхронізація Stripe дозволить оплату, щоб клієнти повинні були оплатити свої замовлення перед їх розміщенням.',
  noStripeAccount: 'Якщо у вас немає облікового запису Stripe, ви можете створити його та повернутися на цей екран, щоб синхронізувати.',
  alreadyHaveStripeAccount: 'Якщо у вас вже є підключений обліковий запис Stripe, ви можете синхронізувати його, натиснувши тут.',
  syncMyStripeAccount: 'Синхронізувати мій обліковий запис Stripe',
  createOrSyncStripeAccount: 'Створити або Синхронізувати обліковий запис Stripe',
  error: 'Помилка',
  stripeConnectError: 'Не вдалося підключитися до Stripe. Спробуйте ще раз.',
  haveStripeAccount: 'У вас є обліковий запис Stripe?',
  redirectSync: 'Якщо у вас вже є обліковий запис, ми перенаправимо вас для синхронізації.',
  yesSyncAccount: 'Так, синхронізувати обліковий запис',
  noCreateAccount: 'Ні, створити новий обліковий запис',
  stripeAccountCreated: 'Обліковий запис Stripe створено',
  syncStripeInfoAfterCreation: 'Тепер вам потрібно синхронізувати його з нами, щоб почати отримувати платежі.',
  stripeCreateError: 'Не вдалося створити обліковий запис Stripe. Спробуйте ще раз.',
  genericError: 'Виникла помилка. Спробуйте ще раз.',
  qrhelp: 'Ви можете створити QR-коди для їх зв\'язку з меню. Потім надрукуйте цей QR і розмістіть його на столах у ресторані, на пляжних парасольках або на ліжках біля басейну в готелі.',
    dashboard: "панель приладів",
    total: "Загальна сума", 
    myOrder: "Моє замовлення",
    orderNotification: "Ми надішлемо вам електронний лист або SMS, коли ваше замовлення буде готове.",
    name: "Ім'я",
    enterName: "Введіть ваше ім'я",
    email: "Електронна пошта",
    enterEmail: "Введіть вашу електронну пошту",
    comments: "Коментарі",
    enter: 'Ввести',
    enterComments: "Введіть коментарі",
    code: "Код",
    phone: "Телефон",
    yespaid: "Це вже було оплачено",
    accessIdentifier: 'Ідентифікатор доступу',
    nopaid: "Ще не оплачено",
    paid: "Оплачено",
    accessIdentifierInfo: 'Цей ідентифікатор буде тим, який клієнт повинен ввести при скануванні QR-коду. Це може бути його національне посвідчення особи, паспорт або номер клієнта. Щось, що він легко запам’ятає.',
    enterPhone: "Введіть номер телефону",
    confirmOrder: "Підтвердити замовлення",
    yourOrder: "Ваше замовлення",
    orderDetails: "Ваше замовлення буде відправлено на кухню, коли ви натиснете надіслати. Ми повідомимо вас, коли воно буде готове до отримання.",
    sendOrder: "Надіслати замовлення",
    cancel: "Скасувати",
  producthelp:'Пам\'ятайте, що якщо ви створите новий продукт, вам потім потрібно буде прив\'язати його до меню, де ви хочете, щоб його було видно',
  scanphone: "Скануйте QR за допомогою телефону або натисніть",
    orderNumber: "Номер замовлення",
    preparingOrder: "Ми готуємо ваше замовлення",
dontClose: 'Ми повідомимо вас спливаючим вікном на цьому екрані, коли ваше замовлення буде готове, і також надішлемо вам електронний лист на адресу, яку ви ввели раніше',
    orderReadyTitle: "Ваше замовлення готове!",
    orderReadyText: "Ви можете забрати своє замовлення зараз.",
    welcome: "Ласкаво просимо",
    instructions: "Будь ласка, дотримуйтесь інструкцій.",
    settings: "Налаштування",
    currency: "Валюта",
    position_currency: "Позиція валюти",
    primary_color: "Основний колір",
    secondary_color: "Другорядний колір",
    menu_languages: "Мови меню",
    request_location: "Запитувати місцезнаходження",
    request_additional_information: "Запитувати додаткову інформацію",
    menu_terms: "Умови меню",
    save: "Зберегти",
    success: "Успіх!",
    business_info_updated: "Інформація про бізнес успішно оновлена",
    error: "Помилка!",
    error_updating_business_info: "Сталася помилка при оновленні інформації про бізнес",
    english: "Англійська",
    spanish: "Іспанська",
    french: "Французька",
    german: "Німецька",
    italian: "Італійська",
    takeaway: "Замовлення на винос, коли споживач повідомлений про готовність",
    onsite: "Замовлення отримується на місці, де сканується QR-код",
    menu_type: "Тип меню",
    myMap: "Моя карта",
    drawMap: "Намалювати карту",
    deleteWall: "Видалити стіну",
    addProduct: "Додати продукт",
    addCategory: "Додати категорію",
    editCategory: "Редагувати категорію",
    addNewCategory: "Додати нову категорію",
    categoryName: "Назва категорії",
    identifier: "Ідентифікатор",
    editProduct: "Редагувати продукт",
    addNewProduct: "Додати новий продукт",
    productName: "Назва продукту",
    description: "Опис",
    productPrice: "Ціна продукту",
    category: "Категорія",
    selectImage: "Вибрати зображення",
    change: "Змінити",
    remove: "Видалити",
    inventory: "Інвентар",
    selectCategoryToAssign: "Вибрати категорію для призначення",
    assignCategory: "Призначити категорію",
    noCategory: "Без категорії",
    pleaseSelectCategoryAndProduct: "Будь ласка, виберіть категорію та хоча б один продукт!",
    productNotFound: "Продукт не знайдено!",
    pleaseFillOutAllFields: "Будь ласка, заповніть всі поля!",
    errorUploadingImage: "Помилка завантаження зображення",
    categoryNotFound: "Категорію не знайдено!",
    areYouSure: "Ви впевнені?",
    doYouWantToSaveAllChanges: "Бажаєте зберегти всі зміни?",
    yesSaveIt: "Так, зберегти!",
    saved: "Збережено!",
    yourChangesHaveBeenSaved: "Ваші зміни збережено.",
    doYouWantToDeleteThisProduct: "Бажаєте видалити цей продукт?",
    yesDeleteIt: "Так, видалити!",
    deleted: "Видалено!",
    yourProductHasBeenDeleted: "Ваш продукт було видалено.",
    doYouWantToDeleteThisCategory: "Бажаєте видалити цю категорію?",
    yourCategoryHasBeenDeleted: "Вашу категорію було видалено.",
    stablishments: "Заклади",
    menus: "Меню",
    qrCodes: "QR-коди",
    addQr: "Додати QR",
    saveAll: "Зберегти все",
    addNewQr: "Додати новий QR",
    qrName: "Назва QR",
    viewMenu: "Переглянути меню",
    youWontBeAbleToRevertThis: "Ви не зможете це скасувати!",
    yourQrHasBeenDeleted: "Ваш QR було видалено.",
    emailPlaceholder: "Електронна пошта...",
    passwordPlaceholder: "Пароль...",
    login: "Увійти",
    incorrectUserOrPassword: "Неправильний користувач або пароль",
    loginError: "Помилка входу",
    addNewStablishment: "Додати новий заклад",
    map: "Карта",
    edit: "Редагувати",
    remove: "Видалити",
    viewOrders: "Переглянути замовлення",
    viewOrdersMap: "Переглянути карту замовлень",
    sendToKitchen: "Відправити на кухню",
    sendAlert: "Відправити сповіщення",
    finish: "Завершити",
    createdAgo: "Створено",
    inKitchenFor: "На кухні протягом",
    qrCode: "QR-код",
    email: "Електронна пошта",
    phone: "Телефон",
    comments: "Коментарі",
    alertSentAgo: "Сповіщення відправлено",
    alerting: "Сповіщення",
    details: "Деталі",
    backToPending: "Повернутися до очікування",
    backToOngoing: "Повернутися до виконання",
    cancelOrder: "Скасувати замовлення",
    enableSoundAlerts: "Увімкнути звукові сповіщення",
    newOrders: "Нові Замовлення",
    inKitchen: "На кухні",
    alertingCustomer: "Сповіщення клієнта",
    closedOrders: "Закриті Замовлення",
    cancelledOrders: "Скасовані Замовлення",
    orders: "Замовлення",
    translation: "переклад",
    products: "продукти",
    address: "Адреса",
      termsRequired: 'Ви повинні прийняти умови та положення.',
      restaurantName: 'Назва ресторану...',
  logoTitle: 'Логотип',
  accountconfirm: 'Ваш обліковий запис підтверджено, тепер ви можете увійти.',
  confirmEmail: 'Підтвердіть свій електронний лист',
  confirmEmailText: 'Будь ласка, натисніть на посилання, яке ми надіслали на вашу електронну пошту, щоб підтвердити свій обліковий запис.',
  register: 'Реєстрація',
  enterAddress: "Введіть свою адресу",
  logo: "Логотип",
  coverImage: "Зображення обкладинки",
   avgOrderValue: 'Середнє значення замовлення',
  sales: 'Продажі',
  topCustomers: 'Топ клієнти',
  topOrderedProducts: 'Найпопулярніші продукти',
  password: 'Пароль...',
  ticket: 'Попередній рахунок',
  termsAndConditions: 'Я погоджуюся з',
  termsLinkText: 'умовами та положеннями',
  getStarted: 'Почати',
  createApp: 'Ми створюємо додаток',
  createAppDesc: 'Ми створюємо додаток для вашого ресторану.',
  addProducts: 'Ви додаєте продукти',
  addProductsDesc: 'Ви додаєте продукти до меню і налаштовуєте для генерації QR-кодів.',
  customersEnjoy: 'Ваші клієнти насолоджуються',
  customersEnjoyDesc: 'Ваші клієнти сканують QR-код і роблять замовлення.',
  restaurantNameExists: 'Назва ресторану вже існує.',
  errorCheckingRestaurantName: 'Помилка при перевірці назви ресторану.',
  nameRequired: "вимагається",
  restaurantNameRequired: 'Назва ресторану є обов’язковою.',
  emailRequired: 'Електронна пошта є обов’язковою.',
  emailInvalid: 'Формат електронної пошти не є дійсним.',
  passwordRequired: 'Пароль є обов’язковим.',
  userExists: 'Користувач вже існує.',
      "nifLabel": "N.I.F",
    "invoiceTitle": "Спрощений рахунок",
    "dateLabel": "Дата",
    "invoiceNumberLabel": "Номер",
    "tableLabel": "Стіл",
    "guestLabel": "Відвідувачі",
    "waiterLabel": "Офіціант",
    "totalWithoutTaxLabel": "Сума без ПДВ",
    "taxLabel": "ПДВ",
    "totalLabel": "Загалом",
    "tipLabel": "ЧАЙОВІ",
    "thankYouMessage": "ПДВ Включено - Дякуємо за візит",
    "description": "Опис",
    "quantity": "К-сть",
    "price": "Ціна",
    "total": "Сума"
}