<template>
  <md-card>
      <md-card-header>
          <div class="card-icon p-1 bg-danger">
            <md-icon class="">edit_note</md-icon>
          </div>
          <h4 class="title mt-2">Rutinas asignadas a mí</h4>

      </md-card-header>
      <md-card-content>
           <div class="p-2 bg-white">


              <div  v-if="id && plannings">
                <PlanningView :item="plannings" @refresh="refresh()"/>
              </div>

              <div class=" mt-3" v-if="!id && plannings && !open && planningsByGoal['pending']" >
               
                    <PlanningSlide :plannings="planningsByGoal['pending']" @goTo="goTo($event)" @refresh="refresh()"/>
                  
                </div>
              <div class=" mt-3" v-if="!id && plannings && !open && planningsByGoal['inprogress']" >
               
                 
                  <h5><md-icon>autorenew</md-icon>En progreso</h5>
                    <PlanningSlide :plannings="planningsByGoal['inprogress']" @goTo="goTo($event)" @refresh="refresh()"/>
                  
                </div>
              <div class=" mt-3" v-if="!id && plannings && !open && planningsByGoal['done']" >
               
                  <h5><md-icon>history</md-icon>Finalizadas</h5>
                    <PlanningSlide :plannings="planningsByGoal['done']" @goTo="goTo($event)" @refresh="refresh()"/>
                  
                </div>
            
           
            </div>

      </md-card-content>
    </md-card>
 
</template>

<script>
import { getAllPlanningsAction, deletePlanningAction, getPlanningAction,getAllUserPlanningAction } from '@/pages/Training/actions';

import CreatePlanning from './CreatePlanning.vue'; // El formulario de edición
import PlanningView from './PlanningView.vue'; // El formulario de edición
import PlanningSlide from './PlanningSlide.vue'; // El formulario de edición
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      open:false,
      plannings: false,
      currentSort: 'name',
      currentSortOrder: 'asc',
      selectedPlanning: null, // Planning seleccionado para editar,
      id: false,
      planningsByGoal: [], // Almacenar plannings agrupados por goal

    };
  },  
  watch: {
    $route(to, from) {
      // Cambiar la `key` al detectar un cambio en la ruta
       this.start();
    },
  },
  components: {
    CreatePlanning,
    PlanningView,
    PlanningSlide
  },
  async created() {
    if(this.$router.currentRoute.params && this.$router.currentRoute.params.id){
      this.id = this.$router.currentRoute.params.id;
    }else{
      this.id = null
    }
    this.start();
  },
  watch: {
    $route(to, from) {
      // Acción que quieres realizar al cambiar la ruta
      this.refreshComponent();
    }
  },
  methods: { 
    goTo(item){
        this.$router.push({
          name: 'PlanningView',
          params: { id: item._id, objeto: { id: item._id, planning: item } }
        }); 
        this.id = item._id  
        this.start();
    },
    refresh(){
      this.open = false
      this.start();
    },
    groupPlanningsByGoal() {
      // Agrupa plannings por goal
      this.planningsByGoal = this.plannings.reduce((acc, planning) => {
        const { goal } = planning;
        if (!acc[goal]) {
          acc[goal] = [];
        }
        acc[goal].push(planning);
        return acc;
      }, {});
    },
    groupPlanningsByStatus() {
      this.planningsByGoal = this.plannings.reduce((acc, planning) => {
        const { status } = planning.myPlanning;
        if (!acc[status]) {
          acc[status] = [];
        }
        acc[status].push(planning);
        return acc;
      }, {});
    },
    async refreshComponent() {
      // Reinicia los datos o realiza cualquier acción que necesites
    
          if(this.$router.currentRoute.params && !this.$router.currentRoute.params.id){
            this.id = null;
             const response = await getAllPlanningsAction();
              if (response.ok) {
                this.plannings = response.plannings;
              } else {
                console.error('Failed to fetch plannings: ', response.message);
              }
          
          }
    },
    async start() {
      try {
        let response;
        if(this.id){
         response = await getPlanningAction(this.id);

          if (response.ok) {
            this.plannings = response.planning;
          } else {
            console.error('Failed to fetch plannings: ', response.message);
          }
        }else{
         response = await getAllPlanningsAction();
          if (response.ok) {
            const filteredPlannings = response.plannings.filter(planning => planning.myPlanning);
            this.plannings = filteredPlannings;
            this.groupPlanningsByStatus()
          } else {
            console.error('Failed to fetch plannings: ', response.message);
          }
        }
      } catch (error) {
        console.error('Error fetching plannings: ', error);
      }
    },
  }
};
</script>
<style scoped>
.planningimage{
  width: 400px !important;
  height: 200px !important;
  border-radius:8px !important;
}
.cursor-pointer{
  cursor:pointer!important;
}
</style>
<style scoped>
.calendar-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  
}

@media (max-width: 980px) {
  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1px;
  }
}

.calendar-day-name {
  text-align: center;
  font-weight: bold;
}
.md-button.md-sm .md-ripple {
    font-size: 11px;
    padding: 4px !important;
}
.weekcell{
   display: grid;
  grid-template-columns: repeat(7, 1fr);
  
}
.daycell{
  
    border:0;
    border-radius: 4px;
}
.calendar-day {
  background-color: #fff; /* Color de fondo por defecto */
}

.past-day {
  background-color: #f0f0f0; /* Color gris para los días pasados */
}
.itemactivity{
    position: absolute;
    top: -6px;
    right: -15px;
    font-size: 8px;
    border-radius: 50%;
    border: 0;
    color: #11b5c9;
    padding: 0px 9px;
    font-weight: 900;
}
.itemactivity i{
    font-size: 10px !important; 
}
.opaco{
      background-color: #d8d8d8 !important;   
    text-decoration-color: #858585;
}

.md-card-content ul {
  list-style-type: none;
  padding: 0;
}

.md-card-content ul li {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}
</style>


<style scoped>
.calendar-grid-phone {
  display: flex;
  flex-wrap: nowrap; /* Para mantener todas las tarjetas en una sola línea */
  overflow-x: auto; /* Para permitir el scroll horizontal */
  -webkit-overflow-scrolling: touch; /* Para una mejor experiencia de scroll en dispositivos táctiles */
}

.calendar-day-phone {
    margin-top: 4px;
  flex: 0 0 auto; /* Evita que las tarjetas se encogen y las mantiene en su tamaño original */
  margin-right: 10px; /* Espaciado entre las tarjetas */
  width: 90%; /* Ajusta el tamaño de la tarjeta, puedes cambiarlo según tu necesidad */
}
@media (min-width: 768px) {

.calendar-day-phone {
  width: 100%; /* Ajusta el tamaño de la tarjeta, puedes cambiarlo según tu necesidad */
}

.calendar-grid-phone {
  display: block;
  flex-wrap: nowrap; /* Para mantener todas las tarjetas en una sola línea */
  overflow-x: auto; /* Para permitir el scroll horizontal */
  -webkit-overflow-scrolling: touch; /* Para una mejor experiencia de scroll en dispositivos táctiles */
}

}


.calendar-day-phone md-card {
  width: 100%; /* Asegura que el md-card ocupe todo el ancho del contenedor */
}

/* Estilos para organizar las actividades como un calendario */
.calendar-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.calendar-day {
}

.card-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}

.title {
  margin-left: 10px;
}


.md-card-content ul {
  list-style-type: none;
  padding: 0;
}

.md-card-content ul li {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

.calendar-grid .md-card {
  min-height: 200px;
}

p {
  text-align: center;
  color: #999;
}

.error {
  color: red;
  font-size: 12px;
}
.cursor-pointer{
  cursor:pointer;
}
</style>
