<template>
  <div class="md-layout text-center mt-4">
    <div 
      class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100"
    >
    <div class="loading-container mr-2 w-100 text-center p-4" v-if="isLoading">
            <md-progress-spinner 
              :md-diameter="80" 
              md-mode="indeterminate" 
              class="text-red bg-red md-red">
            </md-progress-spinner>
          </div>
    <form @submit.prevent="handleSubmit"  v-if="!isLoading">

      <login-card  class="mb-0">
        <div slot="title"  class="image-container logo logoregister mt-4 pt-4">

         <div v-if="subdomain != 'app' 
          && stablishmentReady() 
          && stablishment.coverImage" class="w-100 mb-1 imagebanner">
              <div class="logoresto" :style="'background:url('+stablishment.logo+')'">
                 
              </div>  
        </div>
          <img v-else :src="logo" title="" />
        </div>
        <div slot="buttons">
          <small>{{$t('welcome')}}</small>
          <h3 v-if="subdomain !== 'app' && stablishmentReady()">{{stablishment.name}}</h3>          
         
          <h3 v-if="subdomain !== 'app' && !stablishmentReady()">{{subdomain}}</h3>          
         
          <small v-if="subdomain !== 'app' && stablishmentReady()">{{stablishment.address}}, {{stablishment.country}}</small> 
        </div>
        <md-field class="md-form-group" slot="inputs" v-if="!openRegister" >
        
          <md-icon>email</md-icon>
          <label>{{$t('accessIdentifier')}}</label>
          <md-input      :required="true" v-model="email" type="text"></md-input>
        </md-field>


        <div slot="footer" >
          <div v-if="errorMessage" class="error-message">
            {{ errorMessage }} 
          </div>
          <md-button  v-if="!openRegister && stablishment" 
            :disabled="!email"
            :style="'background-color:'+stablishment.primaryColor+' !important'"  @click="login" class="md-primary md-lg">
            <span> {{ $t('login') }} </span>
           
          </md-button>
          
          <div class="my-3">
   
              <div  v-if="!openRegister" >
                <md-button 
                  class="md-button md-sm w-100 md-white"
                  @click="openRegister=true"><md-icon>person_add</md-icon>{{$t('register')}}
                </md-button>
              </div>                

              <div v-if="openRegister" class="w-100 card p-2 d-block">
                  <md-button 
                  class="md-button md-small md-danger md-simple float-right" @click="openRegister=false" >
                    <md-icon>close</md-icon>
                  </md-button>
                  <NewUserForm 
                  :required="['name', 'email']" 
                  :hide="['paymentDate','role','password','imc']"
                  :roles="[]"
                  :anonymous="true"
                  type="gym"
                  @userCreated="userCreated($event)" 
                  ></NewUserForm>
                </div>


          </div>
        </div>

      </login-card>

    </form>
    </div>
  </div>
</template>

<script>
import { LoginCard } from "@/components";
import { loginAction,confirmAction,sendPassword,changePassword } from "@/pages/Auth/actions";
import Swal from "sweetalert2";
import permissionsMixin from '@/mixins/permissionsMixin';
import { getProfileStablishmentsAction } from "@/pages/Stablishments/actions";
import NewUserForm from "@/pages/Dashboard/Pages/UserProfile/NewUserForm.vue";

export default {
  mixins: [permissionsMixin],
  components: {
    LoginCard,
    NewUserForm
  },
  data() {
    return {
      email: null, 
      isLoading: false,
      password: null,
      register:false,
      openRegister:false,
      logo: '/img/gotakeit.png',
      errorMessage: null, // Añadimos una propiedad para el mensaje de error
      hastoken: false,
      confirm: false,
      token:false,
      subdomain: false,
      showforgot: false,
      stablishment:false,
      allStablishments: [],
    };
  },
  mounted() {

  },
  created(){

    this.token = localStorage.getItem('token');

    if(localStorage.getItem('token')){
      this.$router.push('/dashboard');
      return;
    }

    this.subdomain = window.location.hostname;
    // Usamos split para dividir la URL en partes separadas por "."
    const parts = this.subdomain.split(".");
    // Tomamos la primera parte, que es lo que necesitamos
    this.subdomain = parts[0];

    if(this.$route.query.state){
      const state = this.$route.query.state;
      const code = this.$route.query.code;
      const newurl = "https://"+state+".app.gotake.it/#/stripe?code="+code;
  
      window.location.href = newurl
      return;
    }
    if(this.$router.currentRoute.params.token){
      this.hastoken = true
      const response = confirmAction(this.$router.currentRoute.params.token);
     
      if(!response.ok){
        this.confirm = false
      }else{
        this.confirm = true
        Swal.fire({
          title: this.$t('success'),
          text: this.$t('accountconfirm'),
          type: "success",
          position: 'top',
        })
      }
    }

    if(this.$router.currentRoute.params.login){
      localStorage.setItem('token', this.$router.currentRoute.params.login);
      localStorage.setItem('name', this.$router.currentRoute.params.name);

        this.$router.push('/dashboard');
      
      return;
    }


    this.getStablishments();
    


  },
  methods: {
    userCreated(id){
        this.openRegister = false
        this.email = id
        this.login()
    },
    buttonStyle(color) {
      return {
        backgroundColor: `${color} !important`,
      };
    },
    async getStablishments(){
      this.isLoading = true;
      const stablishmentsResponse = await getProfileStablishmentsAction();
      this.allStablishments = stablishmentsResponse.stablishments
      if(this.allStablishments){
       this.stablishment = this.allStablishments[0];
      }
      if(this.stablishment){

        if(this.stablishment.primaryColor == '#FFFFFF' ||
        this.stablishment.primaryColor == '#ffffff' || 
        !this.stablishment.primaryColor){
          this.stablishment.primaryColor = '#333';
        }
        if(this.stablishment.secondaryColor == '#FFFFFF' ||
        this.stablishment.secondaryColor == '#ffffff' || 
        !this.stablishment.secondaryColor){
          this.stablishment.secondaryColor = '#333';
        }


        this.register = this.stablishment.businessName.register;
      }

      if(this.stablishmentReady()){
        // Cambiar el título de la página
        document.title = this.stablishment.name;
        // Cambiar el favicon de la página
        const link = document.createElement('link');
        link.rel = 'icon';
        link.href = this.stablishment.logo;
        document.head.appendChild(link);
      }

      this.isLoading = false;

    },
    stablishmentReady(){
        return (
          this.stablishment.name !== 'My Fitness' &&
          this.stablishment.name !== 'Costa Azul' && 
          this.stablishment !== undefined )
    },
    async forgot(){
        Swal.fire({
        title: this.$t('forgotPassword'),
        html: this.$t('passwordResetEmail'),
        type: "warning",
        showCancelButton: true,
        position: 'top',
      }).then((result) => {
       if (result.isConfirmed) {
        this.showforgot = true
                this.errorMessage = this.$t('passwordResetEmail'); // Mensaje de error genérico
        }else{

            this.errorMessage='';
        }
      });
    },
    handleSubmit(event) {
      event.preventDefault();
    },
    async sendPassword() {
        Swal.fire({
          title: this.$t('emailSent'),
          html: this.$t('correctEmail'),
          type: "warning",
          showCancelButton: true,
          position: 'top',
        }).then((result) => {
          if (result.isConfirmed) {
                try {
               sendPassword(this.email);
              } catch (error) {

              }
            this.showforgot = false
          }else{
            this.showforgot = false
            this.errorMessage='';
          }
        });

    },
    async login() {
      try {
        const response = await loginAction(this.email, null);


         if(this.subdomain == 'app'){
          this.errorMessage = 'Redirecting...'; // Mensaje de error específico
         }

        if (response.token) {
          this.errorMessage = null;

          if(this.$route.query.qr){
               this.$router.push('/accessqr/'+this.$route.query.qr);
               return;
          }
         this.$router.push('/accessqr/'+this.$route.query.qr);
          if (this.$router.currentRoute.path !== '/dashboard') {
            this.$router.push('/dashboard');
          }
        } else {
         if(this.subdomain !== 'app'){
          this.errorMessage = this.$t('incorrectUserOrPassword'); // Mensaje de error específico
         }
        } 
      } catch (error) { 
            this.errorMessage = this.$t('incorrectUserOrPassword'); // Mensaje de error genérico
      }
    }
  }
};
</script>

<style scoped>

.image-container {
    margin-top: 17px;
    box-shadow: inherit !important;
}

.cursor-pointer {
  cursor:pointer;
}
.image-container {
  max-width: 400px;
}

.error-message {
  color: red;
  margin-top: 10px;
}
.gotakeit{
  font-weigth:bold;
  cursor:pointer !important;
    color: #11b5c9 !important;
}
.login-page .md-card-login .md-card-header {
    margin-bottom: 0px !important;
} 
</style>
