// src/mixins/permissionsMixin.js
import Swal from 'sweetalert2';
import { format } from 'date-fns';

export default {
  data() {
    return {
      rolesPermisos: {
        admin: [],
        employee: ['StablishmentBLock','sells','qrSettings','menuslist','userlist','inventorylist','stablishmentslist'],
        gym: ['sells','menuslist','inventorylist'],
        restaurant: ['qrSettings'],
        teacher: ['sells']
      },
    };
  },
  methods: {
    hasPermission(palabraClave) {
      const role = localStorage.getItem('role');
      return !this.rolesPermisos[role]?.includes(palabraClave);
    },
    isRestaurant() {
      const role = localStorage.getItem('role');
      return role == 'restaurant' || role == 'customer' || role == 'employee'
    },
    isGym() {
      const role = localStorage.getItem('role');
      return role == 'gym'
    },
    isTeacher() {
      const role = localStorage.getItem('role');
      return role == 'teacher'
    },
    isClientGym() {
      const role = localStorage.getItem('role');
      return role == 'client'
    },
    isEmployeeRestaurant() {
      const role = localStorage.getItem('role');
      return role == 'employee'
    },
    getLocalDate(utcDate, dateformat) {
      const date = new Date(utcDate);

      return format(date, dateformat)+'hs.';  // Formato de fecha y hora según la hora local del navegador
    },
    isDemo(showpopup=true) {
        const  subdomain = window.location.hostname.split('.')[0];
        if(subdomain == 'gotakeitdemo'){
          if(showpopup){
            Swal.fire({
              title: "DEMO",
              html: this.$t('demoMessage') + '<br><a class="btn mt-4 border border-primary" href="https://app.gotake.it/#/register" target="_blank">'+this.$t('register')+'</a> ',
              icon: 'error',
              confirmButtonText: 'OK'
            });
          }
          return true;
        }
        if(subdomain == 'gotakeitgym'){
            if(showpopup){
          Swal.fire({
              title: "DEMO",
              html: this.$t('demoMessage') + '<br><a class="btn mt-4 border border-primary"  href="https://app.gotake.it/#/register/gym" target="_blank">'+this.$t('register')+'</a> ',
              icon: 'error',
              confirmButtonText: 'OK'
            });
          }
          return true;
        }
        return false;
    },
    isDemoResto(){
        const  subdomain = window.location.hostname.split('.')[0];
        if(subdomain == 'gotakeitdemo'){
          return true;
        }
        return false;
    },
    isDemoGym() {
        const  subdomain = window.location.hostname.split('.')[0];
        if(subdomain == 'gotakeitgym'){
          return true;
        }
        return false;
    }
  }
};
