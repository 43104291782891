<template>
 <div>
		 <div class=" mt-2 mb-2"
		  <strong class="cursor-pointer" @click="open=!open">Resultado del día {{ getLocalDate(history.createdAt,'PP HH:mm') }} 
		  <md-icon v-if="!open" class="text-primary">arrow_drop_up</md-icon>
		  <md-icon v-else class="text-primary">arrow_drop_down</md-icon>
		  </strong>
		  <div class="card" v-if="open">
		    <div v-for="ex in history.exercises">
		        <small><strong>-  {{ex.name}}: </strong> </small>
		        <small v-if="ex.repetitions && ex.repetitions.length">Repeticiones: {{ex.repetitions.join(',')}} </small>          
		        <small v-if="ex.rest && ex.rest.length">Descanso: {{ex.rest.join(',')}} </small>
		        <small v-if="ex.sets && ex.sets.length">Series: {{ex.sets.join(',')}} </small>
		        <small v-if="ex.duration && ex.duration.length">Duración: {{ex.duration.join(',')}} </small>
		        <small v-if="ex.weight && ex.weight.length">Pesos: {{ex.weight.join(',')}} </small>
		        <small v-if="ex.distance && ex.distance.length">Distancia: {{ex.distance.join(',')}} </small>
		        <small v-if="ex.speed && ex.speed.length">Velocidad: {{ex.speed.join(',')}} </small>
		    </div>
		  </div>
		</div>
	

 </div>
            
</template>

<script>
import permissionsMixin from '@/mixins/permissionsMixin';

export default {  

  mixins: [permissionsMixin],
  data() {
    return {
     open:false
    };
  },
  props:{
    history: []
  },
 }

 </script>