export default {
  addNewMenu: 'Adicionar Novo Menu',
  onlymenu: 'Mostrar apenas o menu, sem opção de fazer pedidos',
menuName: 'Nome do Menu',
menuTypesDescription: 'Existem 4 tipos de menu que você pode escolher de acordo com as necessidades do seu negócio',
deliveryConfirmation: 'Seu pedido já foi entregue',
pay: 'Pagar',
'height' : 'Altura',
'weight' : 'Peso',
"gender" : "Gênero",
deactivateQR: 'Desativar QR',
serviceStart: 'Início do serviço',
subscribe: 'Inscrever-se',
selectAll: 'Selecionar tudo',
  "enrolledPeople": "Pessoas inscritas",
  "emailExistsMessage": "Este email já existe.",
    "perMonth": "por mês",
      "notEnrolledInAnyClass": "Você não está inscrito em nenhuma aula",
      "monthlyPayment": "Pagamento mensal",
    "quota": "Limites",
"thankyoushipping" : "Enviaremos um e-mail com os detalhes do envio em breve.",
    "pleaseinsertemail": "Insira o e-mail com o qual você registrou o aplicativo para ser redirecionado ao seu app.",
      "download": "Baixar",
      "select": "Selecionar",
      "bodyZone": "Zona do corpo",
        "clientAccess": "Acesso para clientes",
      'shoulders' : 'Ombros',
        "pending": "Pendente",
      'perderpeso' : 'Perder peso',
'ganarmusculo' : 'Ganhar músculo',
'tonificacion' : 'Tonificação',
'movilidad' : 'Mobilidade',
'biceps' : 'Bíceps',
'triceps' : 'Tríceps',
'chest' : 'Peito',
'back' : 'Costas',
  "done": "Concluído",
    "inprogress": "Em andamento",
'abdomen' : 'Abdômen',
'glutes' : 'Glúteos',
'quadriceps' : 'Quadríceps',
'hamstrings' : 'Isquiotibiais',
'calves' : 'Panturrilhas',
    "stickQRMessage": "Cole o QR na porta do seu estabelecimento para que seus clientes o escaneiem ao entrar e controlar o acesso",
      "myProfile": "Meu perfil",
      "unlimitedMonthlyPayment": "Pagamento mensal com acessos ilimitados à academia e vagas ilimitadas",
  "unlimitedMonthlyPaymentWithQuota": "Pagamento mensal com acessos ilimitados à academia e {cupos} vagas para aulas por mês",
  "limitedMonthlyPayment": "Pagamento mensal com {access} acessos mensais à academia e {cupos} vagas para aulas por mês",
  "limitedAccessUnlimitedQuota": "Pagamento mensal com {access} acessos mensais à academia e vagas ilimitadas para as aulas",
  "oneTimePayment": "Pagamento único por {access} acessos à academia e {cupos} vagas para aulas",
      "paymentMethod": "Forma de pagamento",
      "paymentPerAccessOrQuota": "Pagamento por quantidade de acessos ou vagas",
      "classLimitPerMonth": "Quantidade de aulas nas quais este cliente pode se inscrever",
      "remainingQuota": "Limites restantes",
        "unlimitedAccess": "Acessos ilimitados",
          "noQuotaLeft": "Você não tem mais vagas disponíveis",
    "waitingForEntries": "Aguardando entradas",
ticket: 'Nota de consumo',
  "mustLoginToSignUp": "Você deve fazer login para se inscrever",
  "uploadFile": "Enviar arquivo",
    "noQuotaLeftThisMonth": "Você não tem mais vagas disponíveis para este mês",
    "myCalendar": "Meu calendário",
      "recurringActivitiesMessage": "As atividades recorrentes não permitem inscrição por enquanto, mas vamos melhorar isso em breve. Escolha uma data específica para habilitar as inscrições.",
  "allowOverQuotaMessage": "Escolhendo esta opção, qualquer cliente que não tenha vagas disponíveis, mas que tenha pago sua mensalidade, poderá se inscrever também.",
  "anyClientCanSignUp": "Qualquer cliente pode se inscrever",
      "allowedAccessCount": "Quantidade de acessos permitidos (Escaneamento do QR ao entrar no estabelecimento)",
      "myClasses": "Minhas aulas",
    "noSlotsAvailable": "Não há vagas disponíveis para esta aula",
      "scanQrFirst": "Primeiro, você deve escanear o QR para poder clicar em entrar.",
    "allready": "Você já está inscrito nesta aula",
    "defineSpecificDate": "Definir uma data específica",
    "seeDemo": "Ver demonstração",
      "availableSlots": "Vagas disponíveis",
      "temporaryIssue": "Estamos com um problema temporário, tente novamente mais tarde.",
      "signUpForThisClass": "Inscrever-me nesta aula",
      "signUpForClass": "Inscrever-me em uma aula",
      "enableRegistrations": "Habilitar inscrições para esta atividade",
    "seeDemoBeforeSignUp": "Quero ver uma demonstração antes de me cadastrar.",
  "imagemenu": "Mostrar apenas a foto do menu, sem opção de fazer pedidos pelo consumidor.",
  "shareScheduleInfo": "Você poderá compartilhar os horários com seus clientes inserindo o link em suas redes sociais. Eles também poderão vê-lo no telefone ao entrar na academia escaneando o código QR.",
imageSet1_step1_title: '1- Escaneiam o QR',
  "availableEntries": "Entradas disponíveis",
  "unlimitedQuota": "Limites ilimitados",
  "quotaMessage": "Inserindo os limites, o sistema não permitirá que as pessoas continuem se inscrevendo quando o limite for atingido. Para se inscrever, devem escanear o código QR que você colocar na sua academia ou usar o link:",
  "demoMessage": "Isto é uma demonstração, você precisa criar sua própria conta para modificar opções. Você pode fazê-lo em:",
  "uploadMenuMessage": "Você pode enviar uma imagem para cada página do menu ou um PDF com o menu completo.",
imageSet1_step1_description: 'Escaneiam o QR em uma piscina, praia ou mercado.',
  "lastEntry": "Última entrada",
    "leaveEmptyIfNoQuota": "Deixe em branco se suas aulas não tiverem limites definidos.",
imageSet1_step2_title: '2- Receber o pedido',
  "leaveEmptyForEmail": "Deixe em branco se quiser usar o e-mail como identificador.",
imageSet1_step2_description: 'O pedido é recebido em um tablet ou smartphone.',
imageSet1_step3_title: '3- Cliente é alertado',
imageSet1_step3_description: 'O cliente recebe um alerta quando o prato está pronto e vai buscá-lo.',
generateReceipt: 'Gerar recibo',
  "gotakeitUsageMessage": "Isso permite começar a usar o Gotakeit sem a necessidade de carregar os clientes manualmente. Eles criarão seus perfis e você deverá inserir a data de pagamento depois.",
  "selfRegistrationMessage": "Permitir que as pessoas se registrem ao escanear o código QR caso ainda não tenham um perfil criado.",
  "identifierExistsMessage": "Este identificador já foi utilizado por outro usuário.",
  myClients: "Meus clientes",
  "identifierMessage": "Insira um identificador que você possa lembrar facilmente. Você o usará aqui para acessar a academia toda vez que escanear o código QR. Exemplo: seu número de telefone, RG ou e-mail.",
    "activityLabel": "Atividade",
      "leaveScreenOpenMessage": "Deixe esta tela aberta no navegador do seu estabelecimento e você poderá verificar sempre que alguém entrar escaneando o código QR.",
  "activityPlaceholder": "Nome da atividade",
  "selectDateLabel": "Selecionar data",
    "uploadImageMessage": "Envie uma imagem que mostre seu rosto para identificá-lo ao entrar na academia.",
  "startTimeLabel": "Hora de início",
  "endTimeLabel": "Hora de fim",
  "timePlaceholder": "HH:MM",
  "removeButton": "Remover",
  "addButton": "Adicionar atividade",
  "saveGridButton": "Salvar grade",
    "addDailyActivityTitle": "Adicionar Atividade Diária",
  "activityLabel": "Atividade",
  "activityPlaceholder": "Ex: Yoga",
  "dayOfWeekLabel": "Dia da semana",
  "startTimeLabel": "Hora de início",
  "endTimeLabel": "Hora de término",
  "addButton": "Adicionar Atividade",
  "Lunes": "Segunda-feira",
  "Martes": "Terça-feira",
  "Miércoles": "Quarta-feira",
  "Jueves": "Quinta-feira",
  "Viernes": "Sexta-feira",
      "Monday": "Segunda-feira",
    "Tuesday": "Terça-feira",
    "Wednesday": "Quarta-feira",
    "Thursday": "Quinta-feira",
    "Friday": "Sexta-feira",
    "Saturday": "Sábado",
    "Sunday": "Domingo",
    "January": "Janeiro",
    "February": "Fevereiro",
    "March": "Março",
    "April": "Abril",
    "May": "Maio",
    "June": "Junho",
    "July": "Julho",
    "August": "Agosto",
    "September": "Setembro",
    "October": "Outubro",
    "November": "Novembro",
    "December": "Dezembro",
  "Sábado": "Sábado",
  "Domingo": "Domingo",
paymentsAndReceipts: 'Pagamentos e recibos',
  waitingPeriod: "Período de espera", // Portugués
  waitingPeriodExplanation: "Escolha a quantidade de dias que você quer esperar antes de mostrar um banner vermelho para um cliente que não pagou.",
imageSet2_step1_title: '1- Escaneie o QR',
imageSet2_step1_description: 'Escaneie o QR e faça os pedidos que desejar.',
imageSet2_step2_title: '2- Receber o pedido',
imageSet2_step2_description: 'O restaurante recebe o pedido na cozinha.',
imageSet2_step3_title: '3- O garçom serve o prato',
imageSet2_step3_description: 'O garçom leva o pedido à mesa.',
    "importUsers": "Importar Usuários de Arquivo Excel (.xls ou .xlsx)",
    "matchColumns": "Emparelhar colunas",
    "selectDateFormat": "Selecionar formato de data",
    "previewImport": "Pré-visualizar Importação",
    "previewUsers": "Pré-visualização dos Usuários a Importar",
    "noDataAvailable": "Sem dados disponíveis para visualização.",
    "confirmAndImport": "Confirmar e Importar",
    "resume": "Retomar",
    "pause": "Pausar",
    "error": "Erro",
    "emptyFile": "O arquivo está vazio ou não contém dados.",
    "missingHeaders": "O arquivo deve conter cabeçalhos na primeira linha.",
    "limitExceeded": "Limite Excedido",
    "importLimit": "Não é possível importar mais de 3000 contatos em uma única execução.",
    "missingHeadersCSV": "O arquivo CSV deve conter cabeçalhos na primeira linha.",
    "importCancelled": "Importação cancelada",
    "errorCreatingUser": "Erro ao criar o usuário {name}:",
    "importedUsers": "{createdUsers} usuários foram importados ou editados com sucesso.",
imageSet3_step1_title: '1- Os clientes veem o menu',
imageSet3_step1_description: 'Eles veem o menu, mas não podem fazer pedidos.',
imageSet3_step2_title: '2- O garçom faz o pedido',
imageSet3_step2_description: 'O garçom acessa o QR da mesa e faz o pedido.',
imageSet3_step3_title: '3- Receber o pedido',
imageSet3_step3_description: 'O restaurante recebe o pedido na cozinha.',
imageSet3_step4_title: '4- O garçom serve o prato',
imageSet3_step4_description: 'O garçom leva o pedido à mesa.',
imageSet3_step5_title: '5- Os clientes pagam',
imageSet3_step5_description: 'Os clientes pagam da maneira tradicional ou escaneando o QR para pagar.',

completePayment: 'Complete seu pagamento',
filter: 'Filtrar',
differentWaysToUse: 'Diferentes modos de usar Go Take It',
perMonth: 'por mês',
importantNotifications: 'Aqui você receberá notificações importantes sobre a atividade das pessoas neste estabelecimento.',
remainingAccesses: 'Acessos restantes',
maxOrders: 'Quantidade máxima de pedidos',
maxOrdersLimit: 'Quantidade máxima de pedidos que este estabelecimento pode aceitar ao mesmo tempo. Se o número for excedido, o QR exibirá o menu, mas não permitirá gerar novos pedidos.',
sendMessageChat: 'Envie-nos uma mensagem no chat',
kitchenStatusOrders: 'Pedidos com status "cozinha"',
remainingOrders: 'Pedidos restantes',
noCreditsQRInactive: 'Sem créditos, seus QRs deixarão de funcionar',
accessGranted: 'Acesso permitido',
contactForHelp: 'Se você tiver dúvidas sobre o funcionamento do sistema ou como se inscrever, escreva-nos no chat e nós o ajudaremos. Não se preocupe, você falará com um humano.',
alreadySubscribedUnlimited: 'Você já está inscrito no plano ilimitado',
accessBlocked: 'Acesso bloqueado',
unsubscribe: 'Cancelar inscrição',
restaurant_role: 'Administrador',
unlimitedPlan: 'Plano ilimitado',
cancelableSubscription: 'Assinatura cancelável a qualquer momento, sem compromisso.',
subscriptionActivated: 'Sua assinatura foi ativada, aproveite o GoTakeIt sem limites!',
inviteToSubscribe: 'Nós te convidamos a se inscrever no plano ilimitado',
monthlySubscription: 'Assinatura mensal de 10.000 pesos argentinos por mês',
monthlySubscriptionEur: 'Assinatura mensal de 12,00 € por mês',
cancelSubscription: 'Você pode cancelar a assinatura a qualquer momento através do MercadoPago',
unlimitedCredits: 'Créditos ilimitados, acesso ilimitado a todas as funcionalidades',
business: 'Negócio',
gym_role: 'Administrador',
accesses: 'Acessos',
delete: 'Excluir',
logout: 'Sair',
  "access_reminder": "Você tem {credits} créditos restantes",
  "subscription_invite": "Convidamos você a se inscrever escolhendo a opção 'assinatura' no menu",
client_role: 'Cliente',
markAsPaid: 'Marcar como pago',
rejected: 'Rejeitado',
paymentDate: 'Data de pagamento',
accepted: 'Aceito',
paymentDateInfo: 'Insira seus clientes com a data de pagamento para que o sistema saiba quando deve rejeitar o ingresso. Por padrão, o sistema permite o acesso caso a data não tenha sido inserida.',
employee_role: 'Garçom',
loadClients: 'Carregar clientes',
clientsStartUsing: 'Seus clientes começam a usá-lo',
qrScanNotification: 'Seus clientes escaneiam o QR sempre que entram no estabelecimento e você recebe uma notificação se a entrada é permitida ou não',
accessControl: 'Controle de acesso para academias, clubes e estabelecimentos privados',
digitalQrMenu: 'Menu digital QR para restaurantes, guarda-sol na praia, piscinas ou hotéis',
activateQR: 'Ativar QR',
insertClients: 'Insira seus clientes com um nome e um identificador',
orderDelivered: 'Seu pedido já foi entregue, você pode fazer outro pedido voltando ao menu',
forgotPassword: 'Esqueci minha senha',
  "payment_due": "Faltam {days} dias para o vencimento do seu pagamento mensal",
   "access_denied": "Acesso negado",
  "payment_overdue": "Seu pagamento está vencido há {days} dias.",
  identifierNotFound: 'O identificador não existe',
tokenExpired: 'O token expirou',
paymentPending: 'Pagamento pendente',
qrSettings: 'Configurações para QR',
qrConfigurationInfo: 'Defina configurações para seus diferentes QRs e associe essa configuração ao QR que desejar. Você pode definir cores, idiomas e frases a serem usadas.',
popupNotification: 'Nós avisaremos com um popup nesta tela quando seu pedido estiver pronto',
goToMyApp: 'Ir a mi aplicación',
tryAgain: 'Tente novamente com um novo email',
nameAlreadyTaken: 'Esse nome já foi escolhido por outro usuário',
sendEmail: 'Enviar email',
consentNotice: 'Ao utilizar o aplicativo Gotakeit, você está consentindo com nossos termos de uso e política de privacidade. Você entende que suas informações são processadas em nome do estabelecimento e serão compartilhadas com eles.',
addUser: 'Adicionar usuário',
businessName: 'O nome do seu negócio',
users: 'Usuários',
userRolesDescription: 'Você pode criar outro usuário admin ou um usuário com a função de empregado. A função de empregado permite que o usuário use o GO Take It no seu telefone para fazer os pedidos e gerar as ordens ele mesmo para os casos onde o consumidor só pode ver o menu, mas não pode gerar ordens por conta própria.',
toPay: 'A pagar',
    "new_password": "Nova Senha",
    "repeat_password": "Repetir Senha",
    "change_password": "Alterar Senha",
    "password_too_short": "A senha deve ter pelo menos 6 caracteres.",
    "password_needs_symbol": "A senha deve conter pelo menos um símbolo.",
    "passwords_do_not_match": "As senhas não coincidem.",
    "password_changed": "Senha alterada com sucesso.",
    "error_changing_password": "Erro ao alterar a senha.",
emailSent: 'Email enviado',
orderSummaryEmail: 'Enviamos um email com o resumo do seu pedido, lembre-se de verificar o SPAM e remover o email dessa pasta para receber corretamente o alerta quando seu pedido estiver pronto.',
correctEmail: 'Se o endereço de email estiver correto, você receberá um link para redefinir a senha',
passwordResetEmail: 'Enviaremos um email com um link para redefinir a senha',
sendNotifications: 'Enviaremos notificações para este email quando você receber pedidos',
activatePaymentSystem: 'Ativar sistema de pagamento nos pedidos',
customersMustPay: 'Os clientes devem pagar no momento de fazer o pedido.',
needToSyncPayments: 'Você precisa sincronizar com o nosso sistema de pagamentos para ativar essa opção.',
  syncpayments:'Sincronizar com plataforma de pagamentos',
  ifyouunsync:'Se você desincronizar, não poderá receber mais pagamentos.',
  stripeAccountSync:'Sua conta foi sincronizada com sucesso.',
  nowYouCanAccess:'Agora você receberá os pagamentos diretamente na sua conta, nós não somos intermediários. Você já pode ativar a opção no seu menu para que os clientes paguem seus pedidos.',
    connectStripeAccount: 'Conectar conta do Stripe',
  syncStripeInfo: 'Sincronizar o Stripe permitirá pagamentos para que os clientes precisem pagar seus pedidos antes de solicitá-los.',
  noStripeAccount: 'Se você não tem uma conta Stripe, pode criar uma e voltar a esta tela para sincronizá-la.',
  alreadyHaveStripeAccount: 'Se você já tem uma conta Stripe conectada, pode sincronizá-la clicando aqui.',
  syncMyStripeAccount: 'Sincronizar minha conta do Stripe',
  createOrSyncStripeAccount: 'Criar ou Sincronizar conta do Stripe',
  error: 'Erro',
  stripeConnectError: 'Não foi possível conectar com o Stripe. Tente novamente.',
  haveStripeAccount: 'Você tem uma conta Stripe?',
  redirectSync: 'Se você já tem uma conta, vamos redirecioná-lo para sincronizá-la.',
  yesSyncAccount: 'Sim, sincronizar conta',
  noCreateAccount: 'Não, criar nova conta',
  stripeAccountCreated: 'Conta Stripe Criada',
  syncStripeInfoAfterCreation: 'Agora você precisa sincronizá-la conosco para começar a receber pagamentos.',
  stripeCreateError: 'Não foi possível criar a conta do Stripe. Tente novamente.',
  genericError: 'Ocorreu um erro. Tente novamente.',
  qrhelp: 'Você pode criar códigos QR para vinculá-los a um menu. Depois, imprima esse QR e coloque-o nas mesas do restaurante, nos guarda-sóis da praia ou nas camas ao lado da piscina de um hotel.',
    dashboard: 'Dashboard',
    total: "Total", 
    myOrder: "Meu pedido",
    orderNotification: "Enviaremos um email ou SMS quando seu pedido estiver pronto.",
    name: "Nome",
    enterName: "Digite seu nome",
    email: "Email",
    enterEmail: "Digite seu email",
    comments: "Comentários",
    enterComments: "Digite os comentários",
    accessIdentifierInfo: 'Este identificador será o que o cliente deve inserir ao escanear o QR, pode ser seu Documento Nacional de Identificação, passaporte ou número de cliente. Algo que ele possa lembrar facilmente.',
    code: "Código",
    phone: "Telefone",
    enter: 'Entrar',
    enterPhone: "Digite o número de telefone",
    confirmOrder: "Confirmar pedido",
    yourOrder: "Seu pedido",
    yespaid: "Já foi pago",
    nopaid: "Ainda não foi pago",
    accessIdentifier: 'Identificador de acesso',
    paid: "Pago",
    orderDetails: "Seu pedido será enviado para a cozinha assim que você clicar em enviar. Nós o notificaremos quando estiver pronto para ser retirado.",
    sendOrder: "Enviar Pedido",
    cancel: "Cancelar",
  producthelp:'Lembre-se de que se você criar um novo produto, deverá então vinculá-lo ao menu onde deseja que ele apareça',
  scanphone: "Escaneie o QR com seu telefone ou clique",
    orderNumber: "Número do pedido",
    preparingOrder: "Estamos preparando seu pedido",
dontClose: 'Nós avisaremos com um popup nesta tela quando seu pedido estiver pronto e também enviaremos um email para o endereço que você inseriu anteriormente',
    orderReadyTitle: "Seu pedido está pronto!",
    orderReadyText: "Você pode retirar seu pedido agora.",
    welcome: "Bem-vindo",
    instructions: "Por favor, siga as instruções.",
    settings: "Configurações",
    currency: "Moeda",
    position_currency: "Posição da moeda",
    primary_color: "Cor primária",
    secondary_color: "Cor secundária",
    menu_languages: "Idiomas do menu",
    request_location: "Solicitar localização",
    request_additional_information: "Solicitar informações adicionais",
    menu_terms: "Termos do menu",
    save: "Salvar",
    success: "Sucesso!",
    business_info_updated: "Informações comerciais atualizadas com sucesso",
    error: "Erro!",
    error_updating_business_info: "Houve um erro ao atualizar as informações comerciais",
    english: "Inglês",
    spanish: "Espanhol",
    french: "Francês",
    german: "Alemão",
    italian: "Italiano",
    takeaway: "Pedidos para serem retirados quando o consumidor for alertado",
    onsite: "O pedido é recebido no local onde o código QR é escaneado",
    menu_type: "Tipo de menu",
    myMap: "Meu mapa",
    drawMap: "Desenhar mapa",
    deleteWall: "Excluir parede",
    addProduct: "Adicionar produto",
    addCategory: "Adicionar categoria",
    editCategory: "Editar categoria",
    addNewCategory: "Adicionar nova categoria",
    categoryName: "Nome da categoria",
    identifier: "Identificador",
    editProduct: "Editar produto",
    addNewProduct: "Adicionar novo produto",
    productName: "Nome do produto",
    description: "Descrição",
    productPrice: "Preço do produto",
    category: "Categoria",
    selectImage: "Selecionar imagem",
    change: "Alterar",
    remove: "Remover",
    inventory: "Inventário",
    selectCategoryToAssign: "Selecionar categoria para atribuir",
    assignCategory: "Atribuir categoria",
    noCategory: "Sem categoria",
    pleaseSelectCategoryAndProduct: "Por favor, selecione uma categoria e pelo menos um produto!",
    productNotFound: "Produto não encontrado!",
    pleaseFillOutAllFields: "Por favor, preencha todos os campos!",
    errorUploadingImage: "Erro ao carregar imagem",
    categoryNotFound: "Categoria não encontrada!",
    areYouSure: "Você tem certeza?",
    doYouWantToSaveAllChanges: "Deseja salvar todas as alterações?",
    yesSaveIt: "Sim, salve!",
    saved: "Salvo!",
    yourChangesHaveBeenSaved: "Suas alterações foram salvas.",
    doYouWantToDeleteThisProduct: "Deseja excluir este produto?",
    yesDeleteIt: "Sim, exclua!",
    deleted: "Excluído!",
    yourProductHasBeenDeleted: "Seu produto foi excluído.",
    doYouWantToDeleteThisCategory: "Deseja excluir esta categoria?",
    yourCategoryHasBeenDeleted: "Sua categoria foi excluída.",
    stablishments: "Estabelecimentos",
    menus: "Menus",
    qrCodes: "Códigos QR",
    addQr: "Adicionar QR",
    saveAll: "Salvar tudo",
    addNewQr: "Adicionar novo QR",
    qrName: "Nome do QR",
    viewMenu: "Ver menu",
    youWontBeAbleToRevertThis: "Você não poderá reverter isso!",
    yourQrHasBeenDeleted: "Seu QR foi excluído.",
    emailPlaceholder: "Email...",
    passwordPlaceholder: "Senha...",
    login: "Entrar",
    incorrectUserOrPassword: "Usuário ou senha incorretos",
    loginError: "Erro ao entrar",
    addNewStablishment: "Adicionar novo estabelecimento",
    map: "Mapa",
    edit: "Editar",
    remove: "Remover",
    viewOrders: "Ver pedidos",
    viewOrdersMap: "Ver mapa de pedidos",
        sendToKitchen: "Enviar para a cozinha",
    sendAlert: "Enviar alerta",
    finish: "Finalizar",
    createdAgo: "Criado há",
    inKitchenFor: "Na cozinha há",
    qrCode: "Código QR",
    email: "Email",
    phone: "Telefone",
    comments: "Comentários",
    alertSentAgo: "Alerta enviado há",
    alerting: "Alertando",
    details: "Detalhes",
    backToPending: "Voltar para pendente",
    backToOngoing: "Voltar para em andamento",
    cancelOrder: "Cancelar pedido",
       enableSoundAlerts: "Ativar alertas sonoros",
    newOrders: "Novos Pedidos",
    inKitchen: "Na Cozinha",
    alertingCustomer: "Alertando o Cliente",
    closedOrders: "Pedidos Fechados",
    cancelledOrders: "Pedidos Cancelados",
    translation: "Tradução",
    products: "Produtos",
      address: "Endereço",
  enterAddress: "Digite seu endereço",
  logo: "Logo",
    restaurantName: 'Nome do restaurante...',
  logoTitle: 'Logo',
    termsRequired: 'Você deve aceitar os termos e condições.',
    accountconfirm:"Sua conta foi confirmada, agora você pode fazer login.",
  confirmEmail: 'Confirme seu e-mail',
  confirmEmailText: 'Por favor, clique no link que enviamos para o seu e-mail para confirmar sua conta.',
  register: 'Registrar',
  coverImage: "Imagem de capa",
    avgOrderValue: 'Valor médio do pedido',
  sales: 'Vendas',
  topCustomers: 'Principais Clientes',
  topOrderedProducts: 'Produtos Mais Pedidos',
  password: 'Senha...',
  termsAndConditions: 'Eu concordo com os',
  termsLinkText: 'termos e condições',
  getStarted: 'Começar',
  createApp: 'Criamos o aplicativo',
  createAppDesc: 'Criamos o aplicativo para o seu restaurante.',
  addProducts: 'Você adiciona produtos',
  addProductsDesc: 'Você adiciona os produtos ao menu e configura para gerar os códigos QR.',
  customersEnjoy: 'Seus clientes aproveitam',
  customersEnjoyDesc: 'Seus clientes escaneiam o código QR e fazem seus pedidos.',
  restaurantNameExists: 'O nome do restaurante já existe.',
  errorCheckingRestaurantName: 'Erro ao verificar o nome do restaurante.',
  nameRequired: 'obrigatório.',
  restaurantNameRequired: 'Nome do restaurante é obrigatório.',
  emailRequired: 'Email é obrigatório.',
  emailInvalid: 'Formato de email não é válido.',
  passwordRequired: 'Senha é obrigatória.',
  userExists: 'Usuário já existe.',
      "nifLabel": "N.I.F",
    "invoiceTitle": "Fatura Simplificada",
    "dateLabel": "Data",
    "invoiceNumberLabel": "No",
    "tableLabel": "Mesa",
    "guestLabel": "Comensais",
    "waiterLabel": "Garçom",
    "totalWithoutTaxLabel": "Total sem IVA",
    "taxLabel": "IVA",
    "totalLabel": "Total",
    "tipLabel": "GORJETA / Propina",
    "thankYouMessage": "IVA Incluído - Obrigado pela visita",
    "description": "Descrição",
    "quantity": "Qtd.",
    "price": "Preço",
    "total": "Total"
}