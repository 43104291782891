<template>
  <md-card>
    <md-card-header>
      <div class="card-icon p-1 bg-danger">
        <md-icon class="">edit_note</md-icon>
      </div>
      <h4 class="title mt-2">Rutinas</h4>
 
    </md-card-header>
    <md-card-content>

      <div class="p-2 bg-white">
        <md-button 
          class="logoplanning border-primary md-white p-3 customborder" 
          @click="open = true" 
          v-if="!open && !id"
        >
          <span class="text-primary"> + Agregar una rutina</span>
        </md-button>


   <div class="loading-container mr-2 w-100 text-center p-4" v-if="isLoading">
            <md-progress-spinner 
              :md-diameter="80" 
              md-mode="indeterminate" 
              class="text-red bg-red md-red">
            </md-progress-spinner>
          </div>


          <div class="w-100 text-center customborder" v-if="plannings.length == 0 && !isLoading">
             <h5 class="text-secondary p-4">{{$t('nothingtosee')}}</h5>
          </div>

        <div v-if="id && plannings">
          <PlanningView :item="plannings" @refresh="refresh()" />
        </div>

        <div 
          class="mt-3" 
          v-if="!id && plannings && !open" 
          v-for="(items, index) in planningsByGoal" 
          :key="index"
        >
          <h5>{{$t(index)}} ({{planningsByGoal[index].length}})</h5>
          <PlanningSlide 
            :plannings="items" 
            :onlyme="onlyme"
            @goTo="goTo($event)" 
            @refresh="refresh()" 
          />
        </div>

        <!-- Cargar formulario de edición solo si hay un planning seleccionado -->
        <CreatePlanning 
          v-if="!id && open" 
          @cancel="open = false" 
          @formSubmitted="refresh()" 
        />
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
import { 
  getAllPlanningsAction, 
  deletePlanningAction, 
  getPlanningAction, 
  getAllUserPlanningAction 
} from '@/pages/Training/actions';

import CreatePlanning from '@/pages/Training/CreatePlanning.vue';
import PlanningView from '@/pages/Training/PlanningView.vue';
import PlanningSlide from '@/pages/Training/PlanningSlide.vue';
import Swal from 'sweetalert2';

export default {
  props:{ 
    type: String
  },
  data() {
    return {
      open: false,
      onlyme: false,
      plannings: false,
      currentSort: 'name',
      isLoading:false,
      currentSortOrder: 'asc',
      selectedPlanning: null,
      id: false,
      planningsByGoal: [],
    };
  },
  watch: {
    $route(to, from) {
      this.start();
    },
  },
  components: {
    CreatePlanning,
    PlanningView,
    PlanningSlide,
  },
  async created() {
    this.id = this.$router.currentRoute.params?.id || null;
    this.start();
  },
  methods: {
    goTo(item) {
      this.$router.push({
        name: 'PlanningView',
        params: { id: item._id, objeto: { id: item._id, planning: item } },
      });
      this.id = item._id;
      this.start();
    },
    refresh() {
      this.open = false;
      this.start();
    },
    groupPlanningsByGoal() {
      const profile = JSON.parse(localStorage.getItem('profile'));
      this.planningsByGoal = this.plannings.reduce((acc, planning) => {
        
        const { goal } = planning;
        if (!acc[goal]) acc[goal] = [];

        if(this.type == 'explore'){
          if(planning.user && planning.user != profile._id){
            acc[goal].push(planning);
          }
        }

        if(this.type == 'onlyme'){
          if(planning.user && planning.user == profile._id){
            acc[goal].push(planning);
          }
        }

        if(!this.type){
              acc[goal].push(planning);
        }
        

        return acc;

      }, {});
    },
    async refreshComponent() {
      if (this.$router.currentRoute.params && !this.$router.currentRoute.params.id) {
        this.id = null;
        const response = await getAllPlanningsAction();
        if (response.ok) {
          this.plannings = response.plannings;
        } else {
          console.error('Failed to fetch plannings: ', response.message);
        }
      }
    },
    async start() {
      this.plannings = false;
      try {
        let response;
        if (this.id) {
          this.isLoading = true;
          response = await getPlanningAction(this.id);
          if (response.ok) {
            this.plannings = response.planning;
          } else {
            console.error('Failed to fetch plannings: ', response.message);
          }
          this.isLoading = false;
        } else {
          this.isLoading = true;
          response = await getAllPlanningsAction();
          if (response.ok) {
            if(this.$route.name == 'CreatedByMe'){
              let profile = JSON.parse(localStorage.getItem('profile'));
              this.plannings = response.plannings.filter(planning => planning.user == profile._id);
            }else{
              this.plannings = response.plannings;
            }
            this.groupPlanningsByGoal();
          this.isLoading = false;
          } else {
            console.error('Failed to fetch plannings: ', response.message);
          }
        }
      } catch (error) {
        console.error('Error fetching plannings: ', error);
      }
    },
  },
};
</script>

<style scoped>
.planningimage{
  width: 400px !important;
  height: 200px !important;
  border-radius:8px !important;
}
.cursor-pointer{
  cursor:pointer!important;
}
</style>
<style scoped>
.calendar-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  
}

@media (max-width: 980px) {
  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1px;
  }
}

.calendar-day-name {
  text-align: center;
  font-weight: bold;
}
.md-button.md-sm .md-ripple {
    font-size: 11px;
    padding: 4px !important;
}
.weekcell{
   display: grid;
  grid-template-columns: repeat(7, 1fr);
  
}
.daycell{
  
    border:0;
    border-radius: 4px;
}
.calendar-day {
  background-color: #fff; /* Color de fondo por defecto */
}

.past-day {
  background-color: #f0f0f0; /* Color gris para los días pasados */
}
.itemactivity{
    position: absolute;
    top: -6px;
    right: -15px;
    font-size: 8px;
    border-radius: 50%;
    border: 0;
    color: #11b5c9;
    padding: 0px 9px;
    font-weight: 900;
}
.itemactivity i{
    font-size: 10px !important; 
}
.opaco{
      background-color: #d8d8d8 !important;   
    text-decoration-color: #858585;
}

.md-card-content ul {
  list-style-type: none;
  padding: 0;
}

.md-card-content ul li {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}
</style>


<style scoped>
.calendar-grid-phone {
  display: flex;
  flex-wrap: nowrap; /* Para mantener todas las tarjetas en una sola línea */
  overflow-x: auto; /* Para permitir el scroll horizontal */
  -webkit-overflow-scrolling: touch; /* Para una mejor experiencia de scroll en dispositivos táctiles */
}

.calendar-day-phone {
    margin-top: 4px;
  flex: 0 0 auto; /* Evita que las tarjetas se encogen y las mantiene en su tamaño original */
  margin-right: 10px; /* Espaciado entre las tarjetas */
  width: 90%; /* Ajusta el tamaño de la tarjeta, puedes cambiarlo según tu necesidad */
}
@media (min-width: 768px) {

.calendar-day-phone {
  width: 100%; /* Ajusta el tamaño de la tarjeta, puedes cambiarlo según tu necesidad */
}

.calendar-grid-phone {
  display: block;
  flex-wrap: nowrap; /* Para mantener todas las tarjetas en una sola línea */
  overflow-x: auto; /* Para permitir el scroll horizontal */
  -webkit-overflow-scrolling: touch; /* Para una mejor experiencia de scroll en dispositivos táctiles */
}

}


.calendar-day-phone md-card {
  width: 100%; /* Asegura que el md-card ocupe todo el ancho del contenedor */
}

/* Estilos para organizar las actividades como un calendario */
.calendar-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.calendar-day {
}

.card-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}

.title {
  margin-left: 10px;
}


.md-card-content ul {
  list-style-type: none;
  padding: 0;
}

.md-card-content ul li {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

.calendar-grid .md-card {
  min-height: 200px;
}

p {
  text-align: center;
  color: #999;
}

.error {
  color: red;
  font-size: 12px;
}
.cursor-pointer{
  cursor:pointer;
}
</style>
