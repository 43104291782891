<template>

  <div class="w-100 " v-if="exercise" ref="fieldContainer">
      
    <div
      v-for="(field, index) in exerciseFields"
      :key="index"
      class="text-center mx-1 float-left"
    >

      <div class="mb-2 customback" v-if="selectedField === field.model">
        <div class="inputpopup">
          <div class="mb-2">
            <h4 class="text-secondary mt-1">{{ field.label }}</h4>
            <small>Dejalo vacio si no quieres mostrar esto a tu alumno en su rutina</small>
          </div>

          <div v-if="field.type === 'select' && field.max">
            <select
              v-model="exercise[field.model]"
              class="custominput text-center"
              @click.stop
            >
                <option :value="null">{{$t('vacio')}}</option>
              <option v-for="n in field.max" :key="n" :value="n">{{ n }}</option>
            </select>
          </div>
          <div v-if="field.type === 'select' && field.options">
            <select
              v-model="exercise[field.model]"
              class="custominput text-center"
              @click.stop
            >
                <option :value="null">{{$t('vacio')}}</option>
              <option v-for="n in field.options" :key="n" :value="n">{{ n }}</option>
            </select>
            <h4 class="d-inline">{{ field.unit }}</h4>
          </div>

          <div v-if="field.model === 'weight'" class="p-2">
            <div v-for="(item, index) in getSets(exercise)" :key="item" class="float-left m-1">
              <h5 class="d-inline">Serie {{ index + 1 }}.</h5>

              <select
                v-model="exercise[field.model][index]"
                class="custominput text-center"
                @change="updateWeight(index)"
                @click.stop
              >
                <option :value="null">{{$t('vacio')}}</option>
                <option v-for="n in field.options" :key="n" :value="n">{{ n }}</option>
              </select>
              <h4 class="d-inline">{{ field.unit }}</h4>
            </div>
          </div>

          <div v-if="field.model === 'duration'" class="p-2">

            <div v-for="(item, index) in getSets(exercise)" :key="item" class="float-left m-1">
              <h5 class="d-inline">Duración {{ index + 1 }}.</h5>

              <select
                v-model="exercise[field.model][index]"
                class="custominput text-center"
                @change="updateDuration(index)"
                @click.stop
              >
                <option :value="null">{{$t('vacio')}}</option>
                      <option v-for="n in field.max" :key="n" :value="n">{{ n }}</option>
              </select>
              <h4 class="d-inline">{{ field.unit }} </h4>
            </div>
          </div>

          <div v-if="field.model === 'rest'" class="p-2">
            <div v-for="(item, index) in getSets(exercise)" :key="item" class="float-left m-1">
              <h5 class="d-inline">Descanso {{ index + 1 }}.</h5>

              <select
                v-model="exercise[field.model][index]"
                class="custominput text-center"
                @change="updateWeight(index)"
                @click.stop
              >
                <option :value="null">{{$t('vacio')}}</option>
                <option v-for="n in field.options" :key="n" :value="n">{{ n }}</option>
              </select>
              <h4 class="d-inline">{{ field.unit }}</h4>
            </div>
          </div>

          <div v-if="field.model === 'repetitions'" class="p-2">

            <div v-for="(item, index) in getSets(exercise)" :key="item" class="float-left mt-1">
              <h5 class="d-inline">Serie {{ item }}.</h5>

              <select
                v-model="exercise.repetitions[index]"
                class="custominput text-center"
                @change="updateRepetitions(index)"
                @click.stop
              >
                <option :value="null">{{$t('vacio')}}</option>
                <option v-for="n in field.max" :key="n" :value="n">{{ n }}</option>
              </select>
              <h4 class="d-inline">{{ field.unit }}</h4>
            </div>
          </div>
        </div>
      </div>

      <button
        :class="visibility(field) ? 'btn-secondary text-black':'text-white'"
        class="btn mb-2 custominputbox"
        @click.stop="selectField(field.model)"
        v-if="source == 'custom' && (attributes || visibility(field))"
      >
        <small class="text-secondary">{{ field.label }}: </small>
        <small v-if="!mutiplefields.includes(field.model)">

          <small v-if="exercise[field.model]">{{ exercise[field.model] }}

           <span v-if="exercise[field.model] > 0">{{ field.unit }}</span>
           <span v-else><md-icon>visibility_off</md-icon></span>

          </small>
          <small v-else><md-icon>visibility_off</md-icon></small>
        </small>
        <small v-else>
          <small v-if="allElementsEqual(exercise[field.model])">


          <span v-if="exercise[field.model][0] > 0">{{ exercise[field.model][0] }} {{ field.unit }}
          </span>
          <span v-else><md-icon>visibility_off</md-icon>
          </span>

          </small>
          <small v-else>
            <small v-if="exercise['sets'] > 0">{{exercise[field.model].join(',')}} {{ field.unit }}</small>
                <small v-else><md-icon>visibility_off</md-icon></small>
          </small>
        </small>
      </button>

      <div
        v-if="(exercise[field.model]  && (Array.isArray(exercise[field.model]) && exercise[field.model].length > 0) ) && source !== 'custom'"

        class="btn mb-2 border"
        @click.stop="selectField(field.model)"
      >
        <small class="text-secondary">{{ field.label }}: </small>
          <small v-if="!mutiplefields.includes(field.model)">

          <small v-if="exercise[field.model]">{{ exercise[field.model] }}

           <span v-if="exercise[field.model] > 0">{{ field.unit }}</span>

          </small>
        </small>
        <div v-else>
          <small v-if="allElementsEqual(exercise[field.model])">


          <span v-if="exercise[field.model][0] > 0">{{ exercise[field.model][0] }} {{ field.unit }}</span>
          <span v-else><md-icon>visibility_off</md-icon></span>

          </small>
          <small v-else>
            <small v-if="exercise['sets'] > 0">{{exercise[field.model].join(',')}}</small>
          </small>
        </div>
      </div>

    </div>
    <div class="w-100 float-left" v-if="source=='custom'">
    <span v-if="!attributes" class="cursor-pointer w-100 p-2 mt-2 badge badge-primary text-white" @click="attributes=true">+ atributos</span>
    <span v-if="attributes" class="cursor-pointer w-100 p-2 mt-2 badge badge-danger text-white" @click="attributes=false">x cerrar</span>
    </div>
  </div>
</template>

<script>
import { updateTrainingExercisesAction } from "@/pages/Training/actions";
import Swal from "sweetalert2";

export default {
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      }
    }
  },
  data() {
    return {
      attributes:false,
      exercise: false,
      edit: false,
      selectedField: null,
      exerciseFields: [
        { label: "Series", model: "sets", type: "select", max: 50, min: 1, unit: "" },
        { label: "Repeticiones", model: "repetitions", type: "selectcustom", max: 400, min: 1, unit: "" },
        { label: "Peso", model: "weight", type: "selectcustom", options: [
    1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10,
  10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20,
  20.5, 21, 21.5, 22, 22.5, 23, 23.5, 24, 24.5, 25, 25.5, 26, 26.5, 27, 27.5, 28, 28.5, 29, 29.5, 30,
  30.5, 31, 31.5, 32, 32.5, 33, 33.5, 34, 34.5, 35, 35.5, 36, 36.5, 37, 37.5, 38, 38.5, 39, 39.5, 40,
  40.5, 41, 41.5, 42, 42.5, 43, 43.5, 44, 44.5, 45, 45.5, 46, 46.5, 47, 47.5, 48, 48.5, 49, 49.5, 50,
  50.5, 51, 51.5, 52, 52.5, 53, 53.5, 54, 54.5, 55, 55.5, 56, 56.5, 57, 57.5, 58, 58.5, 59, 59.5, 60,
  60.5, 61, 61.5, 62, 62.5, 63, 63.5, 64, 64.5, 65, 65.5, 66, 66.5, 67, 67.5, 68, 68.5, 69, 69.5, 70,
  70.5, 71, 71.5, 72, 72.5, 73, 73.5, 74, 74.5, 75, 75.5, 76, 76.5, 77, 77.5, 78, 78.5, 79, 79.5, 80,
  80.5, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
  101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
  121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140,
  141, 142, 143, 144, 145, 146, 147, 148, 149, 150
], unit: "kg" },
        { label: "Distancia", model: "distance", type: "select", options: [10, 20, 30, 50, 100, 150, 200, 500, 800, 1000, 2000, 5000], unit: "m" },
        { label: "Duración", model: "duration", type: "selectcustom", max: 50, min: 1, unit: "min" },
        { label: "Descanso", model: "rest", type: "selectcustom", options: [10, 20, 30, 60, 120, 180], unit: "seg" },
        { label: "Velocidad", model: "speed", type: "select", options: [5, 10, 20, 30, 40, 50, 60], min: 1, unit: "km/h" }
      ],
      mutiplefields:['repetitions','weight','duration','rest']
    };
  },
  props: {
    myExercise: Object,
    source: String,
    status: String
  },
  created() {

    const exercise = this.myExercise;

    if(!Array.isArray(exercise.rest)){
      if(!exercise.rest){
        exercise.rest = []
      }else{
        exercise.rest = [exercise.rest]
      }
    }
    if(!Array.isArray(exercise.duration)){
      if(!exercise.duration){
        exercise.duration = []
      }else{
        exercise.duration = [exercise.duration]
      }
    }

    this.exercise = {
      ...exercise,
      repetitions: Array.isArray(exercise.repetitions) ? exercise.repetitions : [],
      weight: Array.isArray(exercise.weight) ? exercise.weight : [],
      duration: Array.isArray(exercise.duration) ? exercise.duration : [],
      rest: Array.isArray(exercise.rest) ? exercise.rest : []
    };


  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {  
    visibility(field){
        if(!this.mutiplefields.includes(field.model)) {
          if(!this.exercise[field.model]){
            return false;
          }else{

            if(this.allElementsEqual(this.exercise[field.model])){
              if(!this.exercise[field.model][0]){
                return true;
              }else{
               if(this.exercise['sets'] > 0){
                return false;
               }
              }
            }
          }
        }else{
        
          if((this.exercise[field.model] && this.exercise[field.model] > 0)  && this.source !== 'custom'){
            return true;
          }else{
            if(this.allElementsEqual(this.exercise[field.model]) && !this.exercise[field.model][0]){
              return false;
            }
          }
        }
        return true;
    },
    getSets(exercise){
       if(!exercise['sets']){
        return 1
       }else{
        return exercise['sets']
       }
    },
    selectField(field) {
      if(this.source !== 'custom'){
        return;
      }
      if (this.selectedField !== field) {
        this.selectedField = field;
        this.exerciseFields.forEach((field) => {
        
        });
      }
    },
    updateRepetitions(index) {
      if (index === 0) {
        const selectedValue = this.exercise.repetitions[0];
        for(let i = 0; i < this.exercise.sets; i++){
           this.exercise.repetitions[i] = selectedValue;
        }
      }
    },
    allElementsEqual(arr) {
      if(!Array.isArray(arr)){
        return true;
      }

      if (arr.length === 0) return true; // Devuelve true si el array está vacío
      return arr.every(element => element === arr[0]);
    },
    updateWeight(index) {
      if (index === 0) {
        const selectedValue = this.exercise.weight[0];
        for(let i = 0; i < this.exercise.sets; i++){
           this.exercise.weight[i] = selectedValue;
        }
      }
    },
    updateRest(index) {
      if (index === 0) {
        const selectedValue = this.exercise.rest[0];
        for(let i = 0; i < this.exercise.sets; i++){
           this.exercise.rest[i] = selectedValue;
        }
      }
    },
    updateDuration(index) {
      if (index === 0) {
        const selectedValue = this.exercise.duration[0];
        for(let i = 0; i < this.exercise.sets; i++){
           this.exercise.duration[i] = selectedValue;
        }
      }
    },
    handleClickOutside(event) {
      const container = this.$refs.fieldContainer;
      if (this.selectedField) {
        this.selectedField = null;
        this.updateTrainingExercises();
      }
    },
    async updateTrainingExercises() {

        let slice =  this.exercise.repetitions.slice(0, (this.exercise.sets));
        this.exercise.repetitions = slice

         slice =  this.exercise.weight.slice(0, (this.exercise.sets));
        this.exercise.weight = slice

         slice =  this.exercise.duration.slice(0, (this.exercise.sets));
        this.exercise.duration = slice

         slice =  this.exercise.rest.slice(0, (this.exercise.sets));
        this.exercise.rest = slice

      this.$emit("updateTraining", this.exercise);
    }
  }
};
</script>


<style scoped>
.custominputbox {
  background: #e7f4ff;
  border-radius: 4px;
  padding: 4px;
}
.custominput {
    width: 79px !important;
    border: 0;
    border-radius: 4px;
    background: #b9b9b9;
    font-size: 22px;
    margin: 4px;
}
.custominputboxanonymous {
    border-radius: 4px;
    padding: 2px;
    border: 1px solid #ebebeb;
}
.inputpopup {
    width: 391px;
    background: #fff;
    border: 1px solid #e3e0e0;
    border-radius: 5px;
    padding: 13px;
    text-align: center;
    margin-left: -196px;
    margin-top: 75px;
    float:left;
}
.customback{
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
    padding-left: 50%;
  left: 0;
  background: #00000059;
  z-index: 99999;
}
</style>
