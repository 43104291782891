<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100" >
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-rose">
          <div class="card-icon">
            <md-icon>restaurant</md-icon>
          </div>
          <h4 class="title mb-4">{{$t('orders')}}</h4>

        
        </md-card-header>
  <!-- Filtro de estado -->
          <div class="container">
              <div class="row mt-4 p-3">
                <div class="col-md-3 col-sm-12">   
                  <md-field>
                    <label>{{$t('filter')}}</label>
                    <md-select v-model="selectedStatus" @md-selected="selectedOrders = []">
                      <md-option value="">{{$t('all')}}</md-option>
                      <md-option value="cancelled">{{$t('cancelledOrders')}}</md-option>
                      <md-option value="close">{{$t('closedOrders')}}</md-option>
                      <md-option value="ongoing">{{$t('inKitchen')}}</md-option>
                      <md-option value="done">{{$t('alertingCustomer')}}</md-option>
                      <md-option value="payment">{{$t('paymentPending')}}</md-option>
                    </md-select>
                  </md-field>
                </div>
                <div class="col-md-3 col-sm-12"> 
                    <!-- Botones de Delete y Select All -->
                    <md-button 
                      class="md-danger md-warn mt-4 w-100" 
                      @click="deleteSelectedOrders"
                      :disabled="selectedOrders.length === 0"
                    >
                      {{$t('delete')}}
                    </md-button>
                </div>
                <div class="col-md-3 col-sm-12"> 
                    <md-button 
                      class="md-primary mt-4 w-100" 
                      @click="toggleSelectAll"
                    >
                      {{ areAllSelected ? $t('deselectAll') : $t('selectAll') }}
                    </md-button>
                </div>
                <div class="col-md-3 col-sm-12" v-if="false"> 
                    <md-button 
                      class="md-success md-warn mt-4 w-100" 
                      :disabled="selectedOrders.length === 0"
                      @click="createReceipt()"
                    >
                      <md-icon class="material-icons text-white">receipt_long</md-icon> 
                      {{$t('generateReceipt')}}
                    </md-button>
                </div>

              </div>
          </div>
        <md-card-content>
          <div class="loading-container mr-2" v-if="isLoading">
            <md-progress-spinner 
              :md-diameter="80" 
              md-mode="indeterminate" 
              class="text-red bg-red md-red">
            </md-progress-spinner>
          </div>

          <md-table :class="notShowMobile()" v-model="filteredOrders" table-header-color="green">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Select">
                <md-checkbox v-model="selectedOrders" :value="item._id"></md-checkbox>
              </md-table-cell>

              <md-table-cell md-label="Status">
                <span class="badge" :class="getClass(item.status)">
                  {{ getLabel(item.status) }}
                </span>
              </md-table-cell>

              <md-table-cell md-label="Total">
                <span class="badge-success badge">
                  {{ item.totalPrice }} 
                  <span v-if="item.currency">{{ item.currency }} 
                    <small class="ml-1" v-if="item.payed">{{$t('yespaid')}}</small>
                  </span>
                </span>
              </md-table-cell>

              <md-table-cell md-label="Buyer Email">  {{ item.buyerName }} {{ item.buyerEmail }} </md-table-cell>

              <md-table-cell :md-label="$t('stablishments')">
                <span v-if="item.stablishment"><md-icon>business</md-icon> {{ item.stablishment.name }}</span>
              </md-table-cell>
              <md-table-cell :md-label="$t('address')">
                <span v-if=" item.shippingAddress">{{ item.shippingAddress.number }}, {{ item.shippingAddress.street }}, {{ item.shippingAddress.city }} {{ item.shippingAddress.codepostal }} {{ item.shippingAddress.province }} {{ item.shippingAddress.country }}</span>
              </md-table-cell>
              <md-table-cell :md-label="'TAX ID'">
                <span v-if="item.shippingAddress">{{ item.shippingAddress.taxid }}</span>
              </md-table-cell>

              <md-table-cell md-label="Phone">
                <small>{{ item.buyerPhone }}</small>
              </md-table-cell>
              <md-table-cell md-label="Qr">
                <small v-if="item.qr">{{item.qr.name}}</small>
              </md-table-cell>


              <md-table-cell md-label="Comments">
                <small class="text-secondary">{{ item.comments }}</small>
              </md-table-cell>
              <md-table-cell md-label="Products">
                <div v-for="product in item.products" :key="product._id">
                  <span class="badge-success badge">{{ product.product.price }} 
                    <span v-if="item.currency">{{ item.currency }}</span>
                  </span> x 
                  <span class="badge badge-primary">{{ product.quantity }}</span>
                  <span>{{ product.product.name }}</span>
                </div>
              </md-table-cell>

              <md-table-cell md-label="Date" v-if="item.createdAt">{{ localDate(item.createdAt) }}</md-table-cell>
            </md-table-row>
          </md-table>
          <div :class="showMobile()">
            <div v-for="item in filteredOrders" class="border-bottom border-1 p-2 mb-3 card">

              <div  class="text-left">
                <md-checkbox v-model="selectedOrders" :value="item._id"></md-checkbox>
                <span class="badge float-right" :class="getClass(item.status)">
                  {{ getLabel(item.status) }}
                </span>
                <h5 v-if="item.stablishment && item.qr"><md-icon>business</md-icon>  {{ item.stablishment.name }} - {{item.qr.name}}</h5>
                <div> <strong> {{ item.buyerEmail }}</strong>  </div>
                <div>  <small>{{ item.buyerPhone }}</small>  </div>
             
                 <div> <small class="text-secondary">"{{ item.comments }}"</small>  </div>
              </div>
              <div  class="text-left  p-2">
                <div v-for="product in item.products" :key="product._id">
                  
                  <span> {{ product.product.name }}</span> <span class="badge-success badge">{{ product.product.price }} 
                    <span v-if="item.currency">{{ item.currency }}</span>
                  </span> x 
                  <span class="">{{ product.quantity }}</span>
                </div>

                <h4 class="mt-3 ml-2">
                  {{$t('total')}}: {{ item.totalPrice }} 
                  <span v-if="item.currency">{{ item.currency }} 
                     <span class="badge badge-success cursor-pointer" 
                      v-if="item.payed"> {{$t('yespaid')}} 
                 </span>
               </span>
                </h4>    
               </div>
                <small>{{ localDate(item.createdAt) }}</small>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { getAllOrdersAction, deleteAction, createReceiptAction } from "@/pages/Orders/actions"; // Asegúrate de tener la acción deleteAction importada
import { format } from 'date-fns';


export default {
 
  created() {
    this.getAll();
  },
  data() {
    return {
      isLoading: true,
      orderSelected: false,
      orders: [], // Todas las órdenes recibidas del backend
      selectedOrders: [], // Mantiene los IDs de las órdenes seleccionadas
      selectedStatus: '', // Estado seleccionado para filtrar
    };
  },
  computed: {
    // Computed para filtrar las órdenes según el estado seleccionado
    filteredOrders() {
      if (this.selectedStatus) {
        return this.orders.filter(order => order.status === this.selectedStatus);
      }
      return this.orders; // Si no hay filtro, muestra todas las órdenes
    },
    // Computed para verificar si todas las órdenes visibles están seleccionadas
    areAllSelected() {
      return this.filteredOrders.length > 0 && this.filteredOrders.every(order => this.selectedOrders.includes(order._id));
    }
  },
  methods: {
    showMobile(){
      return 'd-sm-none';
    },
    notShowMobile(){
      return 'd-none d-sm-block';
    },
    localDate(utcDate) {
      const date = new Date(utcDate);
      return format(date, 'PPpp');  // Formato de fecha y hora según la hora local del navegador
    },
    getClass(status) {
      if (status == 'cancelled') {
        return "badge-danger";
      }
      if (status == 'close') {
        return "badge-success";
      }
      if (status == 'ongoing' || status == 'done') {
        return "badge-warning";
      }
      if (status == 'payment') {
        return "badge-danger";
      }
      return "badge-primary";
    },
    getLabel(status) {
      if (status == 'cancelled') {
        return this.$t('cancelledOrders');
      }
      if (status == 'close') {
        return this.$t('closedOrders');
      }
      if (status == 'ongoing') {
        return this.$t('inKitchen');
      }
      if (status == 'done') {
        return this.$t('alertingCustomer');
      }
      if (status == 'payment') {
        return this.$t('paymentPending');
      }
      return this.$t('newOrders');
    },
    async getAll() {
      try {
        const result = await getAllOrdersAction();
        this.orders = result.orders;
        this.isLoading = false;
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
    // Método para eliminar órdenes seleccionadas
    async deleteSelectedOrders() {
      if (this.selectedOrders.length === 0) return;

      Swal.fire({
        title: this.$t('areYouSure'),
        text: this.$t('youWontBeAbleToRevertThis'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('yesDeleteIt'),
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await deleteAction(this.selectedOrders); // Aquí llamamos a la acción deleteAction
            this.getAll(); // Refrescar lista de órdenes
            this.selectedOrders = []; // Limpiar selección
            Swal.fire(this.$t('deleted'), '', 'success');
          } catch (error) {
            Swal.fire(this.$t('error'), error.message, 'error');
          }
        }
      });
    },
    // Método para seleccionar o deseleccionar todas las órdenes visibles
    toggleSelectAll() {
      if (this.areAllSelected) {
        // Si todas están seleccionadas, deseleccionamos todo
        this.selectedOrders = [];
      } else {
        // Si no, seleccionamos todas las órdenes filtradas
        this.selectedOrders = this.filteredOrders.map(order => order._id);
      }
    },
    async createReceipt() {
      if (this.selectedOrders.length === 0) return;

      Swal.fire({
        title: this.$t('areYouSure'),
        text: this.$t('thisActionCannotBeUndone'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('yesCreateIt'),
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {

            await createReceiptAction({orders: this.selectedOrders});

            this.getAll(); // Refrescar lista de órdenes
            this.selectedOrders = []; // Limpiar selección
            Swal.fire(this.$t('receiptCreated'), '', 'success');
          } catch (error) {
            Swal.fire(this.$t('error'), error.message, 'error');
          }
        }
      });
    }
  },
};
</script>




<style scoped>
.menu {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.menu-actions {
  display: flex;
  justify-content: flex-end;
}
.product {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 5px;
}
.product span {
  flex-grow: 1;
}
</style>
