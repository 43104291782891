<template>
 <div v-if="canSee()"> 
     <CreatePlanning
      v-if="openedit"
      :key="item._id" 
      :preloadedData="item"
      @formSubmitted="onPlanningUpdated"
      @cancel="openedit=false"
    />
        <div class="banner card w-100 bg-white"  v-if="!openedit">  
          <div v-if="!videopen"  class="bannerplanning logoresto p-3" :style="'background:url('+item.image+')'">
              <div  v-if="isGym()" class="float-right">
                <small v-if="item.private == 'private'" class="text-white"><md-icon class="text-white">lock</md-icon><span class="p-1">Privada</span></small>
                <small v-else class="text-white"><md-icon class="text-white">lock_open</md-icon><span class="p-1">Pública</span></small>
              </div>
              <md-icon class="float-left  circlesuccess" v-if="isStarted()">circle</md-icon>
              <div class="text-white "><h4 >{{ item.name }} - {{ $t(item.goal) }} </h4>
              <span class="badge badge-danger" v-if="item.myPlanning && item.myPlanning.status == 'pending'">Asingada a mí</span>
              <span class="badge badge-danger" v-if="item.myPlanning && item.myPlanning.status == 'done'">Rutina Finalizada</span>
              <small class="badge" v-if="item.myPlanning && item.myPlanning.status == 'done'">Fecha de finalización: {{localDate(item.myPlanning.endAt)}}</small>
              <span class="badge badge-success" v-if="item.myPlanning && item.myPlanning.status == 'inprogress' && item.myPlanning.startedAt">
               <md-icon class="text-white">directions_run</md-icon> Fecha de inicio: {{localDate(item.myPlanning.startedAt)}}
              </span>
              <div id="playnow" class="playvideo" v-if="item.videoUrl" @click="videopen = true;">
                <md-icon>play_circle</md-icon>
              </div>
              </div>
          </div>  
           <div class="video-container" v-if="videopen">
              <iframe
                id="iframeyoutube"
                :src="getEmbedUrl(item.videoUrl, 1)"
                :title="item.name"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen>
              </iframe>
            </div>
          
       


          <div class="p-2">

            <md-button @click="start()" class="md-button md-success w-100 " v-if="item.myPlanning && item.myPlanning.status == 'pending'">
              <md-icon>directions_run</md-icon> {{$t('getStarted')}}   <md-icon>play_arrow</md-icon>
            </md-button> 
            <md-button @click="end()" class="md-button md-danger w-100 " v-if="item.myPlanning && item.myPlanning.status == 'inprogress'">
              <md-icon>pan_tool</md-icon> Terminar todo
            </md-button> 
              
          <div class=""> 
             <div class="row border-bottom border-1 pb-3"> 
              <div class="w-50 text-center"> 
              <div><md-icon>signal_cellular_alt</md-icon></div><div> {{ item.difficulty }}</div>
              </div>
              <div class="w-50  text-center"> 
                <small  v-if="item.duration < 15">
               <div><md-icon>timer</md-icon></div>
                {{ item.duration }} días</small>
                <div v-else>
                <div><md-icon>timer</md-icon></div>
                {{ item.duration }} semanas</div>
                 <small class="text-secondary"> ({{ item.dayperweek }} días por semana)</small>
              </div>
            </div> 
         
            <md-button class="md-sm md-button md-just-icon md-primary" 
            @click="openedit=true" 
            v-if="canEdit()" >
              <md-icon>edit</md-icon>
            </md-button> 

            <md-button class="md-sm md-button  md-just-icon md-danger " 
          @click="confirmDelete(item)"
            v-if="canEdit()" >
              <md-icon>delete</md-icon>
            </md-button> 


            <md-button  
            v-if="!isOwner() && canEdit()" 
            class="ml-2 md-sm md-button md-primary " 
            @click="onlyOwnerPlease()">
             <md-icon>edit</md-icon> {{$t('edit')}} 
            </md-button>    

            

            <md-button  
            v-if="isGym() && item.source != 'custom'" 
            class="ml-2 md-sm md-button md-success" @click="duplicatePlease()">
             <md-icon>person</md-icon> Asignar a un alumno 
            </md-button>

            <md-button 
               v-if="!onlySee()" 
                class="ml-2 md-sm md-button md-primary  md-just-icon" @click="copy()">
             <md-icon>file_copy</md-icon>
            </md-button> 

              
            <md-button class="ml-2 md-sm md-button md-success " v-if="!onlySee() && isClientGym() && !item.myPlanning" @click="assignPlanning()" :disabled="choose" >
             <md-icon>edit_note</md-icon> Asignarme esta rutina
            </md-button>    
            

        

            </div>  


            <div class="md-layout p-2 card" v-if="isGym() && item.userPlannings && item.userPlannings.length">
              <strong >Rutina asignada a:</strong>
              <div v-if="!choose">
                <div v-if="item.userPlannings.length"
                  v-for="user in item.userPlannings">
                  <div class="md-layout-item w-100 float-left my-3"> 
                  <avatar v-if="user.userId" class="float-left" :logo="user.userId.avatar" :small="true"></avatar>
                  <div v-if="user.userId" class="pt-2"><span>{{ user.userId.name }}</span></div>
                  </div>
                </div>
                <div class="w-100 float-left" v-else>
                  <small>No has asignado a nadie aún</small>
                </div>
              </div>
            </div>
              <md-button class="ml-2 md-sm md-button md-success " 
                  v-if="(isGym() || isTeacher()) && item.source == 'custom' && !choose"
                   @click="choose=true">
                   <md-icon>person</md-icon> Asignar a un alumno 
               </md-button> 
                <div class="w-100 mt-1">
                    <myclientsselect :acceptbutton="true" :cancelbutton="true" @cancel="choose=false"  @result="assignPlanning($event)" v-if="choose" />
                  </div>
            
              <div class="mt-3">
                 <small class="text-secondary">{{ item.description }}</small>
              </div> 

          </div> 
        </div>  

        <div  v-if="!openedit">
            <div class="text-center md-layout">
              <div class="text-center md-layout-item md-layout-50 p-1">
                <md-button 
                class="w-100 md-button md-primary" 
                @click="open=true;openGroup=false" 
                v-if="!open && !openGroup && isOwner() && !isStarted() && ((item.myPlanning && item.myPlanning.status !== 'done') || !item.myPlanning)">
                  + crear un grupo de ejercicios
                </md-button>
              </div>
              <div class="text-center  md-layout-item md-layout-50 p-1">
                <md-button 
                class="w-100 md-button md-primary" 
                @click="open=false;openGroup=true" 
                v-if="!open && !openGroup && isOwner() && !isStarted() && ((item.myPlanning && item.myPlanning.status !== 'done') || !item.myPlanning) && !isClientGym()">
                    <md-icon class="">workspaces</md-icon> Asignar un grupo precagrado
                </md-button>
              </div>
            </div>



           <TrainingListClient  
            :myPlanning="item.myPlanning"
            v-if="!open && !openGroup && item._id" 
            :isStarted="isStarted()"
            @refresh="onPlanningUpdated()"

            :planningId="item._id" 
            :source="isOwner() ? 'custom' : 'default'" />

          <template>
            <CreateTraining class="mt-4"  
            @formSubmitted="trainingCreated($event)"
            :planningId="item._id" 
            @cancel="open=false" 
            v-if="open"/>    

            <TrainingGroup 
            :planningId="item._id"
            @refresh="onPlanningUpdated()"
            @cancel="openGroup=false"
            @formSubmitted="trainingCreated($event)"
            v-if="openGroup"/>
          </template>
      </div>
                 
  </div>
  <div v-else>
    La rutina es accesible durante el horario de la actividad 
  </div>
</template>

<script>

import { createUserPlanningAction,copyPlanningAction,updateUserPlanningAction ,deletePlanningAction } from "@/pages/Training/actions";
import Avatar from "@/components/Avatar.vue";
import Swal from 'sweetalert2';
import permissionsMixin from '@/mixins/permissionsMixin';
import CreateTraining from "@/pages/Training/CreateTraining.vue";
import TrainingListClient from "@/pages/Training/TrainingListClient.vue";
import myclientsselect from "@/pages/Users/myclientsselect.vue";
import CreatePlanning from "@/pages/Training/CreatePlanning.vue";
import TrainingGroup from "@/pages/Training/TrainingGroup.vue";


export default {
  mixins: [permissionsMixin],
  components: {
    Avatar,
    CreateTraining,
    TrainingListClient,
    CreatePlanning,
    myclientsselect,
    TrainingGroup
  },
  data() {
    return {
      open: false,
      openGroup:false,
      openedit: false,
      newTraining: false,
      choose: false,
      videopen: false, // Controla si el video está abierto
      autoplay: false, // Controla si el video se reproduce automáticamente
    };
  },
  props: {
    item:Object
  },
  methods: {
    getEmbedUrl(youtubeUrl, autoplay = false) {
      try {
        const url = new URL(youtubeUrl);
        let videoId;

        if (url.hostname === "www.youtube.com" || url.hostname === "youtube.com") {
          if (url.pathname === "/watch") {
            videoId = url.searchParams.get("v");
          } else if (url.pathname.startsWith("/embed/")) {
            videoId = url.pathname.split("/embed/")[1];
          }
        } else if (url.hostname === "youtu.be") {
          videoId = url.pathname.substring(1);
        }

        if (!videoId) throw new Error("No se pudo extraer el ID del video.");

        let embedUrl = `https://www.youtube.com/embed/${videoId}`;
        if (autoplay) {
          embedUrl += "?autoplay=1";
        }

        return embedUrl;
      } catch (error) {
        console.error("Error al procesar la URL:", error.message);
        return null;
      }
    },
    playVideo() {
      this.autoplay = true; // Activa autoplay
      this.videopen = true; // Cambia a modo video
    },
    canSee(){
      return !this.item.hide;
    },
    onlySee(){
        return (!this.isOwner() && this.item.private == 'private');
    },
    async confirmDelete(planning) {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: `You are about to delete the planning "${planning.name}". This action cannot be undone.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'Cancel'
      });

      if (result.isConfirmed) {
        this.deletePlanning(planning._id);
      }
    },
    async deletePlanning(id) {
      try {
        const response = await deletePlanningAction(id);
        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Deleted',
            text: 'Planning deleted successfully',
            timer: 2000,
            showConfirmButton: false
          });
          // Recargar la lista de plannings
            this.$router.push(`/planning`);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to delete planning: ' + response.message,
          });
        }
      } catch (error) {
        console.error('Error deleting planning:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An unexpected error occurred',
        });
      }
    },
    localDate(d){
      return this.getLocalDate(d,'PP HH:mm')
    },
    start(){
      Swal.fire({
        title: 'Comenzar el entrenamiento',
        text: 'Se registrará el dia de hoy como el inicio de tu rutina',
        icon: 'success',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('getStarted')
      }).then((result) => {
        if (result.isConfirmed) {
            this.updateUserPlanning(this.item.myPlanning._id,'inprogress');
        }
      })
    },
    end(){
      Swal.fire({
        title: this.$t('areYouSure'),
        icon: 'warning',
        text: 'El entrenamiento terminará por completo en todas sus etapas.',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('yes')
      }).then((result) => {
        if (result.isConfirmed) {
            this.updateUserPlanning(this.item.myPlanning._id,'done');
        }
      })
    },
    async updateUserPlanning(id,status){
      await updateUserPlanningAction(id,status);
       this.$emit('refresh')
    },
    duplicatePlease(){
      Swal.fire({
        title: 'Rutinas precargadas',
        text: 'Para asignar o editar rutinas precargadas primero haz una copia de la misma con el boton duplicar',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
    },
    isStarted(){
        return (this.item.myPlanning && this.item.myPlanning.status == 'inprogress')
    },
    isEnd(){
        return (this.item.myPlanning && this.item.myPlanning.status == 'done')
    },
    canEdit(){
        return (this.isOwner() && !this.openedit && this.item.source == 'custom' && !this.isEnd() && !this.isStarted());
    },
    copy(){
      if(this.isDemo()){
        return;
      }

      Swal.fire({
        title: 'Duplicar esta rutina',
        icon: 'warning',
        text: 'Vamos a duplicarla y posicionarte en la nueva rutina duplicada.',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('yes'),
      }).then((result) => {
        if (result.isConfirmed) {
            this.copyPlanning()
        }
      })

    },
    async copyPlanning(){
        const result = await copyPlanningAction(this.item._id);
        if(result.planning){
      
            this.$router.push(`/planning/${result.planning._id}`);
              window.location.reload();
        }
    },
    isOwner(){
      const profile = JSON.parse(localStorage.getItem('profile'));

      return (this.item.user && this.item.user == profile._id)
    },
    onlyOwnerPlease(){
      Swal.fire({
        title: 'Debes ser el dueño',
        text: 'Solo puedes editar tus propias rutinas, puedes hacer una copia de esta con el boton duplicar',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
    },
    async assignPlanning(formData){
        if(this.isDemo()){
        return;
      }


      if(formData && formData.users.length){
        await createUserPlanningAction(formData.users,this.item._id);
      }else{
        let response = await createUserPlanningAction(false,this.item._id);
        console.log(response)
        let planningId = response.newPlanningId;
        if(planningId && planningId !== this.item._id){
          this.$router.push(`/planning/${planningId}`);
          this.$emit('refresh');
          window.location.reload(); 
        }else{
          if(!planningId){
            Swal.fire({
              title: 'Ya tienes esta rutina asignada',
              text: 'Búscala en Rutinas > asignadas a mí',
              icon: 'warning',
              confirmButtonText: 'OK',
            });
            return;
          }
        }

      }
      Swal.fire({
        title: this.$t('success'),
        icon: 'success',
        text: 'Redireccionando a la nueva rutina asignada',
        confirmButtonText: 'OK',
      });
        this.$emit('refresh')
      this.choose=false

    },
    onPlanningUpdated(){
        this.openedit=false
        this.$emit('refresh')
    },
    trainingCreated(response){
      if(response){
      this.newTraining = response.training;

      }
      this.open = false
      this.openGroup = false
      this.$emit('refresh')
    }
  },
};
</script>
<style scoped>
.videoplanning{
  position: absolute;
    z-index: 9999;
    width: 100%;
    padding: 8px;
}
.bannerplanning{
    height:250px !important;
    width: 100% !important;
    border-radius: 6px;
    background-blend-mode: multiply !important;
    background-color: #787878 !important;
    overflow: hidden;
}
.video-container {
  position: relative;
  padding-top: 56.25%; /* Relación de aspecto 16:9 */
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px; /* Esquinas redondeadas */
}
.playvideo{
    margin-top: 50px;
}
</style>

<style>

.playvideo{
  color: white;
    margin: 0 auto;
    width: 33px;
    margin-top: 20px;
    cursor:pointer;
}
.playvideo .md-icon{
  color: white !important;
    font-size: 55px !important;
}

</style>
