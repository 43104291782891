export default {  
  addNewMenu: 'Agregar Nuevo Menú',
  onlymenu: 'Mostrar solo el menú, sin opción de hacer pedidos',
menuName: 'Nombre del Menú',
menuTypesDescription: 'Existen 5 tipos de menú que puedes elegir según la necesidad de tu negocio',
deliveryConfirmation: 'Tu pedido ya fue entregado',
orderDelivered: 'Tu pedido ya ha sido entregado, puedes hacer otro pedido volviendo al menú',
forgotPassword: 'Olvidé mi contraseña',
deactivateQR: 'Desactivar QR',
  "shareScheduleInfo": "Podrás compartir los horarios con tus clientes insertando el link en tus redes sociales. También ellos podrán verlo en su teléfono al ingresar al gimnasio escaneando el QR.",
unlimitedPlan: 'Plan ilimitado',
"yes": "Sí",
'height' : 'Altura',
'weight' : 'Peso',
"gender" : "Género",
"pleaseinsertemail" : "Inserta el email con el cual diste de alta la aplicación para poder ser redireccionado a tu aplicación.",
"cardio":"Cardio",
"Rutinas":"Rutinas",
"nothingtosee":"No hay datos",
"thankyoushipping" : "Te enviaremos un email con los detalles del envío en breve.",
"Ejercicios":"Ejercicios",
"teacher_role": "Profesor",
"role" : "Rol",
"elements" : "Elementos",
"Entrenamientos":"Entrenamientos",
  "myCalendar": "Mi calendario",
    "notEnrolledInAnyClass": "No estás inscripto a ninguna clase",
    "myProfile": "Mi perfil",
      "stickQRMessage": "Pega el QR en la puerta de tu establecimiento para que tus clientes lo escaneen al entrar y controlar el acceso",
  "quota": "Cupos",
    "allowedAccessCount": "Cantidad de accesos permitidos (Escaneo del QR al ingresar al establecimiento)",
    "availableEntries": "Accesos disponibles",
    "Monday": "Lunes",
    "Core":"Centro",
    'perderpeso' : 'Perder peso',
'ganarmusculo' : 'Ganar músculo',
'tonificacion' : 'Tonificación',
'movilidad' : 'Movilidad',
      "download": "Descargar",
        "bodyZone": "Zona del cuerpo",
  "Tuesday": "Martes",
  "Wednesday": "Miércoles",
  "Thursday": "Jueves",
  "Friday": "Viernes",
  "Saturday": "Sábado",
  "Sunday": "Domingo",
  "January": "Enero",
    "availableSlots": "Cupos disponibles",
  "February": "Febrero",
  "March": "Marzo",
  "April": "Abril",
  "May": "Mayo",
  "June": "Junio",
  "July": "Julio",
  "August": "Agosto",
  "September": "Septiembre",
    "myClasses": "Mis clases",
  "October": "Octubre",
  "November": "Noviembre",
  "December": "Diciembre",
  'shoulders' : 'Hombros',
'biceps' : 'Bíceps',
'triceps' : 'Tríceps',
'chest' : 'Pecho',
'back' : 'Espalda',
'abdomen' : 'Abdomen',
'glutes' : 'Glúteos',
'quadriceps' : 'Cuádriceps',
'hamstrings' : 'Isquiotibiales',
'calves' : 'Pantorrillas',
    "recurringActivitiesMessage": "Las actividades recurrentes no permiten inscripción por ahora, pero mejoraremos esto pronto. Elige una fecha específica para poder habilitar inscripciones.",
  "allowOverQuotaMessage": "Si no eliges esta opción entonces cualquier cliente, que no tenga cupos disponibles pero que haya pagado su cuota mensual, podrá inscribirse también.",
  "anyClientCanSignUp": "Cualquier cliente puede inscribirse",
    "allready": "Ya estás inscripto en esta clase",
      "perMonth": "por mes",
        "noQuotaLeft": "Ya no te quedan cupos disponibles",
        "noQuotaLeftThisMonth": "No te quedan cupos disponibles por este mes",
    "temporaryIssue": "Tenemos un problema temporal, intenta nuevamente más tarde.",
    "remainingQuota": "Cupos restantes",
  "quotaMessage": "Insertando los cupos, el sistema no dejará que las personas sigan inscribiéndose cuando se alcance el límite. Para inscribirse, deben escanear el código QR que coloques en tu gimnasio o a través del link:",
  "leaveEmptyForEmail": "Deja vacío esto si quieres usar el email como identificador.",
    "leaveEmptyIfNoQuota": "Déjalo vacío si tus clases no tienen cupos definidos.",
  "activityLabel": "Actividad",
    "noSlotsAvailable": "No quedan cupos para esta clase",
      "unlimitedAccess": "Accesos ilimitados",
    "defineSpecificDate": "Definir una fecha específica para la actividad.",
    "seeDemo": "Ver demo",
      "classLimitPerMonth": "Cantidad de clases a las cuales puede inscribirse este cliente",
      "signUpForClass": "Inscribirme a una clase",
        "signUpForThisClass": "Inscribirme a esta clase",
      "unlimitedQuota": "Cupos ilimitados",
    "seeDemoBeforeSignUp": "Quiero ver una demo antes de registrarme.",
    "lastEntry": "Último ingreso",
      "demoMessage": "Esto es una demo, debes crearte tu propia cuenta para modificar opciones. Puedes hacerlo en:",
      "uploadMenuMessage": "Puedes subir una imagen por cada página del menú o un PDF con el menú completo.",
  "activityPlaceholder": "Nombre de la actividad",
  "selectDateLabel": "Seleccionar fecha",
    "uploadFile": "Subir archivo",
  "startTimeLabel": "Hora de inicio",
    "imagemenu": "Mostrar solo la foto del menú, sin opción de hacer pedidos por el consumidor.",
    "waitingForEntries": "Esperando accesos",
  "endTimeLabel": "Hora de fin",
    "leaveScreenOpenMessage": "Deja esta pantalla abierta en el navegador en tu establecimiento y podrás verificar aquí cada vez que alguien ingrese escaneando el QR.",
    "identifierMessage": "Inserta un identificador que recuerdes fácilmente, lo insertarás aquí para ingresar al gimnasio cada vez que escanees el código QR. Ejemplo: tu número de teléfono, DNI o email.",
  "timePlaceholder": "HH:MM",
  "removeButton": "Eliminar",
    "uploadImageMessage": "Sube una imagen que permita ver tu cara para identificarte en el gimnasio al ingresar.",
  "saveGridButton": "Guardar grilla",
    "addDailyActivityTitle": "Agregar Actividad Diaria",
  "activityLabel": "Actividad",
  "activityPlaceholder": "Ej: Yoga",
  "dayOfWeekLabel": "Día de la semana",
  "startTimeLabel": "Hora de inicio",
  "endTimeLabel": "Hora de fin",
  "addButton": "Añadir una actividad o clase",
  "Lunes": "Lunes",
  "Martes": "Martes",
  "Miércoles": "Miércoles",
  "Jueves": "Jueves",
  "Viernes": "Viernes",
  "Sábado": "Sábado",
  "Domingo": "Domingo",
  "deselectAll":"Deseleccionar",
perMonth: 'por mes',
completePayment: 'Completa tu pago',
filter: 'Filtrar',
ticket: 'Nota de consumo',
imageSet1_step1_title: '1- Escanean el QR',
imageSet1_step1_description: 'Escanean el QR en una piscina, playa o mercado.',
imageSet1_step2_title: '2- Recibir el pedido',
imageSet1_step2_description: 'Se recibe el pedido en un tablet o smartphone.',
imageSet1_step3_title: '3- Se alerta al cliente',
imageSet1_step3_description: 'El cliente recibe una alerta cuando el plato está listo y se acerca a buscarlo.',
generateReceipt: 'Generar recibo',
create: 'Crear',
add:'Agregar',
  waitingPeriod: "Período de espera", // Español
  waitingPeriodExplanation: "Elije la cantidad de días que quieres esperar antes de mostrar un cartel rojo a un cliente que no ha pagado.",
imageSet2_step1_title: '1- Escanea el QR',
imageSet2_step1_description: 'Escanea el QR y ordena los productos que desee.',
imageSet2_step2_title: '2- Recibir el pedido',
imageSet2_step2_description: 'El restaurante recibe el pedido en la cocina.',
imageSet2_step3_title: '3- El mesero sirve el plato',
imageSet2_step3_description: 'El mesero acerca el pedido a la mesa.',

imageSet3_step1_title: '1- Los clientes miran el menú',
imageSet3_step1_description: 'Miran el menú pero no pueden crear pedidos.',
imageSet3_step2_title: '2- El mesero toma el pedido',
imageSet3_step2_description: 'El mesero accede al QR de la mesa y genera la orden.',
imageSet3_step3_title: '3- Recibir el pedido',
imageSet3_step3_description: 'El restaurante recibe el pedido en la cocina.',
imageSet3_step4_title: '4- El mesero sirve el plato',
imageSet3_step4_description: 'El mesero acerca el pedido a la mesa.',
imageSet3_step5_title: '5- Los clientes pagan',
imageSet3_step5_description: 'Los clientes pagan de la manera clásica o escaneando el QR para pagar.',
  "monthlyPayment": "Pago mensual",
    "unlimitedMonthlyPayment": "Pago mensual con accesos ilimitados al gimnasio y con cupos ilimitados",
  "unlimitedMonthlyPaymentWithQuota": "Pago mensual con accesos ilimitados al gimnasio y con {cupos} cupos para clases por mes",
  "limitedMonthlyPayment": "Pago mensual con {access} accesos mensuales al gimnasio y con {cupos} cupos para clases por mes",
  "limitedAccessUnlimitedQuota": "Pago mensual con {access} accesos mensuales al gimnasio y con cupos ilimitados para las clases",
  "oneTimePayment": "Un solo pago por {access} accesos al gimnasio y {cupos} cupos para clases",
sendMessageChat: 'Envíanos un mensaje en el chat',
subscribe: 'Suscribirse',
maxOrders: 'Cantidad máxima de pedidos',
maxOrdersLimit: 'Cantidad máxima de órdenes que este establecimiento puede tomar al mismo tiempo. Si se supera el número, el QR mostrará el menú pero no permitirá generar órdenes nuevas.',
importantNotifications: 'Aquí recibirás notificaciones importantes sobre la actividad de las personas en este establecimiento.',
remainingOrders: 'Pedidos restantes',
kitchenStatusOrders: 'Órdenes en status "cocina"',
selectAll: 'Seleccionar todo',
status: 'Estado',
remainingAccesses: 'Accesos restantes',
contactForHelp: 'Si tienes dudas del funcionamiento del sistema o de cómo suscribirte, escríbenos en el chat y te ayudaremos. No te preocupes, hablarás con un humano.',
alreadySubscribedUnlimited: 'Ya estás suscripto al plan ilimitado',
cancelableSubscription: 'Suscripción cancelable en todo momento, sin compromiso.',
monthlySubscription: 'Subscripción mensual de 10.000 pesos argentinos por mes',
monthlySubscriptionEur: 'Subscripción mensual de 12.00 € por mes',
cancelSubscription: 'La subscripción usted la puede cancelar en cualquier momento desde MercadoPago',
unlimitedCredits: 'Créditos ilimitados, acceso ilimitado a todas las funcionalidades',
noCreditsQRInactive: 'Sin créditos tus QR dejarán de funcionar',
business: 'Negocio',
differentWaysToUse: 'Diferentes modos de usar Go Take It',
client_role: 'Cliente',
  "paymentMethod": "Forma de pago",
  "emailExistsMessage": "Este email ya existe.",
  myClients: "Mis clientes",
loadClients: 'Cargar clientes',
pay: 'Pagar',
employee_role: 'Mesero',
paymentDate: 'Fecha de pago',
  "identifierExistsMessage": "Este identificador o email ya fue utilizado por otro usuario.",
subscriptionActivated: 'Tu suscripción ha sido activada, disfruta de GoTakeIt sin límites!',
activateQR: 'Activar QR',
  "access_reminder": "Te quedan {credits} créditos",
    "pending": "Pendiente",
  "subscription_invite": "Te invitamos a suscribirte eligiendo la opción 'suscripción' en el menú",
  inviteToSubscribe: 'Te invitamos a suscribirte al plan ilimitado',
markAsPaid: 'Marcar como pagado',
rejected: 'Rechazado',
enter: 'Ingresar',
accepted: 'Aceptado',
  "scanQrFirst": "Primero debes escanear el QR para poder hacer click en ingresar.",
identifierNotFound: 'El identificador no existe',
paymentDateInfo: 'Ingresa tus clientes con la fecha de pago para que el sistema sepa cuando debe rechazar el ingreso. Por defecto, el sistema permite el acceso en caso de que la fecha no haya sido ingresada.',
insertClients: 'Insertas tus clientes con un nombre y un identificador',
goToMyApp: 'Ir a mi aplicación',
popupNotification: 'Te avisaremos con un popup en esta pantalla cuando tu pedido esté listo',
paymentPending: 'Pendiente de pago',
toPay: 'A pagar',
restaurant_role: 'Administrador',
gym_role: 'Administrador',
users: 'Usuarios',
clientsStartUsing: 'Tus clientes comienzan a utilizarlo',
qrScanNotification: 'Tus clientes escanean el QR cada vez que ingresan al establecimiento y recibes una notificación si tiene permitido el ingreso o no',
addUser: 'Agregar usuario',
userRolesDescription: 'Puedes crear otro usuario admin o un usuario rol empleado. El rol empleado le permite al usuario usar GO Take It en su teléfono para tomar los pedidos y generar las órdenes él mismo para los casos donde el consumidor solo puede mirar el menú pero no puede generar órdenes él mismo.',
tokenExpired: 'El token ha expirado',
tryAgain: 'Intenta nuevamente con un nuevo email',
  "mustLoginToSignUp": "Debes iniciar sesión para inscribirte",
    "new_password": "Nueva Contraseña",
    "repeat_password": "Repetir Contraseña",
    "change_password": "Cambiar Contraseña",
    "password_too_short": "La contraseña debe tener al menos 6 caracteres.",
    "password_needs_symbol": "La contraseña debe contener al menos un símbolo.",
    "passwords_do_not_match": "Las contraseñas no coinciden.",
    "password_changed": "Contraseña cambiada exitosamente.",
    "error_changing_password": "Error al cambiar la contraseña.",
sendEmail: 'Enviar email',
emailSent: 'Email enviado',
unsubscribe: 'Cancelar suscripción',
digitalQrMenu: 'Menú digital QR para restaurantes, parasol en la playa, piscinas u hoteles',
correctEmail: 'Si la casilla de email es correcta, recibirás un link para reiniciar contraseña',
passwordResetEmail: 'Te enviaremos un email con un link para reiniciar la contraseña',
sendNotifications: 'Enviaremos notificaciones a este email cuando recibas pedidos',
activatePaymentSystem: 'Activar sistema de pagos en los pedidos',
customersMustPay: 'Los clientes deberán pagar al momento de hacer el pedido.',
syncRequired: 'Necesitas sincronizar con nuestro sistema de pagos para poder activar esta opción',
  syncpayments:'Sincronizar con plataforma de pagos',
  ifyouunsync:'Si desincronizas no podrás recibir más pagos',
  stripeAccountSync:'Tu cuenta ha sido sincronizada con éxito',
  nowYouCanAccess:'Ahora recibirás los pagos directamente en tu cuenta, nosotros no somos intermediarios, ya puedes activar la opcion en tu menu para que los clientes paguen sus pedidos',
  connectStripeAccount: 'Conectar cuenta de Stripe',
  syncStripeInfo: 'Sincronizando Stripe podrás habilitar los pagos para que los clientes tengan que pagar sus pedidos antes de pedirlos.',
  noStripeAccount: 'Si no tienes una cuenta de Stripe puedes crear una y volver a esta pantalla para sincronizarla.',
  alreadyHaveStripeAccount: 'Si ya tienes una cuenta de Stripe conectada puedes sincronizar haciendo click aquí.',
  syncMyStripeAccount: 'Sincronizar mi cuenta de Stripe',
  createOrSyncStripeAccount: 'Crear o Sincronizar cuenta de Stripe',
  error: 'Error',
  stripeConnectError: 'No se pudo conectar con Stripe. Inténtalo de nuevo.',
  haveStripeAccount: '¿Tienes una cuenta de Stripe?',
  redirectSync: 'Si ya tienes una cuenta, te redirigiremos para sincronizarla.',
  yesSyncAccount: 'Sí, sincronizar cuenta',
  noCreateAccount: 'No, crear cuenta nueva',
  stripeAccountCreated: 'Cuenta de Stripe creada',
  syncStripeInfoAfterCreation: 'Ahora necesitas sincronizarla con nosotros para empezar a recibir pagos.',
  stripeCreateError: 'No se pudo crear la cuenta de Stripe. Inténtalo de nuevo.',
  genericError: 'Ocurrió un error. Inténtalo de nuevo.',
  qrhelp: 'Puedes crear códigos QR para relacionarlos a un menú. Luego imprimir ese QR y ponerlo en las mesas de restaurante, parasoles en la playa o camas en la piscina de un hotel.',
  dashboard: 'Dashboard',
  accessIdentifierInfo: 'Este identificador será el que el cliente deba ingresar al escanear el QR, puede ser su Documento Nacional de Identificación, pasaporte, o número de cliente. Algo que él pueda recordar fácilmente.',
  total: 'Total',
    "selfRegistrationMessage": "Permitir que las personas se registren ellas mismas al escanear el QR en caso de que aún no tengan un perfil creado.",
  myOrder: 'Mi pedido',
    "gotakeitUsageMessage": "Esto permite empezar a usar Gotakeit sin necesidad de cargar los clientes, ellos crearán sus perfiles y tú debes insertar luego la fecha de pago.",
  orderNotification: 'Te enviaremos emails comunicando el avance de tu pedido.',
  name: 'Nombre',
  accesses: 'Accesos',
  enterName: 'Ingresa tu nombre',
  email: 'Email',
  enterEmail: 'Ingresa tu email',
  comments: 'Algun comentario',
  enterComments: 'Comentarios',
  code: "Código",
  yespaid: "Pagado",
  nopaid: "No pagado",
  paid: "Pago",
  scanphone: "Escanea el QR con tu telefono o haz click",
  accessIdentifier: 'Identificador de acceso',
  phone: 'Teléfono',
  producthelp: 'Recuerda que si creas un nuevo producto, luego debes ligarlo al menú donde quieras que se vea',
  enterPhone: 'Número de teléfono',
  confirmOrder: 'Confirmar pedido',
  yourOrder: 'Tu pedido',
  orderDetails: 'Tu pedido será enviado a la cocina cuando hagas click en enviar. Te avisaremos cuando esté listo para que te acerques a buscarlo.',
  sendOrder: 'Hacer Pedido',
  cancel: 'Cancelar',
  orderSummaryEmail: 'Te enviamos un email con el resumen de tu pedido, recuerda revisar SPAM y quitar el email de esa carpeta para poder recibir correctamente la alerta cuando tu pedido esté listo.',
  orderNumber: 'Pedido Número',
    "paymentPerAccessOrQuota": "Pago por cantidad de accesos o cupos",
  preparingOrder: 'Estamos preparando tu pedido',
  dontClose: 'Te avisaremos con un popup en esta pantalla cuando tu pedido esté listo y también te enviaremos un email a la casilla que insertaste anteriormente',
  orderReadyTitle: '¡Tu pedido ya está listo!',
  orderReadyText: 'Puedes recoger tu pedido ahora.',
  welcome: 'Bienvenido',
  instructions: 'Por favor, siga las instrucciones.',
  settings: 'Configuraciones',
  currency: 'Moneda',
  position_currency: 'Posición de la moneda',
  primary_color: 'Color primario',
  secondary_color: 'Color secundario',
  menu_languages: 'Idiomas del menú',
  businessName: 'El nombre de tu negocio',
  request_location: 'Solicitar ubicación al hacer un pedido.',
  request_additional_information: 'Solicitar email y teléfono.',
  request_address: 'Solicitar dirección.',
  shipping_cost: 'Añadir costo de envío.',
  menu_terms: 'Términos del menú',
    "done": "Terminado",
      "inprogress": "En progreso",
  save: 'Guardar',
  shippingdesc: 'El costo de envío se aplicará para el menú de tipo "envíos"',
  success: '¡Hecho!',
  business_info_updated: 'Información del negocio actualizada con éxito',
  error: '¡Error!',
  error_updating_business_info: 'Hubo un error al actualizar la información del negocio',
  english: 'Inglés',
  spanish: 'Español',
  french: 'Francés',
  german: 'Alemán',
  italian: 'Italiano',
  takeaway: 'Pedidos a retirar cuando el consumidor es llamado con una alerta',
  onsite: 'El pedido es recibido en el lugar donde se escanea el código QR',
  shipping: 'El pedido es enviado a la dirección establecida por el usuario.',
  shippgindesc: 'El costo de envío se aplicará para el menú de tipo "envíos"',
  menu_type: 'Tipo de menú',
  myMap: 'Mi mapa',
  drawMap: 'Dibujar mapa',
  deleteWall: 'Borrar pared',
  delete: 'Borrar',
  addProduct: 'Agregar producto',
  addCategory: 'Agregar categoría',
  editCategory: 'Editar categoría',
  addNewCategory: 'Agregar nueva categoría',
  categoryName: 'Nombre de la categoría',
  identifier: 'DNI o Identificador',
  editProduct: 'Editar producto',
  addNewProduct: 'Agregar nuevo producto',
  productName: 'Nombre del producto',
  description: 'Descripción',
  productPrice: 'Precio del producto',
  category: 'Categoría',
  consentNotice: 'Al utilizar la aplicación Gotakeit, estás dando tu consentimiento a nuestros términos de uso y política de privacidad. Entiendes que tu información es procesada en nombre del establecimiento y será compartida con ellos.',
  selectImage: 'Seleccionar imagen',
  change: 'Cambiar',
  remove: 'Eliminar',
  inventory: 'Inventario',
    "enableRegistrations": "Habilitar inscripciones a esta actividad",
  selectCategoryToAssign: 'Seleccionar categoría para asignar',
  assignCategory: 'Asignar categoría',
  noCategory: 'Sin categoría',
  pleaseSelectCategoryAndProduct: '¡Por favor selecciona una categoría y al menos un producto!',
  productNotFound: '¡Producto no encontrado!',
  pleaseFillOutAllFields: '¡Por favor complete todos los campos!',
  errorUploadingImage: 'Error al subir la imagen',
  categoryNotFound: '¡Categoría no encontrada!',
  areYouSure: '¿Estás seguro?',
  doYouWantToSaveAllChanges: '¿Deseas guardar todos los cambios?',
  yesSaveIt: '¡Sí, guárdalo!',
  saved: '¡Guardado!',
    "access_denied": "Acceso denegado",
  "payment_overdue": "Tu pago se venció hace {days} días.",
  "payment_due": "Faltan {days} días para el vencimiento de tu pago mensual",
  yourChangesHaveBeenSaved: 'Tus cambios han sido guardados.',
  doYouWantToDeleteThisProduct: '¿Deseas eliminar este producto?',
  yesDeleteIt: '¡Sí, elimínalo!',
  deleted: '¡Eliminado!',
  yourProductHasBeenDeleted: 'Tu producto ha sido eliminado.',
  doYouWantToDeleteThisCategory: '¿Deseas eliminar esta categoría?',
  yourCategoryHasBeenDeleted: 'Tu categoría ha sido eliminada.',
  stablishments: 'Establecimientos',
  menus: 'Menús',
  qrCodes: 'Códigos QR',
  qrSettings: 'Configuraciones para QR',
  qrConfigurationInfo: 'Define configuraciones para tus diferentes QR y asocia esa configuración al QR que quieras. Puedes establecer colores, idiomas y frases a utilizar.',
  addQr: 'Agregar QR',
  saveAll: 'Guardar todo',
  addNewQr: 'Agregar nuevo QR',
  qrName: 'Nombre del QR',
  viewMenu: 'Ver menú',
  youWontBeAbleToRevertThis: '¡No podrás revertir esto!',
  yourQrHasBeenDeleted: 'Tu QR ha sido eliminado.',
  emailPlaceholder: 'Email...',
  passwordPlaceholder: 'Contraseña...',
  login: 'Iniciar sesión',
  incorrectUserOrPassword: 'Usuario o contraseña incorrectos',
  loginError: 'Error al iniciar sesión',
  addNewStablishment: 'Agregar nuevo establecimiento',
  map: 'Mapa',
  edit: 'Editar',
  remove: 'Eliminar',
  viewOrders: 'Ver pedidos',
  viewOrdersMap: 'Ver mapa de pedidos',
      sendToKitchen: "Enviar a cocina",
    sendAlert: "Enviar alerta",
    finish: "Finalizar",
    createdAgo: "Creado hace",
    inKitchenFor: "En cocina hace",
    serviceStart: 'Inicio de servicio',
    accessGranted: 'Acceso permitido',
    qrCode: "Código QR",
    email: "Correo electrónico",
    phone: "Teléfono",
    accessBlocked: 'Acceso bloqueado',
    comments: "Comentarios",
    alertSentAgo: "Alerta enviada hace",
    alerting: "Alertando",
    details: "Detalles",
      "enrolledPeople": "Participantes",
    backToPending: "Volver a pendiente",
    backToOngoing: "Volver a en curso",
    cancelOrder: "Cancelar orden",
      enableSoundAlerts: "Activar alertas por sonido",
    newOrders: "Nuevos Pedidos",
    inKitchen: "En cocina",
      "clientAccess": "Acceso para clientes",
    alertingCustomer: "Alertando al cliente",
    closedOrders: "Cerradas",
    cancelledOrders: "Canceladas",
    orders: "Pedidos",
    translation: "Traducción",
    products: "Productos",
    paymentsAndReceipts: 'Pagos y recibos',

    accessControl: 'Control de acceso para gimnasios, clubes, y establecimientos privados',
      address: "Dirección",
  enterAddress: "Ingrese su dirección",
  logo: "Logo",
  logout: 'Cerrar sesión',
  nameAlreadyTaken: 'Ese nombre ya fue elegido por otro usuario',
    restaurantName: 'Nombre del restaurante...',
      logoTitle: 'Logo',
        termsRequired: 'Debe aceptar los términos y condiciones.',
  accountconfirm:'Tu cuenta ha sido confirmada, ahora puedes iniciar sesión.',
  confirmEmail: 'Confirma tu correo electrónico',
  confirmEmailText: 'Haz clic en el enlace que te enviamos a tu correo para confirmar tu cuenta.',
  register: 'Registrarse',
  coverImage: "Imagen de portada",
    avgOrderValue: 'Valor promedio del pedido',
  sales: 'Ventas',
  topCustomers: 'Mejores Clientes',
  topOrderedProducts: 'Productos más ordenados',
  password: 'Contraseña...',
  termsAndConditions: 'Acepto los',
  termsLinkText: 'términos y condiciones',
  getStarted: 'Comenzar',
  createApp: 'Creamos la aplicación',
  createAppDesc: 'Creamos la aplicación para tu restaurante.',
  addProducts: 'Agregas productos',
  addProductsDesc: 'Agregas los productos al menú y configuras para generar los codigos QR.',
  customersEnjoy: 'Tus clientes lo disfrutan',
  customersEnjoyDesc: 'Tus clientes escanean el código QR y hacen sus pedidos.',
  restaurantNameExists: 'El nombre del restaurante ya existe.',
  errorCheckingRestaurantName: 'Error al verificar el nombre del restaurante.',
  nameRequired: 'obligatorio.',
  restaurantNameRequired: 'El nombre del restaurante es obligatorio.',
  emailRequired: 'El correo electrónico es obligatorio.',
  emailInvalid: 'El formato del correo electrónico no es válido.',
  passwordRequired: 'La contraseña es obligatoria.',
  userExists: 'El usuario ya existe.',
    "nifLabel": "N.I.F",
    "invoiceTitle": "Factura Simplificada",
    "dateLabel": "Fecha",
    "invoiceNumberLabel": "No",
    "tableLabel": "Mesa",
    "guestLabel": "Comensales",
    "waiterLabel": "Camarero",
    "totalWithoutTaxLabel": "Total sin IVA",
      "select": "Seleccionar",
    "taxLabel": "IVA",
    "totalLabel": "Total",
    "tipLabel": "TIP / Propina",
    "thankYouMessage": "I.V.A. Incluido - Gracias por su visita",
    "description": "Descripción",
    "quantity": "Cant.",
    "importUsers": "Importar Usuarios desde Archivo Excel (.xls o .xlsx)",
    "matchColumns": "Emparejar columnas",
    "selectDateFormat": "Seleccionar formato de fecha",
    "previewImport": "Previsualizar Importación",
    "previewUsers": "Previsualización de Usuarios a Importar",
    "noDataAvailable": "No hay datos disponibles para previsualizar.",
    "confirmAndImport": "Confirmar e Importar",
    "resume": "Reanudar",
    "pause": "Pausar",
    "error": "Error",
    "emptyFile": "El archivo está vacío o no tiene datos.",
    "missingHeaders": "El archivo debe contener encabezados en la primera fila.",
    "limitExceeded": "Límite excedido",
    "importLimit": "No es posible importar más de 3000 contactos en una sola ejecución.",
    "missingHeadersCSV": "El archivo CSV debe contener encabezados en la primera fila.",
    "importCancelled": "Importación cancelada",
    "errorCreatingUser": "Error creando el usuario {name}:",
    "importedUsers": "Se importaron o editaron {createdUsers} usuarios con éxito.",
};
