import { render, staticRenderFns } from "./Excel.vue?vue&type=template&id=210ff010&scoped=true"
import script from "./Excel.vue?vue&type=script&lang=js"
export * from "./Excel.vue?vue&type=script&lang=js"
import style0 from "./Excel.vue?vue&type=style&index=0&id=210ff010&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "210ff010",
  null
  
)

export default component.exports