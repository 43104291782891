<template>

  <div class="calendar-grid-phone">

          <div class="loading-container mr-2 w-100 text-center p-4" v-if="isLoading">
            <md-progress-spinner 
              :md-diameter="80" 
              md-mode="indeterminate" 
              class="text-red bg-red md-red">
            </md-progress-spinner>
          </div>
          <div class="w-100 text-center customborder" v-if="plannings.length == 0 && !isLoading">
             <h5 class="text-secondary p-4">{{$t('nothingtosee')}}</h5>
          </div>
    <div v-for="(item, activityIndex) in plannings" class="calendar-day-phone">

      <div  class="logorestocontainer2" @click="goTo(item)">  

        <div class="logoplanning planningimage p-3 mt-2" :style="'background:url('+item.image+')'">
        

          <div class="text-white"><h5 class="mb-0">{{ item.name }}</h5></div>
          
          <small class="text-white">
              <strong >{{ item.difficulty }} </strong> - <strong >{{ item.goal }} </strong>
          </small>

          <small class="text-white" v-if="item.myPlanning && item.myPlanning.status == 'inprogress'">
            Fecha de inicio: {{getLocalDate(item.myPlanning.startedAt,'PP HH:mm')}}</small>
         
          <small class="text-white" v-if="item.myPlanning && item.myPlanning.status == 'done'">
            Fecha de finalización: {{getLocalDate(item.myPlanning.endAt,'PP HH:mm')}}</small>

          <div class="w-100 float mb-2">
            <span class="badge badge-danger" v-if="item.myPlanning && item.myPlanning.status == 'pending'">Asingada a mí</span>
            <span class="badge badge-danger" v-if="item.myPlanning && item.myPlanning.status == 'done'">Finalizada</span> 
            <md-icon class="float-left  circlesuccess" v-if="item.myPlanning && item.myPlanning.status == 'inprogress'">circle</md-icon>
                 
            <span class="badge badge-success" v-if="item.myPlanning && item.myPlanning.status == 'inprogress'">En progreso</span>
            <span class="badge badge-primary" v-if="isOwner(item)">Creada por mí</span>
          </div>

          <div id="playnow" class="playvideo" v-if="item.videoUrl">
            <md-icon>play_circle</md-icon>
          </div>
      



            </div>  
          </div> 
        <div>
      </div> 
    </div>
  </div>

</template>

<script>
import { getAllPlanningsAction, deletePlanningAction, getPlanningAction } from '@/pages/Training/actions';
import permissionsMixin from '@/mixins/permissionsMixin';

import CreatePlanning from './CreatePlanning.vue'; // El formulario de edición
import PlanningView from './PlanningView.vue'; // El formulario de edición
import Swal from 'sweetalert2';

export default {
  mixins: [permissionsMixin],

  data() {
    return {
      open:false,
      isLoading: false,
      currentSort: 'name',
      currentSortOrder: 'asc',
      selectedPlanning: null, // Planning seleccionado para editar,
      id: false
    };
  },
  props:{ 
    plannings: [],
    onlyme: Boolean
  },
  components: {
    CreatePlanning,
    PlanningView
  },
  watch: {
    $route(to, from) {
      // Acción que quieres realizar al cambiar la ruta
      this.refreshComponent();
    }
  },
  methods: { 
    async refreshComponent() {
      // Reinicia los datos o realiza cualquier acción que necesites
    
          if(this.$router.currentRoute.params && !this.$router.currentRoute.params.id){
            this.id = null;
              this.isLoading = true;
             const response = await getAllPlanningsAction();
              if (response.ok) {
                this.plannings = response.plannings;
              } else {
                console.error('Failed to fetch plannings: ', response.message);
              }

              this.isLoading = false;
          
          }
    },
    isOwner(item){
      const profile = JSON.parse(localStorage.getItem('profile'));

      return (item.user && item.user == profile._id)
    },
    editPlanning(planning) {
      this.selectedPlanning = planning;
    },
    goTo(item){
        this.$emit('goTo',item)
    },
    async deletePlanning(id) {
      try {
        const response = await deletePlanningAction(id);
        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Deleted',
            text: 'Planning deleted successfully',
            timer: 2000,
            showConfirmButton: false
          });

          this.$emit("refresh")
          // Recargar la lista de plannings
          
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to delete planning: ' + response.message,
          });
        }
      } catch (error) {
        console.error('Error deleting planning:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An unexpected error occurred',
        });
      }
    },
    onPlanningUpdated() {
      // Actualiza la lista de plannings después de la edición
      this.selectedPlanning = null;
  
    }
  }
};
</script>

<style scoped>

.planningimage{
  width: 400px !important;
  height: 200px !important;
  border-radius:8px !important;
}
.cursor-pointer{
  cursor:pointer!important;
}
</style>
<style scoped>
.calendar-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  
}

@media (max-width: 980px) {
  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1px;
  }
}

.calendar-day-name {
  text-align: center;
  font-weight: bold;
}
.md-button.md-sm .md-ripple {
    font-size: 11px;
    padding: 4px !important;
}
.weekcell{
   display: grid;
  grid-template-columns: repeat(7, 1fr);
  
}
.daycell{
  
    border:0;
    border-radius: 4px;
}
.calendar-day {
  background-color: #fff; /* Color de fondo por defecto */
}

.past-day {
  background-color: #f0f0f0; /* Color gris para los días pasados */
}
.itemactivity{
    position: absolute;
    top: -6px;
    right: -15px;
    font-size: 8px;
    border-radius: 50%;
    border: 0;
    color: #11b5c9;
    padding: 0px 9px;
    font-weight: 900;
}
.itemactivity i{
    font-size: 10px !important; 
}
.opaco{
      background-color: #d8d8d8 !important;   
    text-decoration-color: #858585;
}

.md-card-content ul {
  list-style-type: none;
  padding: 0;
}

.md-card-content ul li {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}
</style>


<style scoped>
.calendar-grid-phone {
  display: flex;
  flex-wrap: nowrap; /* Para mantener todas las tarjetas en una sola línea */
  overflow-x: auto; /* Para permitir el scroll horizontal */
  -webkit-overflow-scrolling: touch; /* Para una mejor experiencia de scroll en dispositivos táctiles */
}

.calendar-day-phone {
    margin-top: 4px;
  flex: 0 0 auto; /* Evita que las tarjetas se encogen y las mantiene en su tamaño original */
  margin-right: 10px; /* Espaciado entre las tarjetas */
  width: 90%; /* Ajusta el tamaño de la tarjeta, puedes cambiarlo según tu necesidad */
}
@media (min-width: 768px) {

.calendar-day-phone {
width: auto;
        float: left;
        }

.calendar-grid-phone {
  display: block;
  flex-wrap: nowrap; /* Para mantener todas las tarjetas en una sola línea */
  overflow-x: auto; /* Para permitir el scroll horizontal */
  -webkit-overflow-scrolling: touch; /* Para una mejor experiencia de scroll en dispositivos táctiles */
}

}


.calendar-day-phone md-card {
  width: 100%; /* Asegura que el md-card ocupe todo el ancho del contenedor */
}

/* Estilos para organizar las actividades como un calendario */
.calendar-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.calendar-day {
}

.card-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}

.title {
  margin-left: 10px;
}


.md-card-content ul {
  list-style-type: none;
  padding: 0;
}
.logoplanning{

    background-blend-mode: multiply !important;
    background-color: #787878 !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    border-radius: 40%;
     border: 3px solid white;
    display: inline-block;
}
.md-card-content ul li {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}
.calendar-grid .md-card {
  min-height: 200px;
}

p {
  text-align: center;
  color: #999;
}

.error {
  color: red;
  font-size: 12px;
}
.cursor-pointer{
  cursor:pointer;
}
</style>
