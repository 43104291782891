<template>
	<div class="position-relative">
	<div  
		class="p-1"   
		v-if="activity.registration && !activity.alreadySignup">
		<md-button
		  class="md-button md-sm d-inline md-success"
			@click="signUpClass()"
			 :disabled="!(hayLugar() && tieneCupos())"
		  v-if="!isPastDay() && activity.registration && !isGym() && !isTeacher() && !myclasses">
		  <md-icon>app_registration</md-icon>
		  <span v-if="hayLugar() && tieneCupos()">{{ $t('signUpForThisClass') }}</span>
		  <span v-if="!hayLugar() && tieneCupos()">0 {{$t('remainingQuota')}}</span>
  		<span v-if="!tieneCupos()">{{$t('noQuotaLeft')}}</span>
		</md-button>
	</div>


	  <div v-if="edit && !phone"  class="position-absolute top-0 right-0">
        <button class="border-round bg-primary mr-2" @click="editActivity()">
          <md-icon class="text-white cursor-pointer">edit</md-icon>
        </button>
        <button class="border-round bg-danger" @click="deleteAct()">
          <md-icon class="text-white cursor-pointer">close</md-icon>
        </button>
      </div>


	<div class="w-100">
	
	<strong>{{ activity.name }}</strong>
		
	    <PlanningSlide
	    	v-if="planning" 
	    	            @goTo="goTo($event)" 
        :plannings="[planning]"
      />


      
	<div class="text-secondary ">
 		<strong v-if="!activity.startDate">Todos los {{daysOfWeek[activity.day]}}</strong>
	  <strong>
	  <strong v-if="activity.endTime"> de</strong><strong v-else> a las</strong>  {{ activity.startTime }} hs
	  </strong>
	   <strong v-if="activity.endTime">
	  a {{ activity.endTime }} hs
	  </strong>
	</div>



	</div>
	<div  class="px-1 w-100">
	<small v-if="activity.registration" class="p-2">
	  <small v-if="activity.cupos > -1" class=" text-success"><md-icon class=" text-success">groups</md-icon> 
	  Quedan {{ activity.cupos - activity.users.length }} lugares</small>
	  <small v-if="activity.cupos == null"><md-icon>groups</md-icon> {{ $t('remainingQuota') }}: 0</small>
	  <small v-if="activity.cupos == -1">{{ $t('unlimitedQuota') }}  <md-icon>all_inclusive</md-icon></small>
	  <br>
	</small>
	<span v-if="activity.registration">
	  <small v-if="!activity.consumequota" class=" text-success">
	   <md-icon class=" text-success">meeting_room</md-icon> {{ $t('anyClientCanSignUp') }}</small>
	  <small v-else class=" text-success">
	   <md-icon class=" text-success">door_front</md-icon>Clase con inscripción por cupos</small>
  </span>

	<small class="badge badge-success && !myclasses" v-if="activity.alreadySignup">{{ $t('allready') }}</small>
	<small class="badge badge-danger" v-if="!hayLugar() && !isPastDay() && activity.cupos > -1">{{ $t('noSlotsAvailable') }}</small>
	<div class="w-100">
		<md-button
			v-if="userEnrolled.length && (isGym() || isTeacher())"
		  class="md-button md-sm d-inline md-success"
		  @click="openModal()">
		  <md-icon v-if="classicModal">keyboard_arrow_down</md-icon>
		  <md-icon v-else>keyboard_arrow_up</md-icon>
		  {{ $t('enrolledPeople') }}
		</md-button>
		<md-button
			v-if="!userEnrolled.length && (isGym() || isTeacher())"
		  class="md-button md-sm d-inline md-success"
		  disabled>
		  	No hay inscriptos
		</md-button>

    <div class="w-100 float-left card p-4" v-if="userEnrolled.length && classicModal" > 
    		<div v-for="item in userEnrolled" class="w-100">
						
						  <div class="mt-2">
						  	<strong class="pl-2 text-secondary"> {{ item.name }}</strong><small class="pl-2 text-secondary"> {{ item.email }}</small>
						  	   <md-button class="md-btn md-danger md-sm" 
				            v-if="!isPastDay() && (isGym() || isTeacher())" 
				            @click="deleteClass(activity._id,item._id)">{{$t('unsubscribe')}}</md-button>
						  </div>
    		</div>
    </div>
    </div>
	</div>
	</div>
</template>
<script>

import { 
  getPlanningAction
} from '@/pages/Training/actions';
import { getAllUsersAction } from "@/pages/Users/actions";
import { format, addMonths, subMonths, getDay, isToday, isBefore } from 'date-fns';
import { deleteClassAction, getMyClassesAction } from "@/pages/Stablishments/actions";
import PlanningSlide from '@/pages/Training/PlanningSlide.vue';

import permissionsMixin from '@/mixins/permissionsMixin';

export default {
  mixins: [permissionsMixin],
  name:"activity",

	created() {
			if(this.activity.planningId){
				this.getPlanning();
			}
			if(this.activity.users.length){
				 this.getAll();
			}
	},
	data() {
		return {
			planning:false,
			classicModal: false,
			openUsers:[],
			remainingCupos:false,
      defaultLogo: "/img/placeholder.jpg",
			users:[],
      daysOfWeek: ['Domingo','Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
		}
	},
  components: {
    PlanningSlide,
  },
  computed: {
    userEnrolled() {
      return this.users.filter(user => this.activity.users.includes(user._id));
    },
  },
  props: {
    myclasses: {
      type: Boolean,
      default: () => false,
    },
    activity: {
      type: Object,
      default: () => {},
    },
    phone: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    edit: {
      type: Boolean,
      default: () => {
        return true;
      }
    },
    day: {
      type: Date,
      default: () => {
        return true;
      }
    },

  },
  methods:{
    goTo(item) {
      this.$router.push({
        name: 'PlanningView',
        params: { id: item._id, objeto: { id: item._id, planning: item } },
      });
      this.id = item._id;
      this.start();
    },
    async deleteClass(id,userId){
      await deleteClassAction(id,userId);
      
    },
    async getPlanning(){
      const response = await getPlanningAction(this.activity.planningId);
      if (response.ok) {
        this.planning = response.planning;
      }
    },
    async getAll() {
      try {
        const response = await getAllUsersAction();
        this.users = response.users;
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
  	openModal(){
      this.classicModal = !this.classicModal;
  	},
    classicModalHide() {
      this.classicModal = false;
    },
  	hayLugar(){
  		return (this.activity.cupos < 0 || (this.activity.cupos - this.activity.users.length) > 0);
  	},
  	tieneCupos(){
  	  const profile = JSON.parse(localStorage.getItem('profile'));
  	  this.remainingCupos = profile.remainingCupos
			return (this.remainingCupos > 0);
  	},
    isPastDay() {
      const today = new Date();
      return isBefore(this.activity.startDate, today) && !isToday(this.activity.startDate);
    },
  	signUpClass(){
  		this.$emit('signUpClass',{id: this.activity._id,day: this.day});
  	},
  	deleteAct(){
  		this.$emit('deleteAct',this.activity._id);
  	},
  	editActivity(){
  		this.$emit('editActivity',this.activity);
  		 const targetElement = document.getElementById('collapsetotal23');
	    if (targetElement) {
	        targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
	    }
  	}
  }
 }

 </script>

 <style>

 .right-0{
   right:0;
 }
.border-round{
border-radius: 50%;
    border: 0;
    width: 31px !important;
    height: 31px !important;
    padding: 0px !important;
}
.border-round i{
font-size: 20px !important;}

 </style>

