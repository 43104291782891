<template>
 <div>

  <PlanningGrid  :type="type"/>

       
  </div>
</template>

<script>
import permissionsMixin from '@/mixins/permissionsMixin';
import {PlanningGrid } from "@/pages/Training";



export default {
  mixins: [permissionsMixin],
  watch: {
      '$route'() {
          // Lógica que quieres ejecutar cuando la ruta cambia
          this.start();
      }
  },
  data() {
    return {
      type: String
    };
  },
  components: {
    PlanningGrid
  },
  created() {
    this.start();
  },
  methods:{
    start(){

     this.type = this.$router.currentRoute.params.type;
    }
  }
};
</script>

